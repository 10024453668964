import React from "react";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../../components/CustomComponent";
import LogForm from "./AdvisorLogForm";
import CommonLoadingView from "./CommonLoadingView";
//
export default class CommonLogView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: [], firstLoad: true };
  }
  loadResponse(response) {
    //valid data state
    if (response['data'] != null) { this.state.data = response['data'][0]; }
    else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
    }
    this.state.firstLoad =  false;
    this.stopLoading(false);
    this.logForm.loadData(this.state.data);
    this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
    this.logForm.handleTabChange(this.props.forceTabIndex);
  }
  //Acitons
  handleBackButton() {
    this.props.navigationController.popView();
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().fetchLogEntry(this.props.reportLGID,response => {
      if (!this._isMounted) return;
      this.loadResponse(response);
    });
  }
  //UI
  render() {
    return (<div className="AsyncContainer">
              <CommonLoadingView isLoading={this.state.isLoading}/>
              <LogForm actionType={this.props.editable ? "EDIT" : "VIEW"} app={this.props.app} data={this.state.data}
                       ref={c => (this.logForm = c)}
                       navigationController={this.props.navigationController}
                       alertController={this.props.alertController}/>
            </div>);
  }
}
