import React from "react";
import { Button, Form, Container, Col, Row } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../../components/CustomComponent";
import CommonLoadingView from "./CommonLoadingView";
import AdvisorQuickLogFormChildrenController from "./AdvisorQuickLogFormChildrenController";
import Utils from "./../../components/Utils";
import APIUtils from "./../../components/APIUtils";
//
import "./../../stylesheets/AdvisorLogForm.css";
//
export default class AdvisorQuickLogForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //Safe check for Add action with null props.data
    this.loadData((this.props.data == null ? {} : this.props.data));
  }
  loadData(newData) {
    let consultinghours = 1;
    if (newData.consulting && newData.consulting.consultinghours) {
      consultinghours = Utils.convertHourToMinutes(newData.consulting.consultinghours);
    }

    this.state = {
      data: { ...newData, consulting: { ...(newData && newData.consulting ? newData.consulting : {}), consultinghours } },
      selectedTab: 0, pendingDeleteImages: [] /*images not uploaded*/, unsavedReceipts: [], unsavedDocuments: [] /*images uploaded but not saved*/ };
    this.setState(this.state);
    //Check for IE cache
    this._checkForCachedIE(this.getCurrentActivityYear()); //Check for cached IE in current form date
  }
  //utils
  getCurrentActivityYear() {
    return (this.state.data.activitydate ? new Date(this.state.data.activitydate) : new Date()).getFullYear();
  }
  //Actions
  //Form actions
  handleChange(event) {
    Utils.defaultFormChangeHandler(event, this);
    APIUtils.calculateQuickLogEntryValues(this.props.app.sharedCache(), this.state.data);
    this._checkForCachedIEForEvent(event); //Check for cached IE when date is changed
  }
    //Navigation actions
  handleCancel() {
    //confirmation ask
    if (this.props.actionType != "VIEW")
      if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to discard the changes you made?")) return;
    //Action
    this.props.navigationController.popView();
  }
  handleSubmit(e) {
    e.preventDefault(); //prevent submission call from form
    //actions
    this._submitLog();
  }

  //UI
  render() {
    return (
      <div className="AppPageContainer logContainer">
        <CommonLoadingView isLoading={this.state.isLoading}/>
        <Form ref={f => (this.formEl = f)} onInvalid={this.handleValidation} onSubmit={this.handleSubmit}>
          <Container className="logFormContainer">
            {this.getActionButtons()}
            <h6 className="formLabel">{this.getModalActionTypeText()}</h6>
            <AdvisorQuickLogFormChildrenController viewOnly={(this.props.actionType == "VIEW")} ref={c => (this.childrenController = c)} dataSource={this} app={this.props.app}/>
          </Container>
        </Form>
      </div>
    );
  }

  //Utils
  getModalActionTypeText() {
    if (this.props.actionType == "ADD") return "Add quick entry";
    else if (this.props.actionType == "EDIT") return "Edit quick entry";
    else if (this.props.actionType == "VIEW") return "View quick entry";
    else return "UNKNOWN";
  }
  getSubmitButtonTitleTypeText() {
    if (this.props.actionType == "ADD") return "Create";
    else if (this.props.actionType == "EDIT") return "Save";
    else if (this.props.actionType == "VIEW") return "N/A";
    else return "UNKNOWN";
  }
  getCancelButtonTitleTypeText() {
    if (this.props.actionType == "ADD") return "Cancel";
    else if (this.props.actionType == "EDIT") return "Cancel";
    else if (this.props.actionType == "VIEW") return "Back";
    else return "UNKNOWN";
  }
  getActionButtons() {
    return (
      <Row className="formButtonContainer justify-content-start">
        <Col>
          <Button variant="none" className="appBackBtn" onClick={this.handleCancel}>{this.getCancelButtonTitleTypeText()}</Button>
        </Col>
        <Col className="col-md-auto">
          { this.props.actionType == "VIEW" ? <></> :
              <Button variant="none" className="appSubmitBtn" type="submit">{this.getSubmitButtonTitleTypeText()}</Button>
          }
        </Col>
      </Row>
    );
  }

  //private
  _submitLog() {
    const sendData = JSON.parse(JSON.stringify(this.state.data));
    sendData.companyname = 'BCCSA';
    sendData.consulting.consultinghours = Utils.convertMinutesToHour(sendData.consulting.consultinghours);
    APIUtils.calculateLogEntryValues(this.props.app.sharedCache(), sendData);
    sendData.entrytype = 'quick';
    console.debug('Sending data...', sendData);
    //Choose call
    var APICall = null;
    if (this.props.actionType == "ADD") { //ADD LOG API CALL
      APICall = this.props.app.sharedAPIWrapper().addLogEntry.bind(this.props.app.sharedAPIWrapper());
    } else if (this.props.actionType == "EDIT") { //EDIT LOG API CALL
      APICall = this.props.app.sharedAPIWrapper().editLogEntry.bind(this.props.app.sharedAPIWrapper());
    } else console.log("unknown submit action on log modal!");
    //Make the actual call
    this.startLoading();
    APICall(sendData, this.state.unsavedReceipts, this.state.unsavedDocuments, response => {
      if (!this._isMounted) return;
      if (!response["error"]) {
        this.props.alertController.showSuccessAlert("Success","Log entry " + (this.isEditMode ? "edited" : "added") + " with success!");
        this.props.navigationController.popView();
        //Invalidate IE caching, just in case of new addition
        this.props.app.sharedCache().invalidadeIECacheForTheYear(this.getCurrentActivityYear());
      } else {
        this.props.alertController.showErrorAlert("Error","Error while " + (this.isEditMode ? "editting" : "adding") + " log entry.",response);
      }
      this.stopLoading();
    });
  }
  //IE
  _checkForCachedIEForEvent(event) {
    //Check for IE loading
    if (event.target.id == "activitydate") {
      let year = new Date(event.target.value).getFullYear();
      this._checkForCachedIE(year);
    }
  }
  _checkForCachedIE(year) {
    let cachedIEs = this.props.app.sharedCache().getIEsForYear(year);
    if (cachedIEs == undefined || cachedIEs == null) {
      this.startLoading();
      this.props.app.sharedCache().loadAndCacheIEsForTheYear(year, this.props.app, ()=>{
        this.stopLoading();
      });
    }
  }
}
