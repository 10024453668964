import CUs from "./../../data/cus.json";
class Cache {
		constructor(props) {
			this.authenticatedUser = null;
			this.userSettings = null;
			this.categories = null
			this.users = null;
			this.IEs = {};

			this.newCategories = {
        'COR Program Outreach': [
          'Connect with new COR applicants',
          'WorkSafeBC Initiated Verification Audit (WIVA) support'
        ],
        'Safety Manuals': [
		  'Formal safety manual review and gap analysis (for COR compliance)',
		  'Informal safety manual review',
		  'Assist with improvements to safety manual',
		  'Develop Practices and Procedures',
		  'Small COR Initiative Program safety manual development'
		],
        'On-site Mentoring and Support': [
          'Mentoring inspections',
          'Mentoring hazard assessments',
          'Mentoring Incident Investigation',
          'Mentoring on how to conduct Orientations',
          'JOHS Committee Support'
        ],
		'BCCSA Program Support': [
			'Injury Management (IM)',
			'Safety Climate Tool Survey',
			'Silica Tool',
			'RE-MIND; HeadsUpGuys',
			'CCPO'
		],
		'BCCSA Initiatives': [
			'Tradeshow'
		],
        'WorkSafeBC High-Risk Strategy': [
          'Support members that receive WorkSafe orders and directives (following an officer visit)',
          'RSA Hotline',
          'Other work aligned to WorkSafeBC'
        ],
        'General Safety Support': [
          'General safety questions / emails / phone / social media',
		  'Material development / preparation',
		  'Other'
        ],
				'Asbestos Outreach': [],
        'Admin': [
          'Administration not related to members (e.g., internal meetings.)'
        ],
      }
		}
		//static definitions
		getMaxUploadFileSize() { return 5242880; }
		//Custom setters
		setLoggedUser(authenticatedUser) { this.authenticatedUser = authenticatedUser; }
		setSettings(userSettings) { this.userSettings = userSettings; }
		setCategories(categories) { this.categories = categories; }
		setUsers(users) {
			users.sort((a, b) => {
				let safeDisplayNameA = (a.displayname ? a.displayname : (a.username ? a.username : "n/a"));
				let safeDisplayNameB = (b.displayname ? b.displayname : (b.username ? b.username : "n/a"));
				return safeDisplayNameA.localeCompare(safeDisplayNameB);
			});
			this.users = users;
		}
		setIEsForYear(IEs, year) {
			let rawIEs = [];
			for (let IE of IEs) rawIEs.push({value: IE.pk.replace("IE::","")});
			this.IEs[year] = rawIEs;
		}
		//Categories Wrappers
		getLGCategories() { return this.categories; }
		getCompanySizes() { return [{"pk":"Small","description":"Small"},{"pk":"Large","description":"Large"}]; }
		getCategoryUnits() { return CUs.cus;}
		getNewCategories() {
			return Object.keys(this.newCategories).map(category => ({
				pk: category,
				description: category,
			}));
		}
		getSubCategories(category) {
			const subcategories = this.newCategories[category];
			
			if (!subcategories) return [];

			return subcategories.map(subcategory => ({
				id: subcategory,
				desc: subcategory,
			}));
		}
		//Settings Wrappers
		getSettings() { return this.userSettings; }
		getLoggedAdvisorAnnualMaxExpense() {
			let usr = this.getLoggedUser();
			if(usr != null && usr["annualrsamaxexpenses"])
				return parseFloat(usr["annualrsamaxexpenses"]);
			else if(this.userSettings && this.userSettings["annualrsamaxexpenses"])
				return parseFloat(this.userSettings["annualrsamaxexpenses"]);
			return 0;
		}
		//IEs wrapper
		getIEsForYear(year) { return this.IEs[year]; }
		//User wrapper
		getFormattedUsers() { return this.users; }
			//Get current logged user info
		getLoggedUser() { return this.findUserByID(this.getLoggedUserFormattedUserID()); }
		//JWT Auth token
		getCurrentSession() { return this.authenticatedUser.signInUserSession; }
		getCognitoUser() { return this.authenticatedUser; }
			//JWT Auth token
		getLoggedUserHash() { return this.authenticatedUser.signInUserSession.idToken.jwtToken; }
			//User name string
		getLoggedUserName() {
			if (this.authenticatedUser != null) {
				return this.authenticatedUser["attributes"]["name"];
			} return null;
		}
		//Cognito user ID
		getLoggedUserFormattedUserID() {
			if (this.authenticatedUser != null) {
				return this.authenticatedUser["attributes"]["sub"];
			} return null;
		}
		//Check if any user is logged
		isLogged() { return (this.authenticatedUser != null) }
		//Check if current user is admin
		isLoggedUserAdmin() {
			if (this.authenticatedUser != null) {
				var groups = this.authenticatedUser["signInUserSession"]["accessToken"]["payload"]["cognito:groups"];
				return (groups && groups.includes("Admin"));
			} return false;
		}
		//Search wrappers
		findDisplayNameByID(userID) {
			for (var obj of this.users) { if (obj["rsaid"] == userID) return obj["displayname"]; }
			return "Not found";
		}
		findUserByID(userID) {
			for (var obj of this.users) { if (obj["rsaid"] == userID) return obj; }
			return null;
		}
		findIDByUserName(userName) {
			for (var obj of this.users) { if (obj["username"] == userName) return obj["rsaid"]; }
			return "";
		}
		findCUObjectByID(cuID) {
			for (var obj of CUs.cus) { if (obj["id"] == cuID) return obj; }
			return null;
		}
		//Caching general
		loadAndCacheIEsForTheYear(currYear, app, callback) {
			this.fetchIEsForYear(currYear, app, (IEs) => {
				this.setIEsForYear(IEs, currYear);
				callback();
			});
		};
		invalidadeIECacheForTheYear(year) { this.IEs[year] = null; }
		setupUserCache(app,callback) {
			//Settings
			this.fetchSettings(app, usrSettings => { this.setSettings(usrSettings);
				//Categories
				this.fetchCategories(app, categories => {  this.setCategories(categories);
					//Users or user info
					//if not admin this will fetch the current user info only
					this.fetchUsers(app, users => {
						this.setUsers(users);
						//Fetch current Year IEs
						let currYear = (new Date()).getFullYear();
						this.fetchIEsForYear(currYear, app, IEs => {
							this.setIEsForYear(IEs, currYear);
							callback();
						});
					});
				});
			});
		}


		//Caching calls
		fetchUsers(app, callback) {
			app.sharedAPIWrapper().getUsers(response => {
				if (!response["error"] && response["data"]) callback(response["data"]);
				else callback([]);
			});
		}
		fetchSettings(app, callback) {
			app.sharedAPIWrapper().getSettings(response => {
				if (!response["error"] && response["data"]) callback(response["data"][0]);
				else callback({});
			});
		}
		fetchCategories(app, callback) {
			app.sharedAPIWrapper().getCategories(response => {
				if (!response["error"] && response["data"]) callback(response["data"]);
				else callback([]);
			});
		}
		fetchIEsForYear(year, app, callback) {
			app.sharedAPIWrapper().getIndustryEvents(year, response => {
				if (!response["error"] && response["data"]) callback(response["data"]);
				else callback([]);
			});
		}

};

export default Cache;
