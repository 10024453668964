import React, { Component } from "react";
import { Container, Row, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import autoBind from 'react-autobind';
//
import "./../../stylesheets/CommonMultiButton.css"
//
export default class CommonMultiButton extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.buildFilter();
  }
  //helper
  buildFilter() {
    this.state = {selectedObj: this.props.defaultOption, objs: this.props.options};
  }
  //Public
  getSelectedOption() { return this.state.selectedObj; }
  getSelectedOptionIndex() { return this.state.objs.indexOf(this.state.selectedObj); }
  //Actions
  handleChange(value, event) {
    let hasChanged = false;
    if (this.state.selectedObj != value) hasChanged = true;
    this.state.selectedObj = value;
    this.setState(this.state);
    if (hasChanged) this.props.handleMultiChange();
  }
  //UI
  render() {
    let selected = this.state.selectedObj ? this.state.selectedObj : 0;
    //render
    return (
      <Container className="">
        <Row>
          <ToggleButtonGroup type="radio" name="toggleradio" value={this.state.selectedObj} onChange={this.handleChange}>
            {this.state.objs.map((obj, index) => {
                return(
                  <ToggleButton className={"multiButton "}key={index} value={obj}>{obj}</ToggleButton>
                );
            })}
          </ToggleButtonGroup>
        </Row>
       </Container>
     );
     // <Col className="col-auto ml-auto">
     //   <Button disabled={!this.state.hasChanged} className="goButton" onClick={this.props.handleFilterSubmit} variant="success">Search</Button>
     // </Col>
  }
}
