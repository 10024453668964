import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CommonQuarterlyReportChildrenController from "./CommonQuarterlyReportChildrenController";
//
import "./../../stylesheets/CommonQuarterView.css";
//
export default class CommonQuarterView extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {data: this.props.data, selectedTab: 1};
  }
  //actions
  handleTabChange(idx) { //handle tab change and validation tab change request
    this.state.selectedTab = idx;
    this.setState(this.state);
    this.childrenController.setState({ selectedTab: this.state.selectedTab });
  }
  handleCancel() { this.props.navigationController.popView(); }
  //UI
  render() {
    //render
    return (
    <div className="AppPageContainer logContainer">
      <div className="quarterViewChildContainer">
        <Row className="justify-content-start quarterViewButtonContainer">
          <Col className="col-md-auto">
            <Button variant="none" className="appBackBtn" onClick={this.handleCancel}>Back</Button>
          </Col>
        </Row>
        <CommonQuarterlyReportChildrenController viewOnly={true} ref={c => (this.childrenController = c)} dataSource={this} app={this.props.app}/>
      </div>
    </div>);
  }
}
