import React from "react";
import { Auth } from "aws-amplify";
import { Button, Form, Container, Col, Row } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../components/CustomComponent";
import CommonLoadingView from "./subviews/CommonLoadingView";
//resources
import "./../stylesheets/CommonLogin.css";
import LogoBW from "./../images/logoBW.png";
//
export default class CommonLogin extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { username: "", password: "", isLoading: false, rememberMe: true};
  }
  //Utils
  validateForm() {
    return this.state.username.length > 0;
  }
  //Actions
  handleChange = event => {
    if (event.target.type == "checkbox") this.setState({ [event.target.id]: event.target.checked });
    else this.setState({ [event.target.id]: event.target.value });
  }
	handleLogin = async event => {
	  event.preventDefault(); //prevent form submission
    this.startLoading();
		try {
        //configure storage to remeber or not
        this.props.app.configureAuthStorage(this.state.rememberMe);
        const pass = (this.state.password == undefined ? "" : this.state.password);
		    const user = await Auth.signIn(this.state.username, pass);
		    if (user.challengeName === 'SMS_MFA' ||
		        user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            this.props.alertController.showErrorAlert("Error!","App does not support OTP!");
		    } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
		        //const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            this.props.alertController.showErrorAlert("Error!","New password required but not implemented!");
		    } else if (user.challengeName === 'MFA_SETUP') {
            this.props.alertController.showErrorAlert("Error!","App does not support OTP!");
		    } else {
  				  //this.props.alertController.showSuccessAlert("Logged with success");
  				  this.props.app.setAuthenticatedUser(user);
            this.props.history.push("/"); //push to main page
            return; //DO NOT ALLOW LOADING TO STOP
		    }
		} catch (err) {
		    if (err.code === 'UserNotConfirmedException') {
          this.props.alertController.showErrorAlert("Error!","Account is not confirmed yet!");
		    } else if (err.code === 'PasswordResetRequiredException') {
          this.props.alertController.showErrorAlert("Error!","Account is locked! Password reset is required!\nCheck your email for confirmation code.");
          this.props.history.push("/resetPassword"); //push to reset page
		    } else {
          var errMsg = (err["message"] ? err["message"] : err);
          this.props.alertController.showErrorAlert("Error!",errMsg);
		    }
		}
    this.state.isLoading = false;
    this.setState(this.state);
	}
  //UI
  render() {
    var isLoading = this.state.isLoading || this.props.app.state.isAuthenticating;
    return (
      <div className="AppPageContainer">
        <Container className="authBox">
          <CommonLoadingView isLoading={isLoading} isFixed={true}/>
          <Row className="justify-content-md-center">
            <Col className="col-md-auto">
              <img className="authLogo" alt="logo" src={LogoBW}/>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col className="col-md-auto">
              <h6 className="authLabel">Welcome to the BCCSA RSA Portal!</h6>
            </Col>
          </Row>
          <Form onSubmit={this.handleLogin}>
            <Form.Group controlId="username">
              <Form.Control className="inputFields" autoFocus placeholder="Username or e-mail"
                            value={this.state.username} onChange={this.handleChange}/>
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Control className="inputFields" value={this.state.password}
                            placeholder="Password" onChange={this.handleChange} type="password"/>
            </Form.Group>
            <Form.Group controlId="rememberMe">
                <Form.Check type="checkbox" label="Keep me logged in" checked={this.state.rememberMe}  onChange={this.handleChange}/>
            </Form.Group>
            <Button block className="loginButton"
                    disabled={!this.validateForm()} type="submit">
                    Log in
            </Button>
          </Form>
        </Container>
      </div>
    );
  }
}
