import React from "react";
import ReactTable from "react-table";
import autoBind from 'react-autobind';
import { Row, Col, Container } from "react-bootstrap";
//
import Utils from "./../components/Utils";
import UtilsReport from "./../components/UtilsReport";
import CustomComponent from "./../components/CustomComponent";
import CustomNavigationController from './../components/CustomNavigationController';
import CommonLogFilter from "./subviews/CommonLogFilter";
import CommonQuarterView from "./subviews/CommonQuarterView";
import CommonLoadingView from "./subviews/CommonLoadingView";
import RSAYearlyReportPDF from "./pdfs/RSAYearlyReportPDF";
//import AdvisorQuarterlyReportForm from  "./subviews/AdvisorQuarterlyReportForm";
//
export default class AdvisorYearlyReport extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {data: [], firstLoad: true, isLoading: false};
  }
  loadResponse(response) {
    //valid data state
    if (response['data'] != null) {
      this.state.data = UtilsReport.fillMissingQuarters(response['data'], this.filterObj.getFilters());
      //Render PDF into hidden div
      Utils.renderPDF(RSAYearlyReportPDF, this.props.app, this, () => {
        this.pdfRef.loadData(this.state.data);
      });
    } else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
    }
    this.state.firstLoad =  false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() { if (this.state.firstLoad) this.fetchData(); }

  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().fetchQuarterlyReportsByFilter(this.filterObj.getFilters(), response => {
      if (!this._isMounted) return;
      this.loadResponse(response);
    });
  }
  //Actions
  handleFilterSubmit() { this.fetchData(); }
  handleRowClick(event, row) {
    if (row.original.newEntry == undefined) { //push exisiting MR
      this.props.navigationController.pushView(
        <CommonQuarterView app={this.props.app} alertController={this.props.alertController} data={row.original}/>
      );
    }
  }
  //UI
  render() {
    const data = (this.state.data == null ? [] : this.state.data);
    const props = {
      showPagination: false,
      minRows: 4,
      defaultPageSize: 1000,
      noDataText: "No reports!",
      loadingText: "Loading...",
      keyField :'pk',
      loading:this.state.isLoading,
      getTrProps: (s, r) => {
        return { onClick: (e) => { this.handleRowClick(e, r) }, };
      }
    };
    const columns = [
    {
      Header: 'Submission Year',
      accessor: d => (d['sk'] ? d['sk'].slice(0,4) : ""),
      id: "sky",
      width: 200
    },{
      Header: 'Quarter',
      id: 'skm',
      width: 200,
      accessor: d => ( d['sk'] ? ("Q" + d['sk'].slice(5,6)) : "")
    },{
      Header: 'Advisor Name',
      id: 'pku',
      accessor: d => {
        if (d["newEntry"] == undefined) return (d['pk'] ? this.props.app.sharedCache().findDisplayNameByID(d["pk"].replace("QR::","")) : "");
        else return "Report not submitted! Wait until you get your monthly submission approved to see a preview of this quarter.";
      }
    }];
    //render
    return (
    <div className="AppPageContainer">
      <Container className="filterContainer">
        <Row className="overlayLabelContainer justify-content-between align-items-center">
          <Col className="col-auto">
            <CommonLogFilter app={this.props.app} hideMonth={true} isQRStyle={true}
                             handleFilterSubmit={this.handleFilterSubmit}
                             ref={c => (this.filterObj = c)}/>
          </Col>
          <Col className="col-auto">
            { data.length > 0 && Utils.renderPrintPDFButton(this, true /*is landscape*/) }
          </Col>
        </Row>
      </Container>
      <CommonLoadingView isLoading={this.state.isLoading}/>
      <ReactTable data={data} className="v-100 -highlight" columns={columns} {...props} />
    </div>);
  }
}
//Navigation container implementation
export class AdvisorYearlyReportNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props);
    this.containerClass = AdvisorYearlyReport;
  }
}
