import { API } from "aws-amplify";
import APIUtils from "./APIUtils";
//
//TODO: Make APIWrapper subclasses for each manipulated object
//
export default class APIWrapper {
	constructor(auth) {
		this.auth = auth;
	}
	/** QUARTERLY REPORTS **/
	//Fecth QR of specified advisors and period
		//cal used as advisor and admin
	async fetchQuarterlyReportsByFilter(filter, callback) {
		//Build url
		var URL;
		//if (filter.selectedUser !== "") URL = "/submission?submissionType=QR&submissionyearquarter=" + filter.selectedYear + "&rsaid=" + this.auth.sharedCache().findIDByUserName(filter.selectedUser);
		URL = "/submission?submissionType=QR&submissionyearquarter=" + filter.selectedYear ;
		console.log(URL);
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", URL, this.getRequestOptions())
				.then(response => {
						console.log('######');
						console.log(response);
						console.log('######');
						response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
						console.log("success on fetching quarterly reports for admin");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on fetching quarterly reports for admin");
						callback({error: true, errorData: error, data: null});
				})
			});
	}



	/** MONTHLY REPORTS **/
	//Fecth MS of logged advisor with year and quarter
	async fetchAdvisorMonthlyReportsAttachedToQR(filter, callback) {
		var reports = [];
		var quarter = (filter.selectedQuarter-1) * 3;
		//For each month
		for (var i = 1; i < 4; i++) {
			let innerFilter = {selectedYear: filter.selectedYear, selectedMonth: quarter + i};
			this.fetchMonthlyReportsApprovedByFilter(innerFilter, function(response) {
					if (!response.error) reports.push(response.data);
					else reports.push({});
					if (reports.length == 3) {
						response.data = reports;
						callback(response);
					}
			});
		}
	}
	//Fecth MR history of logged advisor, need month and year filter
	async fetchMonthlyReportsApprovedByPeriod(filter, callback) {
		//Build url
		var period = APIUtils.formatStringToGSI1SK(filter.selectedYear,filter.selectedMonth);
		var URL = "/submission?submissionType=MS&submissionyearmonth="+period;
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", URL, this.getRequestOptions())
				.then(response => {
						response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
						console.log("success on fetching advisor monthly report history");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on fetching advisor monthly report history " + error);
						callback({error: true, errorData: error, data: null});
						console.log(error.stack);
				})
			});
	}
	//Fecth MR history of logged advisor, need user and year filter
	async fetchMonthlyReportsApprovedByFilter(filter, callback) {
		//Build url
		var URL = "/submission?submissionType=MS&submissionyearmonth="+filter.selectedYear;
		if (filter.selectedUser && filter.selectedUser !== "") URL += "&rsaid=" + this.auth.sharedCache().findIDByUserName(filter.selectedUser);
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", URL, this.getRequestOptions())
				.then(response => {
						response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
						console.log("success on fetching advisor monthly report history");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on fetching advisor monthly report history " + error);
						callback({error: true, errorData: error, data: null});
						console.log(error.stack);
				})
			});
	}
	//Fetch all monthly reports with status submitted
	async fetchMonthlyReportsForApproval(callback) {
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", "/submission?submissionType=MS&status=submitted", this.getRequestOptions())
				.then(response => {
						response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
						console.log("success on fetching all monthly reports with status SUBMITTED");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on fetching all monthly reports with status SUBMITTED");
						callback({error: true, errorData: error, data: null});
				})
			});
	}
	//Submit new monthly report for advisor with period and LG entries id
	async submitAdvisorMonthlyReports(data, entries, callback) {
		let period = data.submissionPeriod;
		let invoiceNumber = (data.invoicenumber != undefined ? (data.invoicenumber + "") : "");
		//Build body
		period = period.replace("-","");//remove dash
		var body = {"submissionyearmonth" : period, "lgentries" : entries, "submissionType": "M", "includeyearmonth": [period], "invoicenumber": invoiceNumber};
		console.log(JSON.stringify(body));
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.post("main", "/submission" , this.getRequestOptions(body))
				.then(response => {
						response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
						console.log("success on submitting advisor monthly report");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on submitting advisor monthly report");
						callback({error: true, errorData: error, data: null});
				});
		});
	}
	//Submit MR admin decision of approval or rejection
	async submitMonthlyReportDecision(reportObj, isApproved, callback) {
		//Build body
		var serverEntry = Object.assign({}, reportObj); //get new copy for server manipulation
		var reportID = serverEntry["pk"].replace("MS::","");
		var reportDate = serverEntry["sk"];
		var body = {"rsaid":reportID, "newStatus": (isApproved ? "approved" : "rejected"), "submissionyearmonth":reportDate};
		if (!isApproved) body.rejectionReason = reportObj.rejectionReason;
		//
		console.log(body);
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.put("main", "/submission", this.getRequestOptions(body))
				.then(response => {
						console.log("success on submitting admin monthly report decision");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on submitting admin monthly report decision");
						callback({error: true, errorData: error, data: null});
				})
			});
	}



	/** LOG ENTRIES **/
		//Fetch all LG of the logged advisor
		//this must
	async fetchAdvisorMonthlyReportEntriesByPeriod(filter, callback) {
		var period = APIUtils.formatStringToGSI1SK(filter.selectedYear,filter.selectedMonth);
		var URL = "/logEntry?submissionyearmonth="+period;
		console.log(URL);
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", URL, this.getRequestOptions())
				.then(response => {
						console.log("success on fetching advisor monthly report LG entries by period");
						if (response.lgs && response.lgs.length > 0) response.lgs = APIUtils.decodeAPIData(response.lgs,this.auth.sharedCache());
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on fetching advisor monthly report LG entries by period");
						callback({error: true, errorData: error, data: null});
				})
			});
	}
	//Fetch all log entries of logged advisor with status open
	async fetchAdvisorLogEntries(callback) {
		//Build URL
		var url = "/logEntry?status=open&rsaid=" + this.auth.sharedCache().getLoggedUserFormattedUserID();
		console.log(url);
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", url, this.getRequestOptions())
	    	.then(response => {
	      		console.log("success on fetching LGs with status OPEN");
						response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
	      		callback({error: false, data: response});
	    	})
	    	.catch(error => {
	      		console.log("fail on fetching LGs with status OPEN");
	      		callback({error: true, errorData: error, data: null});
	    	})
			});
	}
	//Fetch specific log entry
	async fetchLogEntry(objLG, callback) {
		//Build URL
		var URL = "/logEntry?rsaid=" + objLG["pk"].replace("LG::","") + "&logid=" + objLG["sk"];
		console.log(URL);
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", URL, this.getRequestOptions())
		  	.then(response => {
		    		console.log("success on fetching LG information");
						response = APIUtils.decodeAPIData(response,this.auth.sharedCache());
		    		callback({error: false, data: response});
		  	})
		  	.catch(error => {
		    		console.log("fail on fetching LG information");
		    		callback({error: true, errorData: error, data: null});
		  	})
			});
	}
	//Fetch all log entries attached to a MR
	//this must
	async fetchLogEntriesAttachedToMR(objMR, callback) {
		//mount URL
		var url = "/logEntry?rsaid=" + objMR["pk"].replace("MS::","") + "&submissionyearmonth=" + objMR["sk"];
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", url, this.getRequestOptions())
				.then(response => {
						if (response.lgs && response.lgs.length > 0) response.lgs = APIUtils.decodeAPIData(response.lgs,this.auth.sharedCache());
						console.log("success on fetching LGs attached to MR");
						console.log(response)
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on fetching LGs attached to MR" + error);
						callback({error: true, errorData: error, data: null});
				})
			});
	}
	async deleteLogEntry(logEntry, callback) {
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
		//make request
			API.del("main", "/logEntry", this.getRequestOptions(logEntry))
	    	.then(response => {
	      		console.log("success on deleting LG");
	      		callback({error: false, data: response});
	    	})
	    	.catch(error => {
	      		console.log("fail on deleting LG");
	      		callback({error: true, errorData: error, data: null});
	    	})
			});
	}
	async editLogEntry(logEntry, newReceipts, newDocuments, callback) {
		//data pre processing
		var serverEntry = Object.assign({}, logEntry); //get new copy for server manipulation
		if (serverEntry.receipts == undefined) serverEntry.receipts = []; //check for valid receipts array
		if (serverEntry.documents == undefined) serverEntry.documents = []; //check for valid documents array
		serverEntry.receipts = Object.assign(serverEntry.receipts, newReceipts); //merge with pending images
		serverEntry.documents = Object.assign(serverEntry.documents, newDocuments); //merge with pending images
		serverEntry = APIUtils.encodeForAPI(serverEntry, this.auth.sharedCache(), true); //encode
		console.log("sending EDIT entry with body:" + JSON.stringify(serverEntry));
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.put("main", "/logEntry", this.getRequestOptions(serverEntry))
		  	.then(response => {
		    		console.log("success on edit LG");
		    		callback({error: false, data: response});
		  	})
		  	.catch(error => {
		    		console.log("fail on edit LG");
		    		callback({error: true, errorData: error, data: null});
		  	})
			});
	}
	async addLogEntry(logEntry, newReceipts, newDocuments, callback) {
		//data pre processing
		var serverEntry = Object.assign({}, logEntry); //get new copy for server manipulation
		serverEntry.receipts = [];
		serverEntry.documents = [];
		serverEntry = APIUtils.encodeForAPI(serverEntry, this.auth.sharedCache(), true); //encode
		console.log("sending ADD entry with body:" + JSON.stringify(serverEntry));
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.post("main", "/logEntry", this.getRequestOptions(serverEntry))
	    	.then(response => {
	      		console.log("success on adding LG");
	      		callback({error: false, data: response});
	    	})
	    	.catch(error => {
	      		console.log("fail on adding LG");
	      		callback({error: true, errorData: error, data: null});
	    	})
			});
	}


	/** USERS **/
	async getUsers(callback) {
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", "/user", this.getRequestOptions())
				.then(response => {
						console.log("success on retrieving users");
						response = APIUtils.decodeAPIUserData(response);
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on retrieving users");
						callback({error: true, errorData: error, data: null});
				})
			});
	}
	async addUser(user,callback) {
		var formattedUser = APIUtils.encodeForAPI(user, this.auth.sharedCache(), false);
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.post("main", "/user", this.getRequestOptions(formattedUser))
				.then(response => {
						console.log("success on adding user");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on adding user");
						callback({error: true, errorData: error, data: null});
				})
			});
	}
	async editUser(user,callback) {
		var formattedUser = APIUtils.encodeForAPI(user, this.auth.sharedCache(), false);
		console.log("sending EDIT user with body:" + JSON.stringify(formattedUser));
		console.log(formattedUser);
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.put("main", "/user", this.getRequestOptions(formattedUser))
				.then(response => {
						console.log("success on editing user");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on editing user");
						callback({error: true, errorData: error, data: null});
				})
			});
	}
	async deleteUser(user,callback) {
		console.log(user)
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.del("main", "/user", this.getRequestOptions({"username": user.username, "rsaid": user.rsaid}))
				.then(response => {
						console.log("success on deleting user");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on deleting user");
						callback({error: true, errorData: error, data: null});
				})
			});
	}
	async resetUserPassword(userName,callback) {
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.put("main", "/user/resetPassword", this.getRequestOptions({"username": userName}))
				.then(response => {
						console.log("success on reseting user password");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on reseting user password");
						callback({error: true, errorData: error, data: null});
				})
			});
	}


	/** SETTINGS **/
	async editSettings(settings,callback) {
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.post("main", "/settings", this.getRequestOptions(settings))
				.then(response => {
						console.log("success on editing settings");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on editing settings");
						callback({error: true, errorData: error, data: null});
				})
			});
	}
	async getSettings(callback) {
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", "/settings", this.getRequestOptions())
				.then(response => {
						console.log("success on retrieving settings");
						callback({error: false, data: response});
				})
				.catch(error => {
						console.log("fail on retrieving settings");
						callback({error: true, errorData: error, data: null});
				})
			});
	}


	/** MONTHLY CATEGORIES **/
	async getCategories(callback) {
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//make request
			API.get("main", "/category", this.getRequestOptions())
					.then(response => {
							console.log("success on retrieving categories");
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on retrieving categories");
							callback({error: true, errorData: error, data: null});
					})
		});
	}

	/** INDUSTRY EVENTS **/
	async getIndustryEvents(year,callback) {
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//mount URL
			var url = "/industryEvent?year=" + year;
			//make request
			API.get("main", url, this.getRequestOptions())
					.then(response => {
							console.log("success on retrieving industry events for the year of " + year);
							callback({error: false, data: response});
					})
					.catch(error => {
							console.log("fail on retrieving industry events for the year of " + year);
							callback({error: true, errorData: error, data: null});
					})
		});
	}


	/** IMAGE **/
	async uploadImage(file, callback) {
		//encode file name
		var hashedFile = APIUtils.encodeUploadFile(this.auth.sharedCache().getLoggedUserFormattedUserID(), file.name);
		console.log("encoded file name " + hashedFile);
		//request signed url
		this._getSingedURL(API.put, null, hashedFile, resp => {
			if (resp['data'] != null && resp["data"]["url"] != null) {
				//Proceed to upload
				this._uploadToS3(file.file, file.type, resp["data"]["url"], hashedFile, callback);
			} else callback(resp); //end with error on signing URL
		});
	}
	async getImage(hashedFile, lgID, callback) {
		//request signed url
		this._getSingedURL(API.get, lgID, hashedFile, resp => {
			if (resp['data'] != null && resp["data"]["url"] != null) {
				//Proceed to upload
				this._getFromS3(resp["data"]["url"], hashedFile, callback);
			} else callback(resp); //end with error on signing URL
		});
	}
	async deleteImage(hashedFile, callback) {
		console.log("delete");
		//request signed url
		this._getSingedURL(API.del, null, hashedFile, resp => {
			if (resp['data'] != null && resp["data"]["url"] != null) {
				//Proceed to upload
				this._deleteFromS3(resp["data"]["url"], hashedFile, callback);
			} else callback(resp); //end with error on signing URL
		});
	}
	/** IMAGE-S3 **/
	async _getFromS3(url, hashedFile, callback) {
		console.log("Using URL: " + url);
		//keep with call
		fetch(url, {method: "GET"})
					.then(response => {
							console.log("success on getting file");
							callback({error: false, data: response.blob(), hashedFile:hashedFile});
					})
					.catch(error => {
							console.log("fail on getting file");
							callback({error: true, errorData: error, data: null});
					})
	}
	async _deleteFromS3(url, hashedFile, callback) {
		console.log("Using URL: " + url);
		//keep with call
		fetch(url, {method: "DELETE"})
					.then(response => {
							console.log("success on deleting file");
							callback({error: false, data: response, hashedFile:hashedFile});
					})
					.catch(error => {
							console.log("fail on deleting file");
							callback({error: true, errorData: error, data: null});
					})
	}
	async _uploadToS3(fileData, fileType, url, hashedFile, callback) {
		//build request
		var opt = { body: fileData,
			 					method: "PUT",
								headers: {
									"Content-type": fileType,
									"Content-length": fileData.length
								}
							};
		//keep with call
		fetch(url, opt)
			.then(response => {
					if (!response.ok) throw Error(response.statusText);
					console.log("success on uploading file");
					callback({error: false, data: response, hashedFile: hashedFile});
			}).catch(error => {
					console.log("fail on uploading file");
					callback({error: true, errorData: error, data: null});
			})
	}
	async _getSingedURL(method, lgID, hashedFile, callback) {
		//validate session
		this.auth.renewSessionIfNeeded(validSession => {
			//
			let path = "/file/" + hashedFile;
			if (this.auth.sharedCache().isLoggedUserAdmin() && lgID != null) { path += "?sub=" + lgID.replace("LG::",""); }
			console.log("path " + path);
			//make request
			method.bind(API)("main", path, this.getRequestOptions())
				.then(response => {
						console.log("success on signing URL for image");
						callback({error: false, data: response, hashedFile: hashedFile});
				})
				.catch(error => {
						console.log("fail on signing URL for image");
						callback({error: true, errorData: error, data: null});
				})
			});
	}




	getRequestOptions(optionalBody, optionalHeader, optionalObj) {
		var value = {
      		headers: {
        		"Authorization": "Bearer " + this.auth.sharedCache().getLoggedUserHash()
						//"App-Agent" : ("RSA-WebApp-" + Utils.getAPPVersion())
      		}
    	}
			//Append optional values
			if (optionalHeader != null) value['headers'] = Object.assign(optionalHeader, value['headers']);
    	if (optionalBody != null) value['body'] = optionalBody;
			if (optionalObj != null) value = Object.assign(value, optionalObj);
    	return value;
	 }
}
