import React, { Component } from 'react';
import ReactTable from "react-table";
//
import Utils from "./../../components/Utils";
import UtilsReport from "./../../components/UtilsReport";
import CommonReportUI from "./CommonReportUI";

// Create Document Component
export default class AdminYearlyReportByRSAPDF extends Component {
  constructor(props) {
    super(props);
    this.loadedData = [];
  }
  loadData(data) {
    if (!data || data.length == 0) return;
    this.rawData = Object.assign([],data);
    this.loadedData = UtilsReport.generateYearlyReportRowsGroupped(this.rawData, true);
    this.forceUpdate(); //request redraw
  }
  render() {
    if (!this.rawData || !this.rawData[0]) return (<div></div>); //optmize
    const props = {
      showPagination: false, noDataText: "No data!",
      minRows: 6, keyField :'sk', loading: false, defaultPageSize: 10000,
      getTrProps: (s, r) => {
        if (typeof r !== "undefined") {
          let color = "white";
          if (r.original.isTotal) color = "#bfd7ff";
          else if (r.original.isQRTotal) color = "#eaebed";
          return {style: {background: color}};
        }
      }
    }
    function getSafeRow(props) { return  props.original.isUser || props.original.isSpace ? " " : Utils.safeNumber(props.value) }
    const columns = [
    {
      width: 110,
      Header: ()=> <div className="alignMidHeader"></div>,
      id: 'sk',
      Cell: props => {
        if (props.original.isTotal) { return (<div className='text-left detailedYearlyReportGrandTotal'><b>Yearly Total</b></div>); }
        else if (props.original.isQRTotal) { return (<div className='text-left detailedYearlyReportGrandTotal'>{props.original.sk}</div>); }
        else if (props.original.isUser ) { return (<div className='text-left detailedYearlyUserLabel'>{props.original.sk}</div>); }
        else if (props.original.isSpace == true) { return (<span><br></br></span>); }
        //mark quarters appropriated
        else if (props.original.sk) {
          let Q = props.original.sk;
          if (props.original.newEntry === true) return (<div className='text-left detailedYearlyReportIncompleteQuarter'>{Q}<span className="detailedYearlyReportIncompleteQuarterDetail">**</span></div>);
          else if (props.original.mscount !== 3) return (<div className='text-left detailedYearlyReportIncompleteQuarter'>{Q}<span className="detailedYearlyReportIncompleteQuarterDetail">*</span></div>);
          else return (<div className='text-left'>{Q.value}</div>);
        } else return "";
      }
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># Admin (hs)</div>,
      accessor: 'consulting.consultingadminhours',
      Cell: props => getSafeRow(props)
    }, {
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of New COR Applicatns<br></br>Contacted</div>,
      accessor: 'cor.numnewcor',
      Cell: props => getSafeRow(props)
    }, {
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of COR Gap <br></br>Analysis</div>,
      accessor: 'cor.numgapanalysis',
      Cell: props => getSafeRow(props)
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of Large <br></br>Business COR <br></br>Consultations</div>,
      accessor: 'cor',
      Cell: props => {
        if (props.original.isUser || props.original.isSpace) return " ";
        if(!props.value) return Utils.safeNumber(props.value);
        return Utils.safeNumber(props.value.totalconsultations) - Utils.safeNumber(props.value.totalsmbconsultations);
      }
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of Small <br></br>Business COR <br></br>Consultations</div>,
      accessor: 'cor.totalsmbconsultations',
      Cell: props => getSafeRow(props)
    },{
      width: 140,
      Header: ()=> <div className="alignMidHeader"># of Scaffolding<br></br>Presentations</div>,
      accessor: 'highrisk.scaffolding',
      Cell: props => getSafeRow(props)
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of Work <br></br>Platforms<br></br>Presentations</div>,
      accessor: 'highrisk.workplatforms',
      Cell: props => getSafeRow(props)
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of Mobile <br></br>Equipment<br></br>Presentations</div>,
      accessor: 'highrisk.mobileequip',
      Cell: props => getSafeRow(props)
    },{
      width: 150,
      Header: ()=> <div className="alignMidHeader"># of Social Media<br></br>Submissions</div>,
      accessor: 'otheractivities.socialmedia',
      Cell: props => getSafeRow(props)
    },{
      width: 140,
      Header: ()=> <div className="alignMidHeader"># on Site <br></br> Consultations</div>,
      accessor: 'cor.totalconsultations',
      Cell: props => getSafeRow(props)
    },{
      width: 110,
      Header: ()=> <div className="alignMidHeader"># of Phone<br></br> Calls</div>,
      accessor: 'contacttype.numphone',
      Cell: props => getSafeRow(props)
    },{
      width: 100,
      Header: ()=> <div className="alignMidHeader"># of <br></br> Emails</div>,
      accessor: 'contacttype.numemail',
      Cell: props => getSafeRow(props)
    },{
      width: 100,
      Header: ()=> <div className="alignMidHeader"># of Text<br></br> Messages</div>,
      accessor: 'contacttype.numtext',
      Cell: props => getSafeRow(props)
    },{
      width: 155,
      Header: ()=> <div className="alignMidHeader">IM<br></br> Consultations</div>,
      accessor: 'iminquiry',
      Cell: props => getSafeRow(props)
    }];
    //
    let reportYear = (this.loadedData && this.loadedData[0] ? this.loadedData[0].year : 0);
    return (
      <div className="PDFPageContainer">
          {CommonReportUI.renderHeader(this.props.app)}
          {CommonReportUI.renderTitleHeader('Yearly Report By RSA - ' + reportYear)}
          <ReactTable data={this.loadedData} className="v-100 PDFQuarterlyTable -highlight" columns={columns} {...props}/>
          {CommonReportUI.renderIncompleteDetails()}
          {CommonReportUI.renderIEsTable(reportYear, this.rawData, this.props.app)}
        </div>
    );
  }
}
