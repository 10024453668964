//imports relative to print stuff - could move it to a print button class futurely
import React from "react";
import ReactDOM from 'react-dom';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
//
export default class Utils  {}
//Data handling helpers
Utils.setNestedObject = function(obj, prop, value) {
  var reference = obj;
  var a = prop.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
      var key = a[i];
      if (i+1 != a.length) { //check if is not last object
        //safe check for sub.object
        if (reference[key] == null || reference[key] == undefined) { reference = reference[key] = {}; }
        else reference = reference[key];
      } else { reference[key] = value; }
  } return obj;
}
Utils.getNestedObject = function(obj, props) {
  var a = props.split('.');
  if (obj == null || obj == undefined) return;
  for (var i = 0, n = a.length; i < n; ++i) {
      var key = a[i];
      if (key in obj) { obj = obj[key]; }
      else { return; }
  } return obj;
}
Utils.safelyGetNumericNestedObject = function(obj, props) {
  return Utils.safeNumber(Utils.getNestedObject(obj,props));
}
Utils.safelySumNumericNestedValues = function(objs, props) {
  let retValue = 0;
  for (let obj of objs) {
    retValue += Utils.safelyGetNumericNestedObject(obj, props);
  } return retValue;
}
Utils.safeNumber = function(value) {
  //safe check for booleans
  if (value === true) return 1;
  //safe check for strings
  var returnValue = parseFloat(value);
  if (isNaN(returnValue)) return 0;
  return returnValue;
}
Utils.toDoubleDigit = function(str) { return String("0" + str).slice(-2); }
Utils.toDateFormat = function(str) {
  if (str != undefined) { return str.split("-").join("/"); }
  return "";
}
Utils.toCurrencyFormat = function(str) {
  if (str !== undefined) {
    str = parseFloat(str);
    if (isNaN(str)) return "0.00";
    return str.toLocaleString('en', {minimumFractionDigits: '2', maximumFractionDigits: 2});
  }
  return "0.00";
}
//Currency manipulation
Utils.safelyFixCurrency = function(value, toFix) {
  if (!toFix) toFix = 2;
  let rountTo = Math.pow(10,toFix);
  const v = parseFloat(Math.round(value * rountTo) / rountTo).toFixed(toFix);
  if (isNaN(v)) return 0;
  return v;
}


//FORM HELPER
Utils.defaultFormChangeHandler = function(event, object) {
  const targetID = event.target.id;
  var targetValue;
  //Choose where to get value
  if (event.target.type == "checkbox") { targetValue = event.target.checked; }
  else { targetValue = event.target.value; }
  //set state
  object.state.data = Utils.setNestedObject(object.state.data,targetID,targetValue)
  object.setState(object.state.data);
}


//DATES
Utils.convertMinutesToHour = function(minutes) {
  const value = minutes / 60;
  const rountTo = Math.pow(10, 2);
  return parseFloat(parseFloat(Math.round(value * rountTo) / rountTo).toFixed(2));
}
Utils.convertHourToMinutes = function(hours) {
  const value = hours * 60;
  return Math.round(value);
}
Utils.getMonthYearDescriptByDate = function(date) {
  const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
  return monthNames[date.getMonth() + 1] + "/" + date.getFullYear().toString().slice(2,4);
}
Utils.getMonthName = function(month) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return monthNames[month-1];
}
Utils.setReportDateRange = function(obj) {
  obj.months = [1,2,3,4,5,6,7,8,9,10,11,12];
  //
  const date = obj.selectedYear ? new Date(Date.UTC(obj.selectedYear, 1, 1, 0, 0, 0)) : new Date();
  delete obj.selectedYear;
  obj.years = [];
  for (var i = -1; i < 2; i++) {
    obj.years.push(date.getFullYear() + i);
  }
  //default for filter
  obj.currentYear = obj.years[1];
  obj.currentPreviousMonth = date.getMonth(); //leave as index 0-11
}


//BROWSER stuff
Utils.downloadBlob = function(blobURL, fileName) {
  //var blobURL = window.URL.createObjectURL(blob);
  var tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName || 'Receipt');

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
}

//Print stuff
Utils.defaultPrintStyle = function(isLandscape) {
  //" + (isLandscape ? "A4 landscape" : "
  return "@media print { body { -webkit-print-color-adjust: exact; } } @page { size: auto;  margin: 15mm 6mm 15mm 6mm; }";
}

Utils.renderPDF = function(PDFElement, app, ref, callback) {
  //Render PDF into hidden div
  //{... Utils.propagateRef(ref, "pdfRef")}
  ReactDOM.render(<PDFElement {... Utils.propagateRef(ref, "pdfRef")} app={app} />, document.getElementById("pdf"), ()=>{ if(callback) callback() });
}
Utils.renderPrintPDFButton = function(ref, isLandscape) {
  return <ReactToPrint pageStyle={Utils.defaultPrintStyle((isLandscape == true))}
                       trigger={() => <Button variant="none" className="logBoxPrintButton"><FontAwesomeIcon icon={faPrint} size="1x"/></Button>}
                       content={() => ref.pdfRef}/>
}

//REACT stuff
  //propagate ref child to get referece
Utils.propagateRef = function(parent, props) {
  return {
    ref: (_ref) => Utils.setNestedObject(parent, props, _ref)
  }
}



//Table stuff
Utils.getSafeLGsRow = function(props, propsName, isCurrency) {
  if (props.isSpace) return " ";
  if (props.isNotData) {
    let val = Utils.getNestedObject(props,propsName);
    if (isNaN(val)) return val
    if (isCurrency) return Utils.toCurrencyFormat(Utils.safelyGetNumericNestedObject(props,propsName));
    return Utils.safelyGetNumericNestedObject(props,propsName);
  }
  if (isCurrency) return Utils.toCurrencyFormat(Utils.safelyGetNumericNestedObject(props,propsName));
  return Utils.safelyGetNumericNestedObject(props,propsName);
}
