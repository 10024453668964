import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap";
//
import APIUtils from  "./../../components/APIUtils";
import UtilsReport from "./../../components/UtilsReport";

// Create Document Component
export default class CommonReportUI extends Component {}

CommonReportUI.renderHeader = function(app) {
  return(
    <header className="header d-flex align-content-center justify-content-end">
      <h1 className="mr-auto logo"></h1>
      <Col className="col-auto"> <h5 style={{color: "gray"}}>Created by: {app.sharedCache().getLoggedUserName()} at {APIUtils.getCurrentDateAndTimeOnUIFormat()}</h5> </Col>
    </header>
   );
}
CommonReportUI.renderTitleHeader = function(titleString) {
  return(
    <Container className="filterContainer">
      <Row className="overlayLabelContainer justify-content-center">
        <Col className="col-auto"><h3>{titleString}</h3></Col>
      </Row>
     </Container>
   );
}
//Common between yearly reports
CommonReportUI.renderIEsTable = function(reportYear, rawData, app) {
  return (
    <Container className="filterContainer">
       <Row className="justify-content-center">
         <h5 className="detailedYearlyReportIETitle">Events attended year: <div className="detailedYearlyReportIETitleYear">{reportYear}</div></h5>
       </Row>
       {rawData.map((userQR, userIndex) => {
         //check if is not yearly summary
         if (userQR.isTotal) return (<div key={userIndex  + "::QR::IE::TOTAL"}></div>);
         //get username and IEs
         let userName = app.sharedCache().findDisplayNameByID(userQR.rsaid);
         let IEs = UtilsReport.generateIndustryEventsReportFromQRs(userQR.qrs);
         //
         return(
           <div key={userIndex + "::QR::IE::USERGROUP"}>
             <Row className="justify-content-between">
               {[0,1,2,3,4].map((obj, index) => {
                 if (index == 0) return(<Col key={userName + "::QR::IE:TITLE"} className="detailedYearlyUserLabel logTableFootTitle text-center"><b>{userName}</b></Col>);
                 else return(<Col key={index + "::QR::IE:TITLE"} className="logTableFootTitle text-center"><b>Q{obj}</b></Col>);
               })}
            </Row>
             {(IEs.length > 0 && IEs.map((obj, index) => {
               return(<Row key={index + "::QR::IE::GROUP"} className="justify-content-between">
                        <Col key={index + "BLANK::QR::IE"} className="detailedYearlyReportIETableValue text-center"></Col>
                         {obj.length > 0 && obj.map((subObj, subIndex) => {
                           return(<Col key={subIndex + "::QR::IE"} className="detailedYearlyReportIETableValue text-center">{subObj.industryevent}</Col>);
                         })}
                      </Row>)
             }))}
           </div>)
       })}
       <br></br>
     </Container>
  );
}
CommonReportUI.renderIncompleteDetails = function() {
  return(
    <Container className="filterContainer detailedYearlyReportIncompleteQuarterDescriptionAdmin">
       <Row className="justify-content-begin">
         <Col className="text-start"><span className="detailedYearlyReportIncompleteQuarterDetail">*</span> are incomplete quarters.</Col>
       </Row>
       <Row className="justify-content-begin">
         <Col className="text-start"><span className="detailedYearlyReportIncompleteQuarterDetail">**</span> are quarters with no submissions.</Col>
       </Row>
     </Container>
  );
}
