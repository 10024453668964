import React from "react";
import { Button, Form, Container, Col, Row, Modal } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../../components/CustomComponent";
import CommonLoadingView from "./../subviews/CommonLoadingView";
import FormInput from "./../../components/FormInput";
import Utils from "./../../components/Utils";
import APIUtils from "./../../components/APIUtils";
//resources
import "./../../stylesheets/AdvisorLogSubmissionModal.css";
//
export default class AdvisorLogSubmissionModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      show: false, isLoading: false, selectedIDs: [],
      data: {submissionPeriod: APIUtils.getPreviousMonthOnUIFormat()}, //compatibility to form system
    };
  }
  //Actions
  handleChange(event) { Utils.defaultFormChangeHandler(event, this); }
  //Actions
  handleSubmitReport(e) {
    e.preventDefault();
    //confirmation message
    let month = Utils.getMonthYearDescriptByDate(new Date(this.state.data.submissionPeriod));
    let msg = `Do you really want to submit a monthly report with ${this.state.selectedIDs.length} activities, for the month of ${month} with total value of $${Utils.toCurrencyFormat(this.state.data.totalclaimaftertax)}?`;
    if (!this.props.alertController.showQuestionAlert("Attention!",msg)) return;
    //
    this._handleReportSubmission();
  }
  //Actions
  handleShow(selectedIDs, LGs) {
    console.log(LGs);
    let calculatedMR = APIUtils.calculateMRInfo(this.props.app.sharedCache(), LGs);
    calculatedMR.submissionPeriod = APIUtils.getDateOnUIFormat(new Date(LGs[0].activitydate), true);
    this.setState({ selectedIDs: selectedIDs, show: true, isLoading: false, data: calculatedMR });
    this.forceUpdate();
  }
  handleHide() { this.setState({show: false, isLoading: false}); }
  //UI
  render() {
    var isLoading = this.state.isLoading || this.props.app.state.isAuthenticating;
    let isEditing = (this.props.viewOnly != true ? true : false);
    return (
      <Modal size="lg" dialogClassName="submissionModalBox" aria-labelledby="contained-modal-title-vcenter" centered className="submissionModal" show={this.state.show} onHide={this.handleHide}>
        <CommonLoadingView isLoading={isLoading}/>
        <Form onSubmit={this.handleSubmitReport}>
          <Modal.Header closeButton>
             <Modal.Title id="contained-modal-title-vcenter"> {isEditing ? "Submit monthly report:" : "Monthly report details:"} </Modal.Title>
           </Modal.Header>
           <Modal.Body>
             <Container>
               <Row className="justify-content-start align-items-end">
                 <Col className="col-8">
                   <FormInput id="submissionPeriod" labelText="Submission period:" enabled={isEditing} isRequired={true} type={"month"} parent={this}/>
                 </Col>
                 <Col className="col-4">
                   <FormInput id="invoicenumber" labelText="Invoice number:" enabled={isEditing} isRequired={false} type={"number"} parent={this}/>
                 </Col>
               </Row>
               <Row className="justify-content-start align-items-end">
                 <h5>Contacts:</h5>
              </Row>
              <Row className="justify-content-start align-items-end">
                <Col className="col-3"><h6>
                  <FormInput id="contacttype.numinperson" labelText="In-person" enabled={false} isRequired={true} type={"number"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="contacttype.numemail" labelText="E-mail" enabled={false} isRequired={false} type={"number"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="contacttype.numphone" labelText="Phone" enabled={false} isRequired={false} type={"number"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="contacttype.numtext" labelText="Text" enabled={false} isRequired={false} type={"number"} parent={this}/>
                </h6></Col>
              </Row>
              <Row className="justify-content-start align-items-end">
                <h5>Consulting:</h5>
              </Row>
              <Row className="justify-content-start align-items-end">
                <Col className="col-3"><h6>
                  <FormInput id="consulting.consultinghours" labelText="Hours" enabled={false} isRequired={true} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="consulting.consultingpayment" labelText="Payment ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="consulting.consultinggst" labelText="GST ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="consulting.consultingaftertax" labelText="Total ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
              </Row>
              <Row className="justify-content-start align-items-end">
                <h5>KM:</h5>
              </Row>
              <Row className="justify-content-start align-items-end">
                <Col className="col-3"><h6>
                  <FormInput id="travel.km" labelText="Traveled" enabled={false} isRequired={true} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="travel.kmreimbursement" labelText="Payment ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="travel.kmgst" labelText="GST ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="travel.kmtotal" labelText="Total ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
              </Row>
              <Row className="justify-content-start align-items-end">
                <h5>Travel:</h5>
              </Row>
              <Row className="justify-content-start align-items-end">
                <Col className="col-3"><h6>
                  <FormInput id="travel.traveltime" labelText="Time" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="travel.travelreimbursement" labelText="Payment ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="travel.traveltimegst" labelText="GST ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                    <FormInput id="travel.traveltimetotal" labelText="Total ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
              </Row>
              <Row className="justify-content-start align-items-end">
                <h5>Meal:</h5>
              </Row>
              <Row className="justify-content-start align-items-end">
                <Col className="col-3" />
                <Col className="col-3"><h6>
                  <FormInput id="meal.mealamount" labelText="Payment ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="meal.mealgst" labelText="GST ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                    <FormInput id="meal.mealtotal" labelText="Total ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
              </Row>
              <Row className="justify-content-start align-items-end">
                <h5>Total:</h5>
              </Row>
              <Row className="justify-content-start align-items-end">
                <Col className="col-3"><h6>
                  <FormInput id="othercosts" labelText="Other ($)" enabled={false} isRequired={true} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="totalclaim" labelText="Costs($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="totalclaimgst" labelText="GST($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
                <Col className="col-3"><h6>
                  <FormInput id="totalclaimaftertax" labelText="Grand Total ($)" enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </h6></Col>
              </Row>
             </Container>
           </Modal.Body>
           <Modal.Footer>
             {isEditing && <Button block type="submit" className="loginButton"> Submit </Button>}
           </Modal.Footer>
         </Form>
      </Modal>
    );
  }

  _handleReportSubmission() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().submitAdvisorMonthlyReports(this.state.data,this.state.selectedIDs, response => {
      if (!this._isMounted) return;
      if (!response['error']) {
        this.props.alertController.showSuccessAlert("Submited!", "Report submitted with success!");
        this.props.submissionDelegate();
        this.handleHide();
      }else {
        this.props.alertController.showErrorAlert("Error", "Error while submitting monthly report.", response);
        this.stopLoading();
      }
    });
  }
}
