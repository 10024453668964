import React, { Component } from "react";
import { Tab, Tabs, Col, Row } from "react-bootstrap";
//
import APIUtils from "./../../components/APIUtils";
import FormInput from "./../../components/FormInput";
//
export default class AdvisorQuickLogFormChildren extends Component {
  constructor(props) {
    super(props);
    this.state = {selectedTab: 1};
  }

  //UI
  render() {
    var editMode = !this.props.viewOnly;
    return (
      <Tabs activeKey={this.state.selectedTab} className="log-nav-item" onSelect={this.props.dataSource.handleTabChange}>
        {this.returnGeneralView(editMode)}
      </Tabs>
    );
  }
  returnGeneralView(editMode) {
    return (
      <Tab className="childBox child1" eventKey="1" title="General">
        <Row className="justify-content-start align-items-end">
          <Col className="col-2">
            <h6>
              <FormInput id="activitydate" labelText="Activity Date" enabled={editMode} isRequired={true} type={"date"} parent={this.props.dataSource} defaultValue={APIUtils.getCurrentDateOnUIFormat()}/>
            </h6>
          </Col>
          <Col className="col-2">
            <h6>
              <FormInput id="consulting.consultinghours" labelText="Time (min)" enabled={editMode} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={1}/>
            </h6>
          </Col>
        </Row>
        <Row className="justify-content-start align-items-end">
          <Col className="col-12">
            <h6>
              <FormInput id="description" labelText="Activity Description" enabled={editMode} isRequired={true} type={"textarea"} parent={this.props.dataSource} placeholderValue={"Enter description"} customProps={{rows: 4}}/>
            </h6>
          </Col>
        </Row>
        <Row className="justify-content-start align-items-end" style={{ marginTop: 20 }}>
          <Col className="col-12">
            <h5 className="logLeftMarginLabel">Contact Type</h5>
          </Col>
        </Row>
        <Row className="justify-content-start align-items-end">
          <Col className="custcol-1-half">
            <h6>
              <FormInput id="contacttype.numinperson" labelText="In-person" enabled={editMode} isRequired={true} type={"number"} parent={this.props.dataSource} defaultValue={0} customProps={{min: 0, max: 1000}}/>
            </h6>
          </Col>
          <Col className="custcol-1-half">
            <h6>
              <FormInput id="contacttype.numemail" labelText="E-mail" enabled={editMode} isRequired={true} type={"number"} parent={this.props.dataSource} defaultValue={0} customProps={{min: 0, max: 1000}}/>
            </h6>
          </Col>
          <Col className="custcol-1-half">
            <h6>
              <FormInput id="contacttype.numphone" labelText="Phone" enabled={editMode} isRequired={true} type={"number"} parent={this.props.dataSource} defaultValue={0} customProps={{min: 0, max: 1000}}/>
            </h6>
          </Col>
          <Col className="custcol-1-half">
            <h6>
              <FormInput id="contacttype.numtext" labelText="Text" enabled={editMode} isRequired={true} type={"number"} parent={this.props.dataSource} defaultValue={0} customProps={{min: 0, max: 1000}}/>
            </h6>
          </Col>
          <Col className="custcol-1-half">
            <h6>
              <FormInput id="contacttype.numonline" labelText="Online" enabled={editMode} isRequired={true} type={"number"} parent={this.props.dataSource} defaultValue={0} customProps={{min: 0, max: 1000}}/>
            </h6>
          </Col>
        </Row>
      </Tab>
    );
  }
}
