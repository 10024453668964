//Log rocket
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
//
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
//Compoennts
import App from "./app/App";
//
import './app/stylesheets/index.css';
//DEVEL
import * as serviceWorker from './serviceWorker';
//
if (!process.env.REACT_APP_OFFLINE) {
  LogRocket.init('ue6tsn/rsa-rukyi');
  setupLogRocketReact(LogRocket);
}
//Render router with App
ReactDOM.render(
  <Router>
  	<App/>
  </Router>,
  document.getElementById("root")
);
//DEVEL
serviceWorker.unregister();
