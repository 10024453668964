import React from "react";
import { Button } from "react-bootstrap";
import autoBind from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShippingFast } from '@fortawesome/free-solid-svg-icons';

//
import APIUtils from "./../components/APIUtils";
import Utils from "./../components/Utils";
import CustomComponent from "./../components/CustomComponent";
import CustomNavigationController from '../components/CustomNavigationController';
import CommonLoadingView from "./subviews/CommonLoadingView";
import LogForm from "./subviews/AdvisorLogForm";
import QuickLogForm from "./subviews/AdvisorQuickLogForm";
import LogGauge from "./subviews/AdvisorLogGauge";
import LogTable from "./subviews/AdvisorLogTable";
import LogSubmissionModal from "./subviews/AdvisorLogSubmissionModal";
import eyeIcon from '../images/eye-outlined.svg';
//
import "./../stylesheets/AdvisorLog.css";
//
export default class AdvisorLog extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: [], firstLoad: true, isLoading: false, sorted: [] };
  }
  //states
  loadResponse(response) {
    if (response['data'] != null) { //valid data state
      this.state.data = response["data"];
      this.tableRef.selectAllRows(response['data']);
      //check for router state -- if on scope it's adding or duplicating the object
      if (this.props.app.props.history.location.state != undefined) { this.handleDuplicatedLog(); }
    }else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
    }
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().fetchAdvisorLogEntries(response => {
      if (!this._isMounted) return;
      this.loadResponse(response);
    });
  }
  handleDuplicatedLog() {
    let dupObj = Object.assign({}, this.props.app.props.history.location.state);
    this.props.app.props.history.location.state = undefined;
    this.props.navigationController.pushView(
      <LogForm actionType="ADD" data={dupObj} app={this.props.app}
               alertController={this.props.alertController}/>
    );
  }
  handleAddLog() {
    this.props.navigationController.pushView(
      <LogForm actionType="ADD" app={this.props.app}
               alertController={this.props.alertController}/>
    );
  }
  handleAddQuickLog() {
    this.props.navigationController.pushView(
      <QuickLogForm actionType="ADD" app={this.props.app}
               alertController={this.props.alertController}/>
    );
  }
  handleSubmitReport() {
    var selectedIDs = (this.tableRef === undefined ? [] : this.tableRef.getSelectedIDS());
    var filteredLGs = APIUtils.filterLGEntriesBySelectedIDs(selectedIDs, this.state.data);
    this.submissionModal.handleShow(selectedIDs, filteredLGs);
  }
  handleSorted(sorted) {
    this.setState({ sorted });
  }
  handleResetSorted() {
    this.setState({ sorted: [] });
  }
  //Table handler
  handleTableDeleteUpdate() { this.fetchData(); } //this call will be made by table to request DATA update
  handleTableSelectionUpdate() { this.forceUpdate(); } //this call will be made by table to request UI update because of seleciton change
  //UI
  render() {
    //check if have any selected row, if not, disable submission button
    var selectedIDs = (this.tableRef === undefined ? [] : this.tableRef.getSelectedIDS());
    var disableSubmission = (selectedIDs.length == 0);
    //render
    return (
    <div className="AppPageContainer">
      <CommonLoadingView isLoading={this.state.isLoading}/>
      <LogGauge percentage={APIUtils.getAdvisorExpensesPercentageFromLGs(this.state.data, this.props.app.sharedCache())}>
        <div className="addLogActionContainer">
          <div>
            <Button className="addLogButton auto" variant="none" onClick={this.handleAddLog}>╋</Button>
            <div className="addLogButtonText">New</div>
          </div>
          <div>
            <Button className="addLogButton noBorder auto" variant="none" onClick={this.handleSubmitReport} disabled={disableSubmission}>
              <img src={eyeIcon} alt="Preview Report" />
            </Button>
            <div className="addLogButtonText">Preview</div>
          </div>
        </div>
      </LogGauge>
      {/* <Row className="logButtonContainer justify-content-start">
        <Col></Col>
        <Col className="col-md-auto">
          <Button variant="none" className="logBoxSubmissionButton" disabled={disableSubmission}
                  onClick={this.handleSubmitReport}>Preview Report</Button>
        </Col>
      </Row> */}

      <LogTable data={this.state.data} uiUpdateHandler={this.handleTableSelectionUpdate} loaderReference={this}
                deletedUpdateHandler={this.handleTableDeleteUpdate} {... Utils.propagateRef(this, "tableRef")} app={this.props.app}
                alertController={this.props.alertController} navigationController={this.props.navigationController}
                sorted={this.state.sorted} onSort={this.handleSorted} onResetSort={this.handleResetSorted}/>

      <LogSubmissionModal {... Utils.propagateRef(this, "submissionModal")} submissionDelegate={this.fetchData}
                          app={this.props.app} alertController={this.props.alertController}/>
    </div>);
  }
}
//Navigation container implementation
export class AdvisorLogNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props, { preserveState: true });
    this.containerClass = AdvisorLog;
  }
}
