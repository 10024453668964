import React, { Component } from "react";
import { Container, Row, Col  } from "react-bootstrap";
//
import "./../../stylesheets/AdvisorLogGauge.css";
//
export default class AdvisorLogGauge extends Component {
  render() {
    let percentage = (this.props.percentage && !isNaN(this.props.percentage) ? this.props.percentage.toFixed(1) : 0.0);
    let cappedPercentage = Math.min(percentage,100);
    //
    return (
      <div className="logGaugeBackground">
        <Container className="logGaugeContainer">
            <Row className="logGaugeContainerBackground">
              <Col className="col">
                <div className="logGaugeBar">
                  <div style={this.getUsedBackgroundStyle(percentage,cappedPercentage)} className="logGaugeUsedBackground"></div>
                  <div className="logGaugeBarText">
                    <h2 className="logGaugeMainLabel">{percentage + "%"}</h2>
                    <h4 className="logGaugeSecondaryLabel">of your monthly budget</h4>
                  </div>
                </div>
              </Col>
              <Col md={1} className="logGaugeButtonContainer my-auto">
                {this.props.children}
              </Col>
            </Row>
        </Container>
      </div>);
    }
    getUsedBackgroundStyle(p,cappedP) {
      return {
        "width": ("calc(" + cappedP + "% - 100px)"),
        "backgroundColor": (p > 100 ? "#ED6561" : "#dcaba6")
      }
    }
}
