import React from "react";
import { Auth } from "aws-amplify";
import { Button, Form, Modal } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../components/CustomComponent";
import CommonLoadingView from "./subviews/CommonLoadingView";
//resources
import "./../stylesheets/CommonLogin.css";
//
export default class CommonChangePassword extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      previousPassword: "",
      password: "",
      passwordConfirmation: "",
      isLoading: false,
      show: false
    };
  }
  //Utils
  validateForm() { return this.state.passwordConfirmation.length > 0 && this.state.password.length > 0 && this.state.previousPassword.length > 0; }
  //Actions
  handleShow() {
    this.setState({
      previousPassword: "",
      password: "",
      passwordConfirmation: "",
      isLoading: false,
      show: true
    });
  }
  handleHide() { this.setState({show: false}); }
  handleChange = event => { this.setState({ [event.target.id]: event.target.value }); }
  handleChangePassword = async event => {
    event.preventDefault();
    //
    if (this.state.passwordConfirmation != this.state.password) {
      this.props.alertController.showErrorAlert("Error!","Passwords dont match!");
      return;
    }
    this.startLoading();
    // Collect confirmation code and new password, then
    await Auth.changePassword(this.props.app.sharedCache().authenticatedUser, this.state.previousPassword, this.state.password)
    .then(data => {
      this.props.alertController.showSuccessAlert("Success!","Password updated!");
      this.state.show = false;
    })
    .catch(err => {
      this.props.alertController.showErrorAlert("Error!",err["message"]);
    });
    this.stopLoading();
	}
  //UI
  render() {
    var isLoading = this.state.isLoading || this.props.app.state.isAuthenticating;
    return (
      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={this.state.show} onHide={this.handleHide}>
        <CommonLoadingView isLoading={isLoading}/>
        <Form onSubmit={this.handleChangePassword}>
          <Modal.Header closeButton>
             <Modal.Title id="contained-modal-title-vcenter"> Change password: </Modal.Title>
           </Modal.Header>
           <Modal.Body>
             <h6>Previous password:</h6>
             <Form.Group controlId="previousPassword">
               <Form.Control value={this.state.previousPassword} className="inputFields" placeholder="Previous password"
                             onChange={this.handleChange} type="password"/>
             </Form.Group>
             <h6>New password:</h6>
             <Form.Group controlId="password">
               <Form.Control value={this.state.password} className="inputFields" placeholder="Password"
                             onChange={this.handleChange} type="password"/>
             </Form.Group>
             <Form.Group controlId="passwordConfirmation">
               <Form.Control value={this.state.passwordConfirmation} className="inputFields" placeholder="Password confirmation"
                             onChange={this.handleChange} type="password"/>
             </Form.Group>
           </Modal.Body>
           <Modal.Footer>
             <Button block disabled={!this.validateForm()}
                     type="submit" className="loginButton">
                     Update password
             </Button>
           </Modal.Footer>
         </Form>
      </Modal>
    );
  }
}
