import React, { Component } from "react";
import { Tab, Tabs, Col, Row } from "react-bootstrap";
//
import Utils from "./../../components/Utils";
//
export default class CommonQuarterlyReportChildrenController extends Component {
  constructor(props) {
    super(props);
    this.state = {selectedTab: 1};
  }
  //UI
  render() {
    var editMode = !this.props.viewOnly;
    return (
      <Tabs activeKey={this.state.selectedTab} className="log-nav-item" onSelect={this.props.dataSource.handleTabChange}>
        {this.returnSummaryView(editMode)}
        {this.returnInjuryManagementView(editMode)}
      </Tabs>
    );
  }
  returnSummaryView(editMode) {
    return (
      <Tab className="childBox child1" eventKey="1" title="Summary">
        <div className="quarterlyInfoBox">
          <Row className="justify-content-center align-items-center quarterlyInfoBoxContainer">
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># New visits</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"companyvisit.new")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Follow up visits</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"companyvisit.followup")}</h5>
            </Col>
            {/* <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># On site consultations</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.totalconsultations")}</h5>
            </Col> */}
          </Row>
        </div>
        <div className="quarterlyInfoBox">
          <Row className="justify-content-center align-items-center quarterlyInfoBoxContainer">
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># SMS New</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"sms.new")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># SMS Follow up</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"sms.followup")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Program New</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"program.new")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Program Follow up</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"program.followup")}</h5>
            </Col>
          </Row>
        </div>
        <div className="quarterlyInfoBox">
          <Row className="justify-content-center align-items-center quarterlyInfoBoxContainer">
            {/* <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># New COR Applicants</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.numnewcor")}</h5>
            </Col> */}
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Gap Analysis</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.numgapanalysis")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># COR Gap Analysis</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.numgapanalysisCOR")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># COR Gap Analysis Follow up</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.numgapanalysisCORFollowup")}</h5>
            </Col>
          </Row>
        </div>
        <div className="quarterlyInfoBox">
          <Row className="justify-content-center align-items-center quarterlyInfoBoxContainer">
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># COR Certified (Small)</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.numcorcertifiedsmall")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># COR Registered (Small)</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.numcorregisteredsmall")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># COR Unregistered (Small)</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.numcorunregisteredsmall")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Small Business</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.totalsmbconsultations")}</h5>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center quarterlyInfoBoxContainer">
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># COR Certified (Large)</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.numcorcertifiedlarge")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># COR Registered (Large)</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.numcorregisteredlarge")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># COR Unregistered (Large)</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.numcorunregisteredlarge")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Large Business</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.totalconsultations") - Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"cor.totalsmbconsultations")}</h5>
            </Col>
          </Row>
        </div>
        <div className="quarterlyInfoBox">
          <Row className="justify-content-center align-items-center quarterlyInfoBoxContainer">
            <Col className="col-2 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># of General Presentations</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"highrisk.generalpresentations")}</h5>
            </Col>
            <Col className="col-2 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># of Fall Presentations</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"highrisk.falls")}</h5>
            </Col>
            <Col className="col-2 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># of Scaffolding Presentations</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"highrisk.scaffolding")}</h5>
            </Col>
            <Col className="col-2 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># of Work Platforms Presentations</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"highrisk.workplatforms")}</h5>
            </Col>
            <Col className="col-2 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># of Mobile Equipment Presentations</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"highrisk.mobileequip")}</h5>
            </Col>
          </Row>
        </div>
        <div className="quarterlyInfoBox">
          <Row className="justify-content-center align-items-center quarterlyInfoBoxContainer">
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Social Media Submissions</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"otheractivities.socialmedia")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Phone Calls</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"contacttype.numphone")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Emails</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"contacttype.numemail")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Text Messages</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"contacttype.numtext")}</h5>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center quarterlyInfoBoxContainer">
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># IM Consultations</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"iminquiry")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Online</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"contacttype.numonline")}</h5>
            </Col>
          </Row>
        </div>
        <div className="quarterlyInfoBox">
          <Row className="justify-content-center align-items-center quarterlyInfoBoxContainer">
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Safety Climate Tool</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"otheractivities.safetyclimatetool")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># Overdose Awareness</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"otheractivities.overdosePresentation")}</h5>
            </Col>
            <Col className="col-3 quarterlyInfoColumn">
              <h5 className="quarterlyInfoLabel"># WIVA</h5>
              <h5 className="quarterlyInfoValue">{Utils.safelyGetNumericNestedObject(this.props.dataSource.state.data,"otheractivities.wiva")}</h5>
            </Col>
          </Row>
        </div>
      </Tab>
    );
  }
  returnInjuryManagementView(editMode) {
    var safeIM = (this.props.dataSource.state.data.im != undefined ? this.props.dataSource.state.data.im: []);
    return (
      <Tab className="childBox child2" eventKey="2" title="Injury Management">
        <Row className="justify-content-center align-items-center quarterlyIMHeaderContainer">
          <Col className="col-3"> <h6>Employer Name</h6> </Col>
          <Col className="col-3 offset-1"> <h6># of Attendees</h6> </Col>
        </Row>
        {safeIM.map(
          (obj, idx) => {
            return(
              <Row className="justify-content-center align-items-center quarterlyIMEntriesContainer" key={idx}>
                <Col className="col-3"> <h6>{obj.companyname}</h6> </Col>
                <Col className="col-3 offset-1"> <h6>{obj.attendees}</h6> </Col>
              </Row>
            );
          }
        )}
      </Tab>
    );
  }
}
