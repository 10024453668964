import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../components/CustomComponent";
import CustomNavigationController from '../components/CustomNavigationController';
import CommonLoadingView from "./subviews/CommonLoadingView";
import Utils from "./../components/Utils";
import FormInput from "./../components/FormInput";
import "./../stylesheets/AdminSettings.css";
//
export default class AdminSettings extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this,"handleChange","handleSubmit", "fetchData");
    this.state = { data: [], firstLoad: true, isLoading: false };
  }
  loadResponse(response) {
    if (response['data'] != null) { //valid data state
      this.props.app.sharedCache().setSettings(response["data"]); //set cache again!
      this.state.data = response['data'][0];
    }else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = {};
    }
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().getSettings(response => {
      if (!this._isMounted) return;
      this.loadResponse(response);
    });
  }
  //Actions
  handleChange(e) {
    Utils.defaultFormChangeHandler(e, this);
  }
  handleSubmit(e) {
    e.preventDefault();
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to save settings? ")) return;
    //
    this.startLoading();
    this.props.app.sharedAPIWrapper().editSettings(this.state.data ,response => {
      if (!this._isMounted) return;
      if (!response["error"]) {
        this.props.alertController.showSuccessAlert("Success","Settings edited with success!");
        this.fetchData();
      } else {
        this.props.alertController.showErrorAlert("Error","Error while editing settings.",response);
        this.stopLoading();
      }
    });
  }
  //UI
  render() {
    return (
      <div className="AppPageContainer">
        <CommonLoadingView isLoading={this.state.isLoading}/>
          <Form className="formSettings" onSubmit={this.handleSubmit}>
          <Container className="buttonSettingsContainer">
            <Row className="justify-content-end">
              <Col className="col-auto">
                <Button className="appSubmitBtn" variant="none" type="submit">Save</Button>
              </Col>
            </Row>
          </Container>
          <h3>Settings:</h3><br></br>
          <Container>
              <Row className="justify-content-start align-items-end">
                <Col className="col-4"><h4>
                  <FormInput id="longtravelkm" labelText="Trips over this amount will be considered long distance:" enabled={true} isRequired={true} type={"number"} parent={this} defaultValue={0} customProps={{min: 0}}/>
                </h4></Col>
                <Col className="col-4 offset-2"><h4>
                  <FormInput id="valuekmdriven" labelText="Amount paid per Km driven:" enabled={true} isRequired={true} type={"number.2"} parent={this} defaultValue={0} prefixValue={"$"} customProps={{"step": "0.01", min: 0}}/>
                </h4></Col>
              </Row><Row className="justify-content-start align-items-end">
                <Col className="col-4"><h4>
                  <FormInput id="annualrsamaxexpenses" labelText="Annual budget (not enforced) for expenses claimed by an RSA:" enabled={true} isRequired={true} type={"number.2"} parent={this} defaultValue={0} prefixValue={"$"} customProps={{"step": "0.01", min: 0}}/>
                </h4></Col>
                <Col className="col-4 offset-2"><h4>
                  <FormInput id="gstrate" labelText="Gst rate used:" enabled={true} isRequired={true} type={"number"} parent={this} prefixValue={"%"} customProps={{"step": "0.01", min: 0}} />
                </h4></Col>
              </Row><Row className="justify-content-start align-items-end">
                <Col className="col-4"><h4>
                  <FormInput id="emailpayments" labelText="Email address where payment orders will be sent to:" enabled={true} isRequired={true} type={"email"} parent={this} defaultValue={"example@example.com"}/>
                </h4></Col>
                <Col className="col-4 offset-2"><h4>
                  <FormInput id="adminemail" labelText="Email address used to send automatic emails to admin:" enabled={true} isRequired={true} type={"email"} parent={this} defaultValue={"example@example.com"}/>
                </h4></Col>
              </Row><Row className="justify-content-start align-items-end">
                <Col className="col-4"><h4>
                  <FormInput id="timepaidshortdistance" labelText="Multiply travel time on short trips by:" enabled={true} isRequired={true} type={"number"} parent={this} defaultValue={0} customProps={{min: 0}}/>
                </h4></Col>
                <Col className="col-4 offset-2"><h4>
                  <FormInput id="timepaidlongdistance" labelText="Multiple travel time on long trips by:" enabled={true} isRequired={true} type={"number"} parent={this} defaultValue={0} customProps={{min: 0}}/>
                </h4></Col>
              </Row>
          </Container>
        </Form>
      </div>
    );
  }
}
//Navigation container implementation
export class AdminSettingsNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props);
    this.containerClass = AdminSettings;
  }
}
