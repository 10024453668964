import React from "react";
import ReactTable from "react-table";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../components/CustomComponent";
import CustomNavigationController from './../components/CustomNavigationController';
import Utils from "./../components/Utils";
import CommonLogFilter from "./subviews/CommonLogFilter";
import CommonLogTable from "./subviews/CommonLogTable";
import CommonLoadingView from "./subviews/CommonLoadingView";
//
export default class AdminReportHistory extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //
    this.state = {data: [], firstLoad: true, isLoading: false};
  }
  loadResponse(response) {
    //valid data state
    if (response['data'] != null) { this.state.data = response['data']; }
    else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
    }
    this.state.firstLoad =  false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().fetchMonthlyReportsApprovedByPeriod(this.filterObj.getFilters(), response => {
      if (!this._isMounted) return;
      this.loadResponse(response);
    });
  }
  //Actions
  handleFilterSubmit() { this.fetchData(); }
  handleRowClick(event, row) {
    this.props.navigationController.pushView(
      <CommonLogTable reportMR={row.original} app={this.props.app}
                       alertController={this.props.alertController}/>
    );
  }
  //UI
  render() {
    const data = (this.state.data == null ? [] : this.state.data);
    const props = {
      showPagination: false,
      minRows: 6,
      defaultPageSize: 1000,
      noDataText: "No reports!",
      loadingText: "Loading...",
      defaultSorted: [{ id: "sky", desc: false}, { id: "skm", desc: false}],
      keyField :'pk',
      loading:this.state.isLoading,
      getTrProps: (s, r) => {
        return { onClick: (e) => { this.handleRowClick(e, r) } };
      }
    };
    const columns = [
    {
      Header: 'Submission Year',
      accessor: d => (d['sk'] ? d['sk'].slice(0,4) : ""),
      id: "sky",
      width: 200
    },{
      Header: 'Submission Month',
      id: 'skm',
      width: 200,
      accessor: d => d.sk ? d.sk.slice(4,6) : "",
      Cell: props => { return Utils.getMonthName(props.value) }
    },{
      Header: 'Advisor Name',
      id: 'pku',
      accessor: d => (d['pk'] ? this.props.app.sharedCache().findDisplayNameByID(d["pk"].replace("MS::","")) : "")
    },{
      Header: 'Total Costs($)',
      width: 200,
      accessor: d => Utils.toCurrencyFormat(d['totalclaimaftertax']),
      id:"totalclaimaftertax"
    },{
      Header: 'Status',
      accessor: 'gsi1pk',
      width: 200
    },{
      Header: 'Gap Analysis',
      id: 'numgapanalysisCOR',
      accessor: d => d.cor.numgapanalysisCOR
    }];
    //render
    return (
    <div className="AppPageContainer">
      <CommonLoadingView isLoading={this.state.isLoading}/>
      <CommonLogFilter app={this.props.app}
                       handleFilterSubmit={this.handleFilterSubmit}
                       ref={c => (this.filterObj = c)}/>
      <ReactTable data={data} className="v-100 -highlight" columns={columns} {...props} />
    </div>);
  }
}
//Navigation container implementation
export class AdminReportHistoryNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props);
    this.containerClass = AdminReportHistory;
  }
}
