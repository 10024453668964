import React from "react";
import { Button, Form, Container, Col, Row } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../../components/CustomComponent";
import CommonLoadingView from "./CommonLoadingView";
import AdvisorLogFormChildrenController from "./AdvisorLogFormChildrenController";
import Utils from "./../../components/Utils";
import APIUtils from "./../../components/APIUtils";
//
import "./../../stylesheets/AdvisorLogForm.css";
//
export default class AdvisorLogForm extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    //Safe check for Add action with null props.data
    this.loadData((this.props.data == null ? {} : this.props.data));
  }
  loadData(newData) {
    this.state = { data: newData, selectedTab: 0, pendingDeleteImages: [] /*images not uploaded*/, unsavedReceipts: [], unsavedDocuments: [] /*images uploaded but not saved*/ };
    this.setState(this.state);
    //Check for IE cache
    this._checkForCachedIE(this.getCurrentActivityYear()); //Check for cached IE in current form date
  }
  //utils
  getCurrentActivityYear() {
    return (this.state.data.activitydate ? new Date(this.state.data.activitydate) : new Date()).getFullYear();
  }
  //Actions
    //Tab actions
  handleTabChange(idx) {
    this.state.selectedTab = idx;
    this.setState(this.state);
    this.childrenController.setState({ selectedTab: this.state.selectedTab });
  }
    //Image gallery actions
  handleImageDelete(event, mode) { //handle specific image delete
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!", `Do you really want to delete this ${mode === 'receipts' ? 'receipt' : 'document' }?`)) return;

    //mark image for deletion
    var fileName = event.target.id;
    this.state.pendingDeleteImages.push(fileName);
    
    if (mode === 'receipts') {
      //try to remove from receipts
      if (this.state.data.receipts != undefined)
        this.state.data.receipts = this.state.data.receipts.filter(item => item.path !== fileName);
      //try to remove from unsaved receipts
      if (this.state.unsavedReceipts != undefined)
        this.state.unsavedReceipts = this.state.unsavedReceipts.filter(item => item.path !== fileName);
    } else {
      //try to remove from receipts
      if (this.state.data.documents != undefined)
        this.state.data.documents = this.state.data.documents.filter(item => item.path !== fileName);
      //try to remove from unsaved receipts
      if (this.state.unsavedDocuments != undefined)
      this.state.unsavedDocuments = this.state.unsavedDocuments.filter(item => item.path !== fileName);
    }

    this.setState(this.state);
  }
  handleImageUpload(file, mode) { this._uploadImage(file, mode); }
  //Form actions
  handleChange(event) {
    Utils.defaultFormChangeHandler(event, this);
    const targetID = event.target.id;
    APIUtils.calculateLogEntryValues(this.props.app.sharedCache(), this.state.data, targetID);
    APIUtils.checkAndSetDataIfIsRsaAdmin(this.state.data, targetID);
    this._checkForCachedIEForEvent(event); //Check for cached IE when date is changed
  }
  handleValidation(e) { return this._validateForm(e); }
    //Navigation actions
  handleCancel() {
    //confirmation ask
    if (this.props.actionType != "VIEW")
      if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to discard the changes you made?")) return;
    //Action
    this._discardUnsavedImages(); //discard pending images
    this.props.navigationController.popView();
  }
  handleSubmit(e) {
    e.preventDefault(); //prevent submission call from form
    if (!this.handleValidation(e)) return;
    //confirmation ask
    if (!this.state.data.travel.traveltime && !this.state.data.consulting.consultinghours)
      if (!this.props.alertController.showQuestionAlert("Attention!","You have not entered any time for this activity, proceed anyway?")) return;
    //actions
    this._submitLog();
  }

  //UI
  render() {
    return (
      <div className="AppPageContainer logContainer">
        <CommonLoadingView isLoading={this.state.isLoading}/>
        <Form ref={f => (this.formEl = f)} onInvalid={this.handleValidation} onSubmit={this.handleSubmit}>
          <Container className="logFormContainer">
            {this.getActionButtons()}
            <h6 className="formLabel">{this.getModalActionTypeText()}</h6>
            <AdvisorLogFormChildrenController viewOnly={(this.props.actionType == "VIEW")} ref={c => (this.childrenController = c)} dataSource={this} app={this.props.app}/>
          </Container>
        </Form>
      </div>
    );
  }

  //Utils
  getModalActionTypeText() {
    if (this.props.actionType == "ADD") return "Add activity";
    else if (this.props.actionType == "EDIT") return "Edit activity";
    else if (this.props.actionType == "VIEW") return "View activity";
    else return "UNKNOWN";
  }
  getSubmitButtonTitleTypeText() {
    if (this.props.actionType == "ADD") return "Create";
    else if (this.props.actionType == "EDIT") return "Save";
    else if (this.props.actionType == "VIEW") return "N/A";
    else return "UNKNOWN";
  }
  getCancelButtonTitleTypeText() {
    if (this.props.actionType == "ADD") return "Cancel";
    else if (this.props.actionType == "EDIT") return "Cancel";
    else if (this.props.actionType == "VIEW") return "Back";
    else return "UNKNOWN";
  }
  getActionButtons() {
    return (
      <Row className="formButtonContainer justify-content-start">
        <Col>
          <Button variant="none" className="appBackBtn" onClick={this.handleCancel}>{this.getCancelButtonTitleTypeText()}</Button>
        </Col>
        <Col className="col-md-auto">
          { this.props.actionType == "VIEW" ? <></> :
              <Button variant="none" className="appSubmitBtn" type="submit">{this.getSubmitButtonTitleTypeText()}</Button>
          }
        </Col>
      </Row>
    );
  }

  //private
  _submitLog() {
    const sendData = this.state.data;
    //Choose call
    var APICall = null;
    if (this.props.actionType == "ADD") { //ADD LOG API CALL
      APICall = this.props.app.sharedAPIWrapper().addLogEntry.bind(this.props.app.sharedAPIWrapper());
    } else if (this.props.actionType == "EDIT") { //EDIT LOG API CALL
      APICall = this.props.app.sharedAPIWrapper().editLogEntry.bind(this.props.app.sharedAPIWrapper());
    } else console.log("unknown submit action on log modal!");
    //Make the actual call
    this.startLoading();
    APICall(sendData, this.state.unsavedReceipts, this.state.unsavedDocuments, response => {
      if (!this._isMounted) return;
      if (!response["error"]) {
        this.props.alertController.showSuccessAlert("Success","Log entry " + (this.isEditMode ? "edited" : "added") + " with success!");
        this.props.navigationController.popView();
        //Invalidate IE caching, just in case of new addition
        this.props.app.sharedCache().invalidadeIECacheForTheYear(this.getCurrentActivityYear());
      } else {
        this.props.alertController.showErrorAlert("Error","Error while " + (this.isEditMode ? "editting" : "adding") + " log entry.",response);
      }
      this._removeFlaggedImages(); //keep with removal of flagged images from S3
      this.stopLoading();
    });
  }
  //Images
  _uploadImage(file, mode) {
    //check for file max size
    if (file.size > this.props.app.sharedCache().getMaxUploadFileSize()) {
      this.props.alertController.showErrorAlert("Error","File is too big! Select another one.");
      return;
    }
    //
    this.startLoading();
    this.props.app.sharedAPIWrapper().uploadImage(file, resp => {
      if (!this._isMounted) return;
      if (!resp["error"]) {
        this.props.alertController.showSuccessAlert("Success",`${mode === 'receipts' ? 'Receipt' : 'Document'} uploaded with success!`);
        if (mode === 'receipts') { this.state.unsavedReceipts.push({ path: resp.hashedFile, filename: file.name }) } else {this.state.unsavedDocuments.push({ path: resp.hashedFile, filename: file.name })};
        this.setState(this.state);
      } else {
        this.props.alertController.showErrorAlert("Error","Error while uploading receipt.",resp);
      } this.stopLoading();
    });
  }
  //Image control
  _discardUnsavedImages() {
    this.state.unsavedReceipts.forEach((imageName, idx, array) => {
      this.props.app.sharedAPIWrapper().deleteImage(imageName, function(){
        //if (idx === array.length - 1) this.stopLoading(); -- DO NOT STATUS UPDATE SINCE ITS EXECUTED WHEN PAGE IS GOING OUT
      });
    });
    this.state.unsavedDocuments.forEach((imageName, idx, array) => {
      this.props.app.sharedAPIWrapper().deleteImage(imageName, function(){
        //if (idx === array.length - 1) this.stopLoading(); -- DO NOT STATUS UPDATE SINCE ITS EXECUTED WHEN PAGE IS GOING OUT
      });
    });
  }
  _removeFlaggedImages() {
    this.state.pendingDeleteImages.forEach((imageName, idx, array) => {
      this.props.app.sharedAPIWrapper().deleteImage(imageName, function(){
        //if (idx === array.length - 1) this.stopLoading(); -- DO NOT STATUS UPDATE SINCE ITS EXECUTED WHEN PAGE IS GOING OUT
      });
    });
  }
  //IE
  _checkForCachedIEForEvent(event) {
    //Check for IE loading
    if (event.target.id == "activitydate") {
      let year = new Date(event.target.value).getFullYear();
      this._checkForCachedIE(year);
    }
  }
  _checkForCachedIE(year) {
    let cachedIEs = this.props.app.sharedCache().getIEsForYear(year);
    if (cachedIEs == undefined || cachedIEs == null) {
      this.startLoading();
      this.props.app.sharedCache().loadAndCacheIEsForTheYear(year, this.props.app, ()=>{
        this.stopLoading();
      });
    }
  }
  //Form
  _validateForm(event) {
    const formLength = this.formEl.length;
    for (let i = 0; i < formLength; i++) {
      const elem = this.formEl[i];
      if ((elem.type == "checkbox" && elem.required && elem.checked == undefined) ||
          (elem.type != "checkbox" && elem.required && !elem.value) ||
          !elem.checkValidity()) {
        //
        var tabClasses = elem.closest(".childBox").className;
        var tabNumber = tabClasses.match("child[1-9]")[0].replace("child","");
        //change tab and end method..
        this.handleTabChange(tabNumber);
        return false;
      }
    } return true;
  }
}
