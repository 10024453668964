import React from "react";
import ReactTable from "react-table";
import { Row, Col, Button, Container, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import autoBind from 'react-autobind';
import XLSX from 'xlsx';


//
import Utils from "./../../components/Utils";
import UtilsReport from "./../../components/UtilsReport";
import CustomComponent from "./../../components/CustomComponent";
import CommonLogView from "./CommonLogView";
import CommonLoadingView from "./CommonLoadingView";
import CommonMonthlySubmissionPDF from "./../pdfs/CommonMonthlySubmissionPDF";
//
import "./../../stylesheets/CommonLogTable.css";
//
export default class CommonLogTable extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { data: [], rowsData: [], firstLoad: true };
  }
  loadResponse(response) {
    //valid data state
    if (response['data'] != null) {
      this.state.data = response['data'];
      this.state.rowsData = UtilsReport.generateMonthlyReportRows(response['data']);
      //Render PDF
      Utils.renderPDF(CommonMonthlySubmissionPDF, this.props.app, this, () => {
        this.pdfRef.loadData(this.state.rowsData, this.state.data);
      });
    } else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
      this.state.rowsData = [];
    }
    this.state.firstLoad =  false;
    this.stopLoading();
    this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //Actions
  handleBackButton() { this.props.navigationController.popView(); }
  handleRowClick(event, row) {
    if (row.original.isNotData) return;
    this.props.navigationController.pushView(
      <CommonLogView reportLGID={row.original} app={this.props.app} editable={this.props.editable}
                     alertController={this.props.alertController}/>
    );
  }
  handlePreviewReceipts(obj, event) {
    event.stopPropagation();
    this.props.navigationController.pushView(
      <CommonLogView reportLGID={obj} app={this.props.app} alertController={this.props.alertController} forceTabIndex={1}/>
    );
  }
  handleEditLog(obj, event) {
    event.stopPropagation();
    this.props.navigationController.pushView(
      <CommonLogView reportLGID={obj} app={this.props.app} editable={this.props.editable} alertController={this.props.alertController} />
    );
  }
  handleGenerateExcel() {
    function _getActivityDate(d) {
      const { activitydate } = d;

      if (activitydate == 'ZZ') return '';
      if (activitydate == 'ZZZ') return 'Sub-total';
      if (activitydate == 'ZZZZ') return 'GST';
      if (activitydate == 'ZZZZZ') return 'Total';

      return d.isSpace || d.isNotData ? activitydate : Utils.toDateFormat(activitydate);
    }

    const treatedData = this.state.rowsData.map((d) => ({
      'Activity Date': _getActivityDate(d),
      'Activity Description': d.description,
      'Company Name': d.companyname,
      'Cons.': Utils.getSafeLGsRow(d,"consulting.consultinghours"),
      'Cons.($)': Utils.getSafeLGsRow(d,"consulting.consultingpayment", true),
      'Travel': Utils.getSafeLGsRow(d,"travel.traveltime"), 
      'Travel($)': Utils.getSafeLGsRow(d, "travel.travelreimbursement", true),
      'KM': Utils.getSafeLGsRow(d,"travel.km"),
      'KM($)': Utils.getSafeLGsRow(d,"travel.kmreimbursement", true),
      'Meal($)': Utils.getSafeLGsRow(d,"meal.mealamount", true),
      'Other($)': Utils.getSafeLGsRow(d,"othercosts", true),
      'Total($)': Utils.getSafeLGsRow(d,"totalclaim", true),
      'WorksafeBC Account #': d.wcdid ? Utils.getSafeLGsRow(d,"wcdid") : '',
    }));

    const spreadsheet = XLSX.utils.json_to_sheet(treatedData);
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, spreadsheet, "Sheet1");

    const { reportMR: { gsi1sk } } = this.props;
    const filename = `history${gsi1sk ? `-${gsi1sk}` : ''}.xlsx`;
    XLSX.writeFile(workbook, filename);
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().fetchLogEntriesAttachedToMR(this.props.reportMR,response => {
      if (!this._isMounted) return;
      this.loadResponse(response);
    });
  }
  //UI
  render() {
    const props = {
      showPagination: false, noDataText: "No data!",
      loadingText: "Loading...", minRows: 6,
      sortable: false, defaultPageSize: 1000,
      keyField :'sk', loading:this.state.isLoading,
      defaultSorted: [{ id: "activitydate", desc: false}],
      getTrProps: (s, r) => {
        return { onClick: (e) => { this.handleRowClick(e, r) } };
      }
    };
    const columns = [
      {
        Header: 'Activity Date',
        id: "activitydate",
        width: 130,
        accessor: d => {
          if (d.isSpace || d.isNotData) return d.activitydate;
          return Utils.toDateFormat(d.activitydate);
        },
        Cell: props => {
          if (props.original.isSpace) return " ";
          else if (props.original.isNotData) return (<div className='text-left detailedLogReportTotal'><b>{props.original.title}</b></div>);
          return props.value;
        }
      },
      {
        Header: 'Activity Description',
        accessor: 'description',
        width: 180
      },
      {
        Header: 'Company Name',
        accessor: 'companyname',
        width: 150
      },
      {
        Header: 'Cons.',
        width: 60,
        accessor: d => Utils.getSafeLGsRow(d,"consulting.consultinghours"),
        id:"consulting.consultinghours"
      },
      {
        Header: 'Cons.($)',
        width: 80,
        accessor: d => Utils.getSafeLGsRow(d,"consulting.consultingpayment", true),
        id:"consulting.consultingpayment"
      },
      {
        Header: 'Travel',
        width: 60,
        accessor: d => Utils.getSafeLGsRow(d,"travel.traveltime"),
        id:"travel.traveltime"
      },
      {
        Header: 'Travel($)',
        width: 80,
        accessor: d => Utils.getSafeLGsRow(d, "travel.travelreimbursement", true),
        id:"travel.travelreimbursement"
      },
      {
        Header: 'KM',
        width: 60,
        accessor: d => Utils.getSafeLGsRow(d,"travel.km"),
        id:"travel.km"
      },
      {
        Header: 'KM($)',
        width: 80,
        accessor: d => Utils.getSafeLGsRow(d,"travel.kmreimbursement", true),
        id:"travel.kmreimbursement"
      },
      {
        Header: 'Meal($)',
        width: 60,
        accessor: d => Utils.getSafeLGsRow(d,"meal.mealamount", true),
        id:"meal.mealamount"
      },
      {
        Header: 'Other($)',
        width: 80,
        accessor: d => Utils.getSafeLGsRow(d,"othercosts", true),
        id:"othercosts"
      },
      {
        Header: 'Total($)',
        width: 80,
        accessor: d => Utils.getSafeLGsRow(d,"totalclaim", true),
        id:"totalclaim"
      },
      {
        Header: '',
        accessor: 'sk',
        sortable: false,
        width: (this.props.editable ? 160 : 80),
        Cell: props => {
          if (props.original.isNotData) return (<div><br></br></div>);
          return (<span className='tableButtonContainer'>
                    <OverlayTrigger key="edit" placement="left" overlay={ <Tooltip id="tooltip-edit"> Preview receipts </Tooltip> } container={this}>
                      <Button className="tableEditButton" variant="none" onClick={this.handlePreviewReceipts.bind(this,props.original)}> <FontAwesomeIcon icon={faEye} size="1x"/> </Button>
                    </OverlayTrigger>
                    {this.props.editable &&
                      <OverlayTrigger key="edit" placement="left" overlay={ <Tooltip id="tooltip-edit"> Edit entry </Tooltip> } container={this}>
                        <Button className="tableEditButton" variant="light" onClick={this.handleEditLog.bind(this,props.original)}><FontAwesomeIcon icon={faPencilAlt} size="1x"/></Button>
                      </OverlayTrigger>}
                  </span>);
      }
    }];
    //render
    return (<div className="AppPageContainer">
              <CommonLoadingView isLoading={this.state.isLoading}/>
              <Container className="filterContainer">
                <Row className="overlayLabelContainer justify-content-between">
                  <Col className="col-auto"> <Button variant="none" className="appBackBtn" onClick={this.handleBackButton}> Back </Button> </Col>
                  <Badge variant="secondary" className="col-auto">
                    <Col className=" overlayTotalLabel">Grand Total ($)</Col>
                    <Col className=" overlayTotalValue">{Utils.toCurrencyFormat(this.state.data.totalclaimaftertax)}</Col>
                  </Badge>
                  <Col className="col-auto">
                    {this.state.rowsData.length > 0 && (
                      <Button variant="none" className="logBoxPrintButton" onClick={this.handleGenerateExcel}><FontAwesomeIcon icon={faFileExcel} size="1x"/></Button>
                    )}
                    { this.state.rowsData.length > 0 && Utils.renderPrintPDFButton(this) }
                  </Col>
                </Row>
               </Container>
              <ReactTable data={this.state.rowsData} className="v-100 -highlight" columns={columns} {...props}/>
           </div>);
  }
}
