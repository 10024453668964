import React, { Component } from 'react';
import { Row, Col, Container, Badge } from "react-bootstrap";
import ReactTable from "react-table";
//
import APIUtils from  "./../../components/APIUtils";
import Utils from "./../../components/Utils";
import CommonReportUI from "./CommonReportUI";

// Create Document Component
export default class CommonMonthlySubmissionPDF extends Component {
  constructor(props) {
    super(props);
    this.loadedData = [];
    this.msObj = {};
  }
  loadData(rowData, msObj) {
    if (!rowData) return;
    this.loadedData = Object.assign([],rowData);
    this.msObj = msObj;
    this.forceUpdate(); //request redraw
  }
  render() {
    if (this.loadedData.length == 0) return (<div></div>); //optmize
    const props = {
      showPagination: false, noDataText: "No data!",
      minRows: 6, defaultPageSize: 1000, keyField :'sk',
      loading: false, sortable: false, defaultSorted: [{ id: "activitydate", desc: false}],
    };
    const columns = [
    {
        Header: 'Activity Date',
        id: "activitydate",
        width: 130,
        accessor: d => {
          if (d.isSpace || d.isNotData) return d.activitydate;
          return Utils.toDateFormat(d.activitydate);
        },
        Cell: props => {
          if (props.original.isSpace) return " ";
          else if (props.original.isNotData) return (<div className='text-left detailedLogReportTotal'><b>{props.original.title}</b></div>);
          return props.value;
        }
      }, {
      Header: 'Activity Description',
      accessor: 'description',
      width: 170
    }, {
      Header: 'Company Name',
      accessor: 'companyname',
      width: 170,
      Cell: props => {
        if (props.original.isNotData || props.value === undefined) return (<div><br></br></div>);
        return props.value;
      }
    },
    {
      Header: 'Cons.',
      width: 60,
      accessor: d => Utils.getSafeLGsRow(d,"consulting.consultinghours"),
      id:"consulting.consultinghours"
    },
    {
      Header: 'Cons.($)',
      width: 80,
      accessor: d => Utils.getSafeLGsRow(d,"consulting.consultingpayment", true),
      id:"consulting.consultingpayment"
    },
    {
      Header: 'Travel',
      width: 60,
      accessor: d => Utils.getSafeLGsRow(d,"travel.traveltime"),
      id:"travel.traveltime"
    },
    {
      Header: 'Travel($)',
      width: 80,
      accessor: d => Utils.getSafeLGsRow(d, "travel.travelreimbursement", true),
      id:"travel.travelreimbursement"
    },
    {
      Header: 'KM',
      width: 60,
      accessor: d => Utils.getSafeLGsRow(d,"travel.km"),
      id:"travel.km"
    },
    {
      Header: 'KM($)',
      width: 80,
      accessor: d => Utils.getSafeLGsRow(d,"travel.kmreimbursement", true),
      id:"travel.kmreimbursement"
    },
    {
      Header: 'Meal($)',
      width: 80,
      accessor: d => Utils.getSafeLGsRow(d,"meal.mealamount", true),
      id:"meal.mealamount"
    },
    {
      Header: 'Other($)',
      width: 80,
      accessor: d => Utils.getSafeLGsRow(d,"othercosts", true),
      id:"othercosts"
    },
    {
      Header: 'Total($)',
      width: 100,
      accessor: d => Utils.getSafeLGsRow(d,"totalclaim", true),
      id:"totalclaim"
    }];
    return (
      <div className="PDFPageContainer">
        {CommonReportUI.renderHeader(this.props.app)}
        {CommonReportUI.renderTitleHeader('Monthly Report')}
        <Container className="filterContainer">
          <Row className="overlayLabelContainer justify-content-center">
            <Badge variant="secondary" className="col-auto">
              <Col className=" overlayTotalLabel">Grand Total ($)</Col>
              <Col className=" overlayTotalValue">{Utils.toCurrencyFormat(this.msObj.totalclaimaftertax)}</Col>
            </Badge>
          </Row>
         </Container>
         <Container className="filterContainer">
           <Row className="overlayLabelContainer justify-content-between" style={{paddingTop: 14}}>
             { this.msObj && this.msObj.pk &&
               <Col className="col-auto"><h6><b>User: </b>{this.props.app.sharedCache().findDisplayNameByID(this.msObj.pk.replace("MS::",""))}</h6></Col> }
             { this.msObj && this.msObj.sk && <Col className="col-auto"><h6><b>Submission Period: </b>{APIUtils.formatStringDateToGSI1SK(this.msObj.sk, true)}</h6></Col> }
             { this.msObj && this.msObj.submissiontimestamp && <Col className="col-auto"><h6><b>Submission Date: </b>{APIUtils.getDateOnUIFormat(new Date(this.msObj.submissiontimestamp), false)}</h6></Col> }
             { this.msObj && this.msObj.gsi1pk && <Col className="col-auto"><h6><b>Status: </b>{this.msObj.gsi1pk}</h6></Col> }
           </Row>
          </Container>
         <ReactTable data={this.loadedData} className="v-100 PDFTable -highlight" columns={columns} {...props}/>
        </div>
    );
  }
}
