import React, { Component } from "react";
import './../../stylesheets/CommonLoadingView.css';
//
export default class CommonLoadingView extends Component {
  render() {
    var loading = this.props.isLoading;
    let loadingClass = (loading ? "" : "hide ") + "loading" + (this.props.isFixed != undefined ? " loadingLogin" : "");
    return (
      <div className={loadingClass}>
        <p className="loading-wheel"></p>
      </div>
    );
  }
}
