import React, { Component } from "react";
import NavigationController from 'react-navigation-controller';
//
import "./../stylesheets/CustomNavigationController.css";
//Navigation container implementation
export default class CustomNavigationController extends Component {
  constructor(props, customConfig) {
    super(props);
    this.customConfig = customConfig;
  }
  render() {
    let props = {
      views: [
        <this.containerClass app={this.props.app} alertController={this.props.alertController}/>
      ],
      preserveState: false,
      transitionTension: 0,
      transitionFriction: 6
    };

    if (this.customConfig) {
      props = {
        ...props,
        ...this.customConfig,
      };
    }
    return ( <NavigationController {...props} />);
  }
}
