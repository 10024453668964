import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../../components/CustomComponent";
import CommonLoadingView from "./CommonLoadingView";
import Utils from "./../../components/Utils";
import FormInput from "./../../components/FormInput";
//
export default class AdminUserDetails extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setInitialState();
  }
  setInitialState() {
    if (this.props.userData !== undefined)
      this.state = {data: this.props.userData};
    else
      this.state = {data: {}};
  }
  //Actions
  handleChange(event) { Utils.defaultFormChangeHandler(event, this); }
  handleCancel() { this.props.navigationController.popView(); }
  handleSubmit(e) {
    e.preventDefault(); //prevent form submission
    //confirmation ask
    var alertText = ((this.props.actionType == "ADD") ? "Are you sure you want to add a new user?" : "Are you sure you want to edit user?");
    if (!this.props.alertController.showQuestionAlert("Attention!",alertText)) return;
    //Check if both password matches
    if (this.state.data.passwordConfirmation != this.state.data.password) {
      this.props.alertController.showErrorAlert("Error","Passwords must match!");
      return;
    }
    //action
    this._submitUser();
  }
  //UI
  render() {
    var isAdmin = this.state.data.type;
    var isAdding = (this.props.actionType == "ADD");
    return (
      <div className="AppPageContainer">
        <CommonLoadingView isLoading={this.state.isLoading}/>
        <Form onSubmit={this.handleSubmit}>
          <Container className="buttonUserContainer">
            <Row className="justify-content-start">
              <Col>
                <Button className="appBackBtn" variant="none" onClick={this.handleCancel}>Cancel</Button>
              </Col>
              <Col className="col-md-auto">
                <Button className="appSubmitBtn" variant="none" type="submit">{(isAdding ? "Create" : "Save")}</Button>
              </Col>
            </Row>
            </Container>
            <Container className="userDetailsForm">
            <h3 className="userPageLabel">{(isAdding ? "Create user:" : "Edit user:")}</h3><br></br>
            <div>
              <Row className="justify-content-start align-items-end">
                <Col className="col-2"><h4>
                  <FormInput id="type" labelText="Is admin:" enabled={isAdding} isRequired={false} type={"checkbox"} parent={this} defaultValue={false}/>
                </h4></Col>
                <Col className="col-4 offset-3"><h4>
                  <FormInput id="username" labelText="Username:" enabled={isAdding} isRequired={true} type={"text"} parent={this}/>
                </h4></Col>
              </Row><Row className="justify-content-start align-items-end">
                <Col className="col-4"><h4>
                  <FormInput id="displayname" labelText="Name:" enabled={true} isRequired={true} type={"text"} parent={this}/>
                </h4></Col>
                <Col className="col-4 offset-1"><h4>
                  <FormInput id="email" labelText="Email:" enabled={isAdding} isRequired={true} type={"email"} parent={this}/>
                </h4></Col>
              </Row><Row className="justify-content-start align-items-end">
                <Col className="col-3"><h4>
                  <FormInput id="gstnumber" labelText="GST number:" enabled={!isAdmin} isRequired={false} type={"text"} parent={this}/>
                </h4></Col>
                <Col className="col-3 offset-2"><h4>
                  <FormInput id="timepaidshortdistance" labelText="Short travel pay rate:" enabled={!isAdmin} isRequired={true} type={"number"} parent={this} customProps={{min: 0, step:"any"}}/>
                </h4></Col>
              </Row><Row className="justify-content-start align-items-end">
                <Col className="col-3"><h4>
                  <FormInput id="annualrsamaxexpenses" labelText="Annual budget:" enabled={!isAdmin} isRequired={true} type={"number"} prefixValue={"$"} customProps={{min: 0, step:"any"}} parent={this}/>
                </h4></Col>
              <Col className="col-3 offset-2"><h4>
                  <FormInput id="hourlyrate" labelText="Hourly rate:" enabled={!isAdmin} isRequired={true} type={"number"} parent={this} prefixValue={"$"} customProps={{min: 0, step:"any"}}/>
                </h4></Col>
              </Row><Row className="justify-content-start align-items-end">
                <Col className="col-4"><h4>
                  <FormInput id="businessname" labelText="Bussiness name:" enabled={!isAdmin} isRequired={false} type={"text"} parent={this}/>
                </h4></Col>
              </Row>
              { isAdding ?
                <Row className="justify-content-start align-items-end">
                  <Col className="col-4"><h4>
                    <FormInput id="password" labelText="Password:" enabled={true} isRequired={true} type={"password"} parent={this}/>
                  </h4></Col>
                  <Col className="col-4 offset-1"><h4>
                    <FormInput id="passwordConfirmation" labelText="Confirm password:" enabled={true} isRequired={true} type={"password"} parent={this}/>
                  </h4></Col>
                </Row> : <></>
              }
            </div>
          </Container>
        </Form>
      </div>
    );
  }


  //private
  _submitUser() {
    const sendData = this.state.data;
    this.startLoading();
    ////ADD USER API CALL
    if (this.props.actionType == "ADD") {
      this.props.app.sharedAPIWrapper().addUser(sendData ,response => {
        if (!this._isMounted) return;
        if (!response["error"]) {
          this.props.alertController.showSuccessAlert("Success","User created with success!");
          this.props.navigationController.popView();
        } else {
          this.props.alertController.showErrorAlert("Error","Error while creating user.",response);
        } this.stopLoading();
      });
      //EDIT USER API CALL
    } else if (this.props.actionType == "EDIT") {
      this.props.app.sharedAPIWrapper().editUser(sendData ,response => {
        if (!this._isMounted) return;
        if (!response["error"]) {
          this.props.alertController.showSuccessAlert("Success","User edited with success!");
          this.props.navigationController.popView();
        } else {
          this.props.alertController.showErrorAlert("Error","Error while editing user.",response);
        } this.stopLoading();
      });
    }
  }
}
