import React from "react";
import ReactTable from "react-table";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import autoBind from 'react-autobind';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
//
import APIUtils from  "./../../components/APIUtils";
import Utils from "./../../components/Utils";
import CustomComponent from "./../../components/CustomComponent";
import LogForm from "./AdvisorLogForm";
import QuickLogForm from "./AdvisorQuickLogForm";
import "./../../stylesheets/AdvisorLogTable.css"
//
const CheckboxTable = checkboxHOC(ReactTable);
export default class AdvisorLogTable extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { selection: [], selectAll: false };
  }
  selectAllRows(data) {
    this.handleToogleSelectAll(data);
  }
  getSelectedIDS() { return this.state.selection; }
  //UI
  render() {
    const data = (this.props.data == null ? [] : this.props.data);
    const props = {
      selectAll: this.state.selectAll,
      isSelected: this.handleIsSelected,
      toggleSelection: this.handleSelection,
      toggleAll: this.handleToogleSelectAll,
      selectType: "checkbox",
      minRows: 6,
      defaultPageSize: 1000,
      showPagination: false,
      noDataText: "No data!",
      loadingText: "Loading...",
      keyField :'sk',
      defaultSorted: [{ id: "activitydate", desc: false}],
      loading:this.state.isLoading
    };
    const columns = [
      {
        Header: 'Activity Date',
        accessor: d => Utils.toDateFormat(d['activitydate']),
        id: "activitydate",
        width: 130
      }, {
        Header: 'Activity Description',
        accessor: 'description',
        width: 180
      }, {
        Header: 'Company Name',
        accessor: 'companyname',
        width: 150
      }, {
        Header: 'Cons.',
        width: 60,
        accessor: d => Utils.getNestedObject(d,"consulting.consultinghours"),
        id:"consulting.consultinghours"
      }, {
        Header: 'Cons.($)',
        width: 80,
        accessor: d => Utils.toCurrencyFormat(Utils.getNestedObject(d,"consulting.consultingpayment")),
        id:"consulting.consultingpayment"
      }, {
        Header: 'Travel',
        width: 60,
        accessor: d => Utils.safelyGetNumericNestedObject(d,"travel.traveltime"),
        id:"travel.traveltime"
      }, {
        Header: 'Travel($)',
        width: 80,
        accessor: d => Utils.toCurrencyFormat(Utils.safelyGetNumericNestedObject(d,"travel.travelreimbursement")),
        id:"travel.travelreimbursement"
      }, {
        Header: 'KM',
        width: 60,
        accessor: d => Utils.safelyGetNumericNestedObject(d,"travel.km"),
        id:"travel.km"
      }, {
        Header: 'KM($)',
        width: 80,
        accessor: d => Utils.toCurrencyFormat(Utils.safelyGetNumericNestedObject(d,"travel.kmreimbursement")),
        id:"travel.kmreimbursement"
      }, {
        Header: 'Other($)',
        width: 80,
        accessor: d => Utils.toCurrencyFormat(d['othercosts']),
        id:"othercosts"
      }, {
        Header: 'Total($)',
        width: 80,
        accessor: d => Utils.toCurrencyFormat(d['totalclaim']),
        id:"totalclaim"
      }, {
        Header: '',
        accessor: '',
        sortable: false,
        width: 180,
        Cell: props => {
          return (<span className='tableButtonContainer'>
                    <OverlayTrigger key="edit" placement="left" overlay={ <Tooltip id="tooltip-edit"> Edit entry </Tooltip> } container={this}>
                      <Button className="tableEditButton" variant="light"
                              onClick={this.handleEditLog.bind(this,props.value)}><FontAwesomeIcon icon={faPencilAlt} size="1x"/></Button>
                    </OverlayTrigger>
                    <OverlayTrigger key="dup" placement="left" overlay={ <Tooltip id="tooltip-dup"> Duplicate entry </Tooltip> } container={this}>
                      <Button className="tableEditButton" variant="none"
                              onClick={this.handleDuplicatedEntry.bind(this,props.original)} tooltip="tooltip content here"><FontAwesomeIcon icon={faCopy} size="1x"/></Button>
                    </OverlayTrigger>
                    <OverlayTrigger key="del" placement="left" overlay={ <Tooltip id="tooltip-del"> Delete entry </Tooltip> } container={this}>
                      <Button className="tableDeleteButton" variant="danger"
                              onClick={this.handleDeleteLog.bind(this,props.value)}><FontAwesomeIcon icon={faTrashAlt} size="1x"/></Button>
                    </OverlayTrigger>
                  </span>);
    }
  }];

    //render
    return (<div className="AppPageContainer">
              {/* <div style={{ position: 'absolute', top: -30, left: 15 }}>
                <Button onClick={this.props.onResetSort} style={{ height: '30px', padding: '0 15px' }}>Reset Sort</Button>
              </div> */}
              <CheckboxTable keyField='username' data={data}
                            className="-highlight v-100 advLogTable" columns={columns}
                            onSortedChange={this.props.onSort}
                            sorted={this.props.sorted}
                            {...props}/>
              </div>);
  }
  //Action handler
  handleEditLog(obj) {
    const FormComponent = obj.entrytype == 'quick' ? QuickLogForm : LogForm;

    this.props.navigationController.pushView(
      <FormComponent actionType="EDIT" data={Object.assign({},obj)} app={this.props.app}
               alertController={this.props.alertController}/>
    );
  }
  handleDeleteLog(obj) {
    //confirmation ask
    if (!this.props.alertController.showQuestionAlert("Attention!","Do you really want to delete this entry? ")) return;
    this.props.loaderReference.startLoading();
    //
    this.props.app.sharedAPIWrapper().deleteLogEntry(obj ,response => {
      if (!this._isMounted) return;
      if (!response["error"]) {
        this.props.alertController.showSuccessAlert("Success","Log entry deleted with success!");
        this.props.deletedUpdateHandler();//ask for UI update
      } else {
        this.props.alertController.showErrorAlert("Error","Error while deleting log entry.",response);
        this.props.loaderReference.stopLoading();
      }
    });
  }
  handleDuplicatedEntry(logObj) {
    let dupObject = APIUtils.duplicatedLogEntryObject(logObj);
    this.props.navigationController.pushView(
      <LogForm actionType="ADD" data={Object.assign({},dupObject)} app={this.props.app}
               alertController={this.props.alertController}/>
    );
  }
  //Table datasource
  handleSelection = (key, shift, row) => {
    // start off with the existing state
    var selection = [...this.state.selection];
    var keyIndex = selection.indexOf(row.sk);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else { selection.push(row.sk); }
    // update the state
    this.state.selection = selection;
    this.state.selectAll = (this.state.selection.length == this.props.data.length);
    this.props.uiUpdateHandler();
  };
  handleToogleSelectAll = (optionalData) => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    optionalData = (!Array.isArray(optionalData) ? this.props.data : optionalData);
    //push all
    if (selectAll) {
      optionalData.forEach(item => { selection.push(item['sk']); });
    }
    this.state.selectAll = selectAll;
    this.state.selection = selection;
    this.props.uiUpdateHandler();
  };
  handleIsSelected = key => {
    return this.state.selection.includes(key);
  };
}
