import React, { Component } from 'react';
import { Row, Col, Container } from "react-bootstrap";
import ReactTable from "react-table";
//
import Utils from "./../../components/Utils";
import UtilsReport from "./../../components/UtilsReport";
import CommonReportUI from "./CommonReportUI";

// Create Document Component
export default class RSAYearlyReportPDF extends Component {
  constructor(props) {
    super(props);
    this.loadedData = [];
  }
  loadData(data) {
    if (!data || data.length == 0) return;
    this.loadedData = Object.assign([],data);
    this.loadedData.push(UtilsReport.generateYearlyReportRow(this.loadedData));
    this.loadedData = this.loadedData;
    this.forceUpdate(); //request redraw
  }
  render() {
    if (!this.loadedData || !this.loadedData[0]) return (<div></div>); //optmize
    const props = {
      showPagination: false, noDataText: "No data!",
      minRows: 6, keyField :'sk'
    };
    const columns = [
    {
      width: 100,
      Header: ()=> <div className="alignMidHeader">Q</div>,
      id: 'sk',
      headerStyle: props.headerStyle,
      Cell: props => {
        if (props.original.isTotal) { return (<div className='text-left detailedYearlyReportGrandTotal'><b>Yearly Total</b></div>); }
        else if (props.original.sk) {
          let Q = ("Q" + props.original.sk.slice(5,6));
          if (props.original.newEntry === true) return (<div className='text-left detailedYearlyReportIncompleteQuarter'>{Q}<span className="detailedYearlyReportIncompleteQuarterDetail">**</span></div>);
          else if (props.original.mscount !== 3) return (<div className='text-left detailedYearlyReportIncompleteQuarter'>{Q}<span className="detailedYearlyReportIncompleteQuarterDetail">*</span></div>);
          else return (<div className='text-left'>{Q.value}</div>);
        } else return "";
      }
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of New COR Applicatns<br></br>Contacted</div>,
      accessor: 'cor.numnewcor',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of COR Gap <br></br>Analysis</div>,
      accessor: 'cor.numgapanalysis',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of Large <br></br>Business COR <br></br>Consultations</div>,
      accessor: 'cor',
      Cell: props => {
        if(!props.value) return Utils.safeNumber(props.value);
        return Utils.safeNumber(props.value.totalconsultations) - Utils.safeNumber(props.value.totalsmbconsultations);
      }
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of Small <br></br>Business COR <br></br>Consultations</div>,
      accessor: 'cor.totalsmbconsultations',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 140,
      Header: ()=> <div className="alignMidHeader"># of Scaffolding<br></br>Presentations</div>,
      accessor: 'highrisk.scaffolding',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of Work <br></br>Platforms<br></br>Presentations</div>,
      accessor: 'highrisk.workplatforms',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 120,
      Header: ()=> <div className="alignMidHeader"># of Mobile <br></br>Equipment<br></br>Presentations</div>,
      accessor: 'highrisk.mobileequip',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 150,
      Header: ()=> <div className="alignMidHeader"># of Social Media<br></br>Submissions</div>,
      headerStyle: props.headerStyle,
      accessor: 'otheractivities.socialmedia',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 140,
      Header: ()=> <div className="alignMidHeader"># on Site <br></br> Consultations</div>,
      accessor: 'cor.totalconsultations',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 110,
      Header: ()=> <div className="alignMidHeader"># of Phone<br></br> Calls</div>,
      accessor: 'contacttype.numphone',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 100,
      Header: ()=> <div className="alignMidHeader"># of <br></br> Emails</div>,
      accessor: 'contacttype.numemail',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 100,
      Header: ()=> <div className="alignMidHeader"># of Text<br></br> Messages</div>,
      accessor: 'contacttype.numtext',
      Cell: props => Utils.safeNumber(props.value)
    },{
      width: 155,
      Header: ()=> <div className="alignMidHeader">IM<br></br> Consultations</div>,
      accessor: 'iminquiry',
      Cell: props => Utils.safeNumber(props.value)
    }];
    //
    let reportYear = (this.loadedData && this.loadedData[0] ? this.loadedData[0].sk.slice(0,4) : 0);
    let IEs = UtilsReport.generateIndustryEventsReportFromQRs(this.loadedData);
    return (
      <div className="PDFPageContainer">
         {CommonReportUI.renderHeader(this.props.app)}
         {CommonReportUI.renderTitleHeader('Yearly Report')}
         <Container className="filterContainer">
           <Row className="overlayLabelContainer justify-content-between" style={{paddingTop: 14}}>
             { this.loadedData[0] && this.loadedData[0].pk &&
               <Col className="col-auto"><h6><b>User: </b>{this.props.app.sharedCache().findDisplayNameByID(this.loadedData[0].pk.replace("QR::",""))}</h6></Col> }
             { this.loadedData[0] && this.loadedData[0].sk &&
               <Col className="col-auto"><h6><b>Year: </b>{reportYear}</h6></Col> }
             { this.loadedData[0] && <Col className="col-auto"><h6><b>Completed: </b>{this.loadedData[0].mscount == 3 ? "Yes" : "No"}</h6></Col> }
           </Row>
          </Container>
         <ReactTable data={this.loadedData} className="v-100 PDFQuarterlyTable -highlight" columns={columns} {...props}/>
         {CommonReportUI.renderIncompleteDetails()}
         <Container className="filterContainer">
            <Row className="justify-content-center">
              <h5 className="detailedYearlyReportIETitle">Events attended year: <div className="detailedYearlyReportIETitleYear">{reportYear}</div></h5>
            </Row>
            <Row className="justify-content-between">
              {[1,2,3,4].map((obj, index) => {
                return(<Col key={index + "QR::IE:TITLE"} className="logTableFootTitle text-center"><b>Q{obj}</b></Col>);
              })}
            </Row>
            { (IEs.length > 0 && IEs.map((obj, index) => {
              return(<Row key={index + "QR::IE::GROUP"} className="justify-content-between">
                      {obj.length > 0 && obj.map((subObj, subIndex) => {
                        return(<Col key={subIndex + "QR::IE"} className="detailedYearlyReportIETableValue text-center">{subObj.industryevent}</Col>);
                      })}
                     </Row>)
              }))}
            <br></br>
          </Container>
        </div>
    );
  }
}
