import Utils from "./Utils";
//
var md5 = require('md5');
export default class APIUtils  {}

//App utils
APIUtils.getAPPVersion = function() { return "0.1/beta"; }
APIUtils.encodeUploadFile = function(userID, filename) {
  var timestamp = Date.now();
  return md5(userID + filename + timestamp);
}
APIUtils.isReactNative = function() { return (typeof navigator != 'undefined' && navigator.product == 'ReactNative'); }
//API
  //Currently used to encode Log entries
  //Currently used to encode users
APIUtils.encodeForAPI = function(obj, cache, isLG) {
  obj = Object.assign({},obj);
  for (var key in obj) {
    if (key == "activitydate") {
      var date = new Date(obj[key]);
      var utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
      obj[key] = APIUtils.formatDateToGSI1SK(utc);
    } else if (key == "socialmediasubmission" || key == "safetyclimatetool") {
      obj[key] = (obj[key] == true ? 1 : 0);
    } else if (key == "industryevent") {
      //this prevents FE to send industryevent if checkbox was unselected, after typing
      if (obj["isIE"] == true && obj[key] && obj[key].length > 0) obj[key] = "IE::" + obj[key];
      else obj[key] = '';
    } else if (key == "submissionyearmonth") {
      obj[key] = "" + obj[key].slice(0,4) + obj[key].slice(5,7);
    } else if (key == "type") {
      obj[key] = (obj[key] == true ? "Admin" : "User");
    } else if (key == "ctid") {
      var catVal = "";
      cache.getLGCategories().forEach(cat => {
        if (cat["description"] == obj[key]) { catVal = cat["pk"]; }
      });
      obj[key] = catVal;
    } else if (key == "cu" && !this.isReactNative()) {
      var cuIDs = [];
      for (let cuIdx in obj[key]) cuIDs.push(obj[key][cuIdx].id);
      obj[key] = cuIDs;
    } else if (key == "highrisk" && obj['activitydate']) { //must be an LG
      for (var riskkey in obj[key]) {
        obj[key][riskkey] = (obj[key][riskkey] == true ? 1 : 0);
      }
    } else if (key == "corstatus" && isLG) {
      let val = '';
      if (obj[key] == 'COR Certified') val = 1;
      else if (obj[key] == 'COR Registered') val = 2;
      else if (obj[key] == 'Not Registered') val = 3;
      obj[key] = val;
    } else if (key == "companyvisit" && isLG) {
      let val = 1;
      if (obj[key] == 'Follow up') val = 2;
      obj[key] = val;
    } else if (key == "sms" && isLG) {
      let val = 0;
      if (obj[key] == 'SMS Review') val = 2;
      else if (obj[key] == 'SMS Follow up') val = 2;
      obj[key] = val;
    } else if (key == "program" && isLG) {
      let val = 1;
      if (obj[key] == 'Follow up') val = 2;
      obj[key] = val;
    } else if (key === "subCategories" && isLG) {
      var subCategories = [];
      for (let subIdx in obj[key]) subCategories.push(obj[key][subIdx].id);
      obj[key] = subCategories;
    }
  }

  return obj;
}
APIUtils.encodeQRForAPI = function(obj,period) {
    var retVal = {
    "submissionType": "Q",
    "submissionyearquarter": period.selectedYear + Utils.toDoubleDigit(period.selectedQuarter),
    "cordescription": Utils.getNestedObject(obj, "cordescription"), //obj
    "imrsadescription": Utils.getNestedObject(obj, "imrsadescription"), //obj
    "im": Utils.getNestedObject(obj, "im"), //[]
    "highriskdescription": Utils.getNestedObject(obj, "highrisk.description"),
    "otheractivitiesdescription": Utils.getNestedObject(obj, "otheractivities.description"),
    "eventsattended": Utils.getNestedObject(obj, "eventsattended")
  }; return retVal;
}
APIUtils.decodeAPIData = function(obj,cache) {
  var retValue = [];
  //
  if (obj == undefined || !Array.isArray(obj) || obj.length <= 0) return [];
  //
  obj.forEach(record => {
    for (var key in record) {
      if (key == "activitydate") {
        record[key] = APIUtils.formatStringDateToGSI1SK(record[key]);
      } else if (key == "socialmediasubmission" || key == "safetyclimatetool") {
        record[key] = (record[key] == 1 ? true : false);
      } else if (key == "submissionyearmonth") {
        record[key] = record[key].slice(0,4) + "-" + record[key].slice(4,6);
      } else if (key == "industryevent") {
        record[key] = record[key].replace("IE::","");
        record["isIE"] = true;
      } else if (key == "corstatus" && record['pk'].includes('LG::')) {
        if (record[key] == 2) record[key] = 'COR Registered';
        else if (record[key] == 3) record[key] = 'Not Registered';
        else record[key] = 'COR Certified';
      } else if (key == "companyvisit" && record['pk'].includes('LG::')) {
        if (record[key] == 2) record[key] = 'Follow up';
        else record[key] = 'New company';
      } else if (key == "sms" && record['pk'].includes('LG::')) {
        if (record[key] == 2) record[key] = 'SMS Review';
        else if (record[key] == 3) record[key] = 'SMS Follow up';
        else record[key] = 'No SMS';
      } else if (key == "program" && record['pk'].includes('LG::')) {
        if (record[key] == 2) record[key] = 'Follow up';
        else record[key] = 'Review';
      } else if (key == "ctid") {
        var value = "";
        if (cache.getLGCategories() != undefined && record[key] != undefined && record[key].length > 0) {
          let newValue = record[key].replace("LG::","");
          cache.getLGCategories().forEach(cat => {
            if (cat["pk"] == newValue) { value = cat["description"]; }
          });
          record[key] = value;
        }
      } else if (key == "cu" && !this.isReactNative()) {
        var cuIDs = [];
        if (Array.isArray(record[key])) {
          for (let cuObj of record[key]) { cuIDs.push(cache.findCUObjectByID(cuObj)); }
        } record[key] = cuIDs;
      } else if (key == "highrisk" && record['activitydate']) { //must be an LG
        for (var riskkey in record[key]) {
          record[key][riskkey] = (record[key][riskkey] == 1 ? true : false);
        }
      } else if (key == "subCategories" && !this.isReactNative()) {
        record[key] = record[key].map(cat => ({ id: cat.S || cat, desc: cat.S || cat }));
      }
    }
    retValue.push(record);
  });
  return retValue;
}
APIUtils.decodeAPIUserData = function(obj) {
  var cleanUserList = [];
  //
  if (obj == undefined || !Array.isArray(obj) || obj.length <= 0) return [];
  //For all userSettings
  obj.forEach( userObj => {
    var newUser = {};
    //for each key in user object
    newUser["username"] = userObj["Username"];
    newUser["type"] = (userObj["GroupName"] == "Admin" ? true : false);
    //parse other values in Attributes!
    for (var attrIdx in userObj["Attributes"]) {
      var attr = userObj["Attributes"][attrIdx];
      if (attr["Name"] == "email") { newUser["email"] = attr["Value"]; }
      else if (attr["Name"] == "sub") { newUser["rsaid"] = attr["Value"]; }
      else if (attr["Name"] == "name") { newUser["displayname"] = attr["Value"]; }
    }
    //parse other values in extraData!
    var extraData = userObj["extraData"];
    if (extraData !== undefined && extraData.length > 0) extraData = extraData[0];

    for (var extraKey in extraData) {
      if (extraKey == "hourlyrate") { newUser["hourlyrate"] = extraData[extraKey]; }
      else if (extraKey == "gstnumber") { newUser["gstnumber"] = extraData[extraKey]; }
      else if (extraKey == "timepaidshortdistance") { newUser["timepaidshortdistance"] = extraData[extraKey]; }
      else if (extraKey == "businessname") { newUser["businessname"] = extraData[extraKey]; }
      else if (extraKey == "annualrsamaxexpenses") { newUser["annualrsamaxexpenses"] = extraData[extraKey]; }
    }
    //
    cleanUserList.push(newUser);
  });
  return cleanUserList;
}


//API Data formatting
APIUtils.formatDateToGSI1SK = function(date, excludeDay) { //
  //optional day field
  if (excludeDay === undefined) excludeDay = false;
  var day = (excludeDay ? "" : Utils.toDoubleDigit(date.getDate()));
  //month is index from 0 to 11
  var month = Utils.toDoubleDigit(date.getMonth() + 1);
  return ("" + date.getFullYear() + month + day);
}
APIUtils.formatStringToGSI1SK = function(yearStr, monthStr) {
  var month = ("0" + monthStr).slice(-2);
  return ("" + yearStr + month);
}
APIUtils.formatStringDateToGSI1SK = function(dateString, excludeDay) {
  if (dateString === undefined) return "Invalid Date";
  //optional day field
  if (excludeDay === undefined) excludeDay = false;
  var day = (excludeDay ? "" : ("-" + dateString.slice(6,8)));
  //month is index from 0 to 11
  var month = dateString.slice(4,6);
  //
  return (dateString.slice(0,4) + "-" + month + day);
}
APIUtils.getCurrentDateOnUIFormat = function(excludeDay) {
  return this.getDateOnUIFormat(new Date(), excludeDay);
}
APIUtils.getDateOnUIFormat = function(date, excludeDay) {
  //optional day field
  if (excludeDay === undefined) excludeDay = false;
  var day = (excludeDay ? "" : ("-" + Utils.toDoubleDigit(date.getDate())));
  //month is index from 0 to 11
  var month = Utils.toDoubleDigit(date.getMonth() + 1);
  return ("" + date.getFullYear() + "-" + month + day);
}
APIUtils.getCurrentDateAndTimeOnUIFormat = function() {
  let d = new Date();
  return [Utils.toDoubleDigit(d.getMonth()+1), Utils.toDoubleDigit(d.getDate()), d.getFullYear()].join('/') +' ' +
              [Utils.toDoubleDigit(d.getHours()), Utils.toDoubleDigit(d.getMinutes()), Utils.toDoubleDigit(d.getSeconds())].join(':');
}
APIUtils.getPreviousMonthOnUIFormat = function() {
  let date = new Date();
  //month is index from 0 to 11 - leave as index so we get previous month
  var month = Utils.toDoubleDigit(date.getMonth());
  return ("" + date.getFullYear() + "-" + month);
}
APIUtils.filterLGEntriesBySelectedIDs= function(selectedIDs, LGs) {
  let retValue = [];
  for (let LG of LGs) {
    if (selectedIDs.indexOf(LG.sk) > -1) retValue.push(LG);
  } return retValue;
}


//API Data manipulation
APIUtils.getAdvisorExpensesPercentageFromLGs = function(data, cache) {
  var maxAnnualExpense = cache.getLoggedAdvisorAnnualMaxExpense();
  if (!maxAnnualExpense || !data || data.length === 0) return 0;
  //Get all expense of entries
  var totalExpenses = 0;
  for (var i = 0; i < data.length; i++) {
    if (data[i]["totalclaim"] != undefined)
      totalExpenses += parseFloat(data[i]["totalclaim"]);
  } return (totalExpenses / (maxAnnualExpense/12.0)) * 100.0;
}


//MR stuff
APIUtils.calculateMRInfo = function(cache, entries) {
  function safeObject(_obj) { return(_obj !== undefined ? parseFloat(_obj) : 0); }
  function safeIntObject(_obj) { return(_obj !== undefined ? parseInt(_obj) : 0); }
  //user for GST calc
  let settings = cache.getSettings();
  let GST = parseFloat(settings["gstrate"]);
  let userSub = (entries[0] != undefined ? entries[0].pk.replace("LG::","") : "");
  let user = cache.isLoggedUserAdmin() ? cache.findUserByID(userSub) : cache.getLoggedUser();
  let hourlyRate = parseFloat(user.hourlyrate);
  //
  let obj = {};
  obj["travel"] = {}; obj["consulting"] = {}; obj["contacttype"] = {}; obj["meal"] = {};
  //for each LG
  for (let i = 0; i < entries.length; i++) {
    var entry = entries[i];
    //other
    obj.othercosts = Utils.safelyFixCurrency(Utils.safelyGetNumericNestedObject(entry,"othercosts") + safeObject(obj.othercosts));
    //Round Travel and consulting
    obj.travel.km = Utils.safelyFixCurrency(Utils.safelyGetNumericNestedObject(entry,"travel.km") + safeObject(obj.travel.km));
    obj.travel.traveltime = Utils.safelyFixCurrency(Utils.safelyGetNumericNestedObject(entry,"travel.traveltime") + safeObject(obj.travel.traveltime));
    obj.consulting.consultinghours = Utils.safelyFixCurrency(Utils.safelyGetNumericNestedObject(entry, "consulting.consultinghours") + safeObject(obj.consulting.consultinghours));
    //Meals
    obj.meal.mealamount = Utils.safelyFixCurrency(Utils.safelyGetNumericNestedObject(entry,"meal.mealamount") + safeObject(obj.meal.mealamount));
    obj.meal.mealgst = Utils.safelyFixCurrency(Utils.safelyGetNumericNestedObject(entry,"meal.mealgst") + safeObject(obj.meal.mealgst));
    obj.meal.mealtotal = Utils.safelyFixCurrency(Utils.safelyGetNumericNestedObject(entry, "meal.mealtotal") + safeObject(obj.meal.mealtotal));
    //Contacts
    obj.contacttype.numinperson = (Utils.safelyGetNumericNestedObject(entry,"contacttype.numinperson") + safeIntObject(obj.contacttype.numinperson));
    obj.contacttype.numemail = (Utils.safelyGetNumericNestedObject(entry,"contacttype.numemail") + safeIntObject(obj.contacttype.numemail));
    obj.contacttype.numphone = (Utils.safelyGetNumericNestedObject(entry, "contacttype.numphone") + safeIntObject(obj.contacttype.numphone));
    obj.contacttype.numtext = (Utils.safelyGetNumericNestedObject(entry, "contacttype.numtext") + safeIntObject(obj.contacttype.numtext));
    obj.contacttype.numonline = (Utils.safelyGetNumericNestedObject(entry, "contacttype.numonline") + safeIntObject(obj.contacttype.numonline));
    //
    if (entry.invoicenumber !== undefined && entry.invoicenumber.length > 0) { obj.invoicenumber = entry.invoicenumber; }
    //check if last LG, calculate GSTs and after tax values - round numbers
    if (i == (entries.length - 1)) {
      //Consulting
      obj.consulting.consultingpayment = Utils.safelyFixCurrency(parseFloat(obj.consulting.consultinghours * hourlyRate), 2);
      obj.consulting.consultinggst = Utils.safelyFixCurrency((obj.consulting.consultingpayment * GST), 2);
      obj.consulting.consultingaftertax = Utils.safelyFixCurrency(safeObject(obj.consulting.consultingpayment) + safeObject(obj.consulting.consultinggst), 2);
      //t.distance
      obj.travel.kmreimbursement = Utils.safelyFixCurrency(parseFloat(obj.travel.km * parseFloat(settings.valuekmdriven)), 2)
      obj.travel.kmgst = Utils.safelyFixCurrency((obj.travel.kmreimbursement * GST), 2);
      obj.travel.kmtotal = Utils.safelyFixCurrency(safeObject(obj.travel.kmreimbursement) + safeObject(obj.travel.kmgst), 2);
      console.log('Calculating kmreimbursement',obj.travel.km, obj.travel.kmreimbursement, obj.travel.kmgst, obj.travel.kmtotal);
      //t.time
      let traveltime = parseFloat(obj.travel.traveltime);
      if (parseFloat(obj.travel.km) >= parseFloat(settings.longtravelkm)) {
        traveltime *= settings.timepaidlongdistance * hourlyRate;
      } else {
        let shortDistance = parseFloat(user.timepaidshortdistance != undefined && user.timepaidshortdistance > 0 ? user.timepaidshortdistance : settings.timepaidshortdistance);
        traveltime *= shortDistance * hourlyRate;
      }
      obj.travel.travelreimbursement = Utils.safelyFixCurrency(parseFloat(traveltime), 2);
      obj.travel.traveltimegst = Utils.safelyFixCurrency((obj.travel.travelreimbursement * GST), 2);
      obj.travel.traveltimetotal = Utils.safelyFixCurrency(safeObject(obj.travel.travelreimbursement) + safeObject(obj.travel.traveltimegst), 2);
      //close totals
      obj.totalclaim = Utils.safelyFixCurrency(parseFloat(obj.othercosts) + parseFloat(obj.travel.kmreimbursement) + parseFloat(obj.travel.travelreimbursement) + parseFloat(obj.consulting.consultingpayment) + parseFloat(obj.meal.mealamount), 2);
      obj.totalclaimgst = Utils.safelyFixCurrency(parseFloat(obj.travel.kmgst) + parseFloat(obj.travel.traveltimegst) + parseFloat(obj.consulting.consultinggst) + parseFloat(obj.meal.mealgst), 2);
      obj.totalclaimaftertax = Utils.safelyFixCurrency(parseFloat(obj.totalclaim) + parseFloat(obj.totalclaimgst), 2);
    }
  } return obj;
}


//LG stuff
APIUtils.calculateQuickLogEntryValues = function(cache,obj) {
  const timeInNumberFormat = Number(obj.time);
  if (timeInNumberFormat < 1) obj.time = 1;
  if (timeInNumberFormat > 59) obj.time = 59;
}
APIUtils.calculateLogEntryValues = function(cache,obj,targetID) {
  let settings = cache.getSettings();
  let userID = ((obj && obj.pk) ? obj.pk.replace("LG::","") : "");
  let user = cache.isLoggedUserAdmin() ? cache.findUserByID(userID) : cache.getLoggedUser();
  if (!user) return;
  let hourlyRate = parseFloat(user["hourlyrate"]);
  let GST = parseFloat(settings["gstrate"]);
  //Safe check for objects
  if (obj["travel"] === undefined) obj["travel"] = {};
  if (obj["consulting"] === undefined) obj["consulting"] = {};
  if (obj["meal"] === undefined) obj["meal"] = {};

  //->> travel time
  let traveltime = parseFloat(obj.travel.traveltime);
  if (parseFloat(obj.travel.km) >= parseFloat(settings.longtravelkm)) {
    traveltime *= settings.timepaidlongdistance * hourlyRate;
  } else {
    let shortDistance = parseFloat(user.timepaidshortdistance != undefined && user.timepaidshortdistance > 0 ? user.timepaidshortdistance : settings.timepaidshortdistance);
    traveltime *= shortDistance * hourlyRate;
  }
  obj.travel.travelreimbursement = Utils.safelyFixCurrency(parseFloat(traveltime), 2);
  obj.travel.traveltimegst = Utils.safelyFixCurrency(parseFloat(obj.travel.travelreimbursement) * GST, 2);
  obj.travel.traveltimetotal = Utils.safelyFixCurrency(parseFloat(obj.travel.traveltimegst) + parseFloat(obj.travel.travelreimbursement), 2);

  //->> travel km
  obj.travel.kmreimbursement = Utils.safelyFixCurrency(parseFloat(obj.travel.km * parseFloat(settings.valuekmdriven)), 2);
  obj.travel.kmgst = Utils.safelyFixCurrency(parseFloat(obj.travel.kmreimbursement) * GST, 2);
  obj.travel.kmtotal = Utils.safelyFixCurrency(parseFloat(obj.travel.kmgst) + parseFloat(obj.travel.kmreimbursement), 2);

  //->> Calculation
  obj.consulting.consultingpayment = Utils.safelyFixCurrency(parseFloat(obj.consulting.consultinghours * hourlyRate), 2);
  obj.consulting.consultinggst = Utils.safelyFixCurrency(parseFloat(obj.consulting.consultingpayment) * GST, 2);
  obj.consulting.consultingaftertax = Utils.safelyFixCurrency(parseFloat(obj.consulting.consultingpayment) + parseFloat(obj.consulting.consultinggst), 2);

  //->> Meals
  obj.meal.mealamount = Utils.safelyFixCurrency(obj.meal.mealamount, 2);
  if (targetID != 'meal.mealgst' && obj.meal.autocalculated !== false) {
    obj.meal.autocalculated = true;
    obj.meal.mealgst = Utils.safelyFixCurrency(parseFloat(obj.meal.mealamount) * GST, 2);
  } else if (targetID == 'meal.mealgst') {
    obj.meal.autocalculated = false;
    obj.meal.mealgst = Utils.safelyFixCurrency(parseFloat(obj.meal.mealgst), 2);
  } else {
    obj.meal.mealgst = Utils.safelyFixCurrency(parseFloat(obj.meal.mealgst), 2);
  }
  
  const mealGST = parseFloat(obj.meal.mealgst);
  const mealTotal = Utils.safelyFixCurrency(mealGST + parseFloat(obj.meal.mealamount), 2);
  obj.meal.mealtotal = Utils.safelyFixCurrency(mealTotal, 2);

  //->> Total claim
  obj.totalclaim = Utils.safelyFixCurrency(parseFloat(obj.othercosts || 0) + parseFloat(obj.travel.kmreimbursement || 0) + parseFloat(obj.travel.travelreimbursement || 0) + parseFloat(obj.consulting.consultingpayment) + parseFloat(obj.meal.mealamount || 0), 2);
  obj.totalclaimgst = Utils.safelyFixCurrency(parseFloat(obj.travel.kmgst || 0) + parseFloat(obj.travel.traveltimegst || 0) + parseFloat(obj.consulting.consultinggst || 0) + mealGST, 2);
  obj.totalclaimaftertax = Utils.safelyFixCurrency(parseFloat(obj.totalclaim) + parseFloat(obj.totalclaimgst), 2);
}
APIUtils.checkAndSetDataIfIsRsaAdmin = function(obj, targetID) {
  if (obj.rsaadmin && targetID == 'rsaadmin') {
    if (!obj.contacttype) obj.contacttype = {};
    if (!obj.highrisk) obj.highrisk = {};

    // General tab
    obj.wcdid = '';
    obj.ctid = 'No category';
    obj.companyname = 'BCCSA';
    obj.newtocor = false;
    obj.companysize = 'Small';
    obj.corstatus = 'Not Registered';
    obj.repname = '';
    obj.cu = [];
    obj.repemail = '';
    obj.isIE = false;
    obj.repphone = '';
    obj.industryevent = '';
    obj.companyvisit = 'Follow up';
    obj.sms = 'No SMS';
    obj.program = 'Follow up';
    obj.description = 'Administrative activities';
    obj.contacttype.numinperson = 0;
    obj.contacttype.numemail = 0;
    obj.contacttype.numphone = 0;
    obj.contacttype.numtext = 0;
    obj.contacttype.numonline = 0;

    // Activities tab
    obj.numattendees = 0;
    obj.gapanalysis = false;
    obj.gapanalysisCOR = false;
    obj.gapanalysisCOR = false;
    obj.gapanalysisCORFollowup = false;
    obj.iminquiry = false;
    obj.socialmediasubmission = false;
    obj.safetyclimatetool = false;
    obj.wiva = false;
    obj.overdosePresentation = false;
    obj.highrisk.generalpresentations = false;
    obj.highrisk.falls = false;
    obj.highrisk.scaffolding = false;
    obj.highrisk.workplatforms = false;
    obj.highrisk.mobileequip = false;
  }
}
APIUtils.duplicatedLogEntryObject = function(logObj) {
  return {
    description: logObj.description, companyname: logObj.companyname,
    companysize: logObj.companysize, repname: logObj.repname, repemail: logObj.repemail,
    repphone: logObj.repphone, wcdid: logObj.wcdid, corcertified: logObj.corcertified,
    cu: logObj.cu, receipts: [], documents: [], corstatus: logObj.corstatus,
    category: logObj.category, subCategories: logObj.subCategories,
    numattendees: logObj.numattendees, isPresentation: logObj.isPresentation,
  }
}



//QR Stuff
APIUtils.newQRObject = function(year) {
  return {
    newEntry: true,
    sk: year,
    iminquiry: 0,
    contacttype: { numinperson: 0, numemail: 0, numphone: 0, numtext: 0, numonline: 0 },
    im: [],
    industryevents: [],
    qrs:[],
    mscount: 0,
    highrisk: { generalpresentations: 0, falls: 0, scaffolding: 0, workplatforms: 0, mobileequip: 0 },
    //New values
    cor: { numnewcor: 0, numgapanalysis: 0, totalconsultations: 0, totalsmbconsultations: 0,
           numgapanalysisCOR: 0, numgapanalysisCORFollowup: 0, numcorcertifiedsmall: 0,
           numcorregisteredsmall: 0, numcorunregisteredsmall: 0, numcorcertifiedlarge: 0,
           numcorregisteredlarge: 0, numcorunregisteredlarge: 0
    },
    otheractivities: { socialmedia: 0, safetyclimatetool: 0, overdosePresentation: 0, wiva: 0 },
    companyvisit: {
      new: 0, followup: 0
    },
    sms: {
      review: 0, followup: 0
    },
    program: {
      review: 0, followup: 0,
    },
  };
}
APIUtils.sumQRs = function(report, advQuarterObj) {
  if (!advQuarterObj) return;
  console.log(JSON.stringify(advQuarterObj))
  //Sanitize values
  report = { ...APIUtils.newQRObject(), ...report };
  advQuarterObj = { ...APIUtils.newQRObject(), ...advQuarterObj };
  //
  report.newEntry = false;
  report.pk = advQuarterObj.pk;
  report.sk = advQuarterObj.sk;
  report.mscount = Utils.safeNumber(report.mscount) + Utils.safeNumber(report.mscount);
  //
  if (report.qrs == undefined) report.qrs = [];
  if (!report.consulting) report.consulting = {};
  if (!advQuarterObj.consulting) advQuarterObj.consulting = {};
  report.qrs.push(advQuarterObj);
  //im
  report.iminquiry = Utils.safeNumber(report.iminquiry) + Utils.safeNumber(advQuarterObj.iminquiry);
  //highrisk
  report.highrisk.falls = Utils.safeNumber(report.highrisk.falls) + Utils.safeNumber(advQuarterObj.highrisk.falls);
  report.highrisk.generalpresentations = Utils.safeNumber(report.highrisk.generalpresentations) + Utils.safeNumber(advQuarterObj.highrisk.generalpresentations);
  report.highrisk.mobileequip = Utils.safeNumber(report.highrisk.mobileequip) + Utils.safeNumber(advQuarterObj.highrisk.mobileequip);
  report.highrisk.scaffolding = Utils.safeNumber(report.highrisk.scaffolding) + Utils.safeNumber(advQuarterObj.highrisk.scaffolding);
  report.highrisk.workplatforms = Utils.safeNumber(report.highrisk.workplatforms) + Utils.safeNumber(advQuarterObj.highrisk.workplatforms);
  //cor
  report.cor.numgapanalysis = Utils.safeNumber(report.cor.numgapanalysis) + Utils.safeNumber(advQuarterObj.cor.numgapanalysis);
  report.cor.numnewcor = Utils.safeNumber(report.cor.numnewcor) + Utils.safeNumber(advQuarterObj.cor.numnewcor);
  report.cor.totalconsultations = Utils.safeNumber(report.cor.totalconsultations) + Utils.safeNumber(advQuarterObj.cor.totalconsultations);
  report.cor.totalsmbconsultations = Utils.safeNumber(report.cor.totalsmbconsultations) + Utils.safeNumber(advQuarterObj.cor.totalsmbconsultations);
  report.cor.totallbconsultations = report.cor.totalconsultations - report.cor.totalsmbconsultations;
  report.cor.numgapanalysisCOR = Utils.safeNumber(report.cor.numgapanalysisCOR) + Utils.safeNumber(advQuarterObj.cor.numgapanalysisCOR);
  report.cor.numgapanalysisCORFollowup = Utils.safeNumber(report.cor.numgapanalysisCORFollowup) + Utils.safeNumber(advQuarterObj.cor.numgapanalysisCORFollowup);
  report.cor.numcorcertifiedsmall = Utils.safeNumber(report.cor.numcorcertifiedsmall) + Utils.safeNumber(advQuarterObj.cor.numcorcertifiedsmall);
  report.cor.numcorregisteredsmall = Utils.safeNumber(report.cor.numcorregisteredsmall) + Utils.safeNumber(advQuarterObj.cor.numcorregisteredsmall);
  report.cor.numcorunregisteredsmall = Utils.safeNumber(report.cor.numcorunregisteredsmall) + Utils.safeNumber(advQuarterObj.cor.numcorunregisteredsmall);
  report.cor.numcorcertifiedlarge = Utils.safeNumber(report.cor.numcorcertifiedlarge) + Utils.safeNumber(advQuarterObj.cor.numcorcertifiedlarge);
  report.cor.numcorregisteredlarge = Utils.safeNumber(report.cor.numcorregisteredlarge) + Utils.safeNumber(advQuarterObj.cor.numcorregisteredlarge);
  report.cor.numcorunregisteredlarge = Utils.safeNumber(report.cor.numcorunregisteredlarge) + Utils.safeNumber(advQuarterObj.cor.numcorunregisteredlarge);
  //contact
  report.contacttype.numemail = Utils.safeNumber(report.contacttype.numemail) + Utils.safeNumber(advQuarterObj.contacttype.numemail);
  report.contacttype.numinperson = Utils.safeNumber(report.contacttype.numinperson) + Utils.safeNumber(advQuarterObj.contacttype.numinperson);
  report.contacttype.numtext = Utils.safeNumber(report.contacttype.numtext) + Utils.safeNumber(advQuarterObj.contacttype.numtext);
  report.contacttype.numphone = Utils.safeNumber(report.contacttype.numphone) + Utils.safeNumber(advQuarterObj.contacttype.numphone);
  report.contacttype.numonline = Utils.safeNumber(report.contacttype.numonline) + Utils.safeNumber(advQuarterObj.contacttype.numonline);
  //other activites
  report.otheractivities.otherevents = Utils.safeNumber(report.otheractivities.otherevents) + Utils.safeNumber(advQuarterObj.otheractivities.otherevents);
  report.otheractivities.socialmedia = Utils.safeNumber(report.otheractivities.socialmedia) + Utils.safeNumber(advQuarterObj.otheractivities.socialmedia);
  report.otheractivities.safetyclimatetool = Utils.safeNumber(report.otheractivities.safetyclimatetool) + Utils.safeNumber(advQuarterObj.otheractivities.safetyclimatetool);
  report.otheractivities.overdosePresentation = Utils.safeNumber(report.otheractivities.overdosePresentation) + Utils.safeNumber(advQuarterObj.otheractivities.overdosePresentation);
  report.otheractivities.wiva = Utils.safeNumber(report.otheractivities.wiva) + Utils.safeNumber(advQuarterObj.otheractivities.wiva);

  // companyvisit
  report.companyvisit.new = Utils.safeNumber(report.companyvisit.new) + Utils.safeNumber(advQuarterObj.companyvisit.new);
  report.companyvisit.followup = Utils.safeNumber(report.companyvisit.followup) + Utils.safeNumber(advQuarterObj.companyvisit.followup);
  // sms
  report.sms.review = Utils.safeNumber(report.sms.review) + Utils.safeNumber(advQuarterObj.sms.review);
  report.sms.followup = Utils.safeNumber(report.sms.followup) + Utils.safeNumber(advQuarterObj.sms.followup);
  // program
  report.program.review = Utils.safeNumber(report.program.review) + Utils.safeNumber(advQuarterObj.program.review);
  report.program.followup = Utils.safeNumber(report.program.followup) + Utils.safeNumber(advQuarterObj.program.followup);
  
  report.consulting.consultingadminhours = Utils.safeNumber(report.consulting.consultingadminhours) + Utils.safeNumber(advQuarterObj.consulting.consultingadminhours);
  
  return report;
}
