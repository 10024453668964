import React, { Component } from "react";
import { Container, Col, Row, DropdownButton, Dropdown} from "react-bootstrap";
import autoBind from 'react-autobind';
//
import "./../../stylesheets/CommonLogFilter.css"
import Utils from "./../../components/Utils";
//
export default class CommonLogFilter extends Component {
  constructor(props) {
    super(props);
    //setup
    autoBind(this,"handleChange");
    this.isAdmin = this.props.app.sharedCache().isLoggedUserAdmin();
    this.buildFilter();
  }
  //helper
  buildFilter() {
    var yms = {};
    Utils.setReportDateRange(yms);
    //
    // yms.years.push(2021);
    //
    var newState = {
        selectedYear: yms.currentYear,
        selectedMonth: yms.currentPreviousMonth,
        selectedUser: (this.isAdmin ? "" : this.props.app.sharedCache().getLoggedUserName()),
        years: yms.years,
        months: yms.months
      };
      if (this.props.showUsers) { newState.users = this.props.app.sharedCache().getFormattedUsers(); }
      this.state = newState;
  }
  //Public
  getFilters() {
    let retVal =  {
      selectedMonth: this.state.selectedMonth,
      selectedYear: this.state.selectedYear
    };
    if (this.props.showUsers) retVal['selectedUser'] = this.state.selectedUser;
    return retVal;
  }
  //Actions
  handleChange(value, event) {
    const targetID = event.target.name;
    let hasChanged = false;
    if (targetID == "user") {
      if (this.state.selectedUser != value) hasChanged = true;
      this.state.selectedUser = value;
    } else if (targetID == "year") {
      if (this.state.selectedYear != value) hasChanged = true;
      this.state.selectedYear = value;
    } else {
      if (this.state.selectedMonth != value) hasChanged = true;
      this.state.selectedMonth = value;
    }
    //let go button be activate
    this.setState(this.state);
    if (hasChanged) {
      var yms = { selectedYear: this.state.selectedYear };
      Utils.setReportDateRange(yms);
      
      this.setState((prevState) => ({
        ...prevState,
        years: yms.years,
        months: yms.months,
      }), this.props.handleFilterSubmit);
    }
  }
  //UI
  render() {
    //render
    return (
      <Container className={this.props.isQRStyle ? "" : "filterContainer"}>
        <Row >
          {this.getYearsFilter()}
          {this.getMonthsFilter()}
          {this.getUsersFilter()}
        </Row>
       </Container>
     );
     // <Col className="col-auto ml-auto">
     //   <Button disabled={!this.state.hasChanged} className="goButton" onClick={this.props.handleFilterSubmit} variant="success">Search</Button>
     // </Col>
  }

getYearsFilter() {
  return (
      <Col className="col-auto">
        <DropdownButton className="yearButton" name="year" title={"Year: " + this.state.selectedYear} onSelect={this.handleChange}>
          {this.state.years.map(
            obj => {
              return (<Dropdown.Item name="year" className="yearButtonItem" key={obj} eventKey={obj}>{obj}</Dropdown.Item>)
            }
          )}
        </DropdownButton>
      </Col>
  );
}
getMonthsFilter() {
  return (
    this.props.hideMonth == undefined || !this.props.hideMonth ?
      <Col className="col-auto">
        <DropdownButton className="monthButton" name="month" title={"Month: " + Utils.getMonthName(this.state.selectedMonth)} onSelect={this.handleChange}>
          {this.state.months.map(
            obj => {
              return (<Dropdown.Item name="month" className="monthButtonItem" key={obj} eventKey={obj}>{Utils.getMonthName(obj)}</Dropdown.Item>)
            }
          )}
        </DropdownButton>
      </Col> :
      <></>
  );
}
  getUsersFilter() {
    return (
      this.props.showUsers && this.isAdmin ?
        <Col className="col-auto">
          <DropdownButton className="usersButton" name="user" title={"User: " + (this.state.selectedUser == "" ? "All" : this.state.selectedUser)} onSelect={this.handleChange}>
            <Dropdown.Item name="user" className="usersButtonItem" key="" eventKey="" value="">All</Dropdown.Item>
            {this.state.users.map(
              obj => {
                var username = obj["username"];
                return (<Dropdown.Item name="user" className="usersButtonItem" key={username} eventKey={username}>{username}</Dropdown.Item>)
              }
            )}
          </DropdownButton>
        </Col> :
      <></>
    );
  }
}
