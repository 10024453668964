import React, { Component } from "react";
import { Tab, Tabs, Col, Row } from "react-bootstrap";
//
import APIUtils from "./../../components/APIUtils";
import FormInput from "./../../components/FormInput";
//
export default class AdvisorLogFormChildren extends Component {
  constructor(props) {
    super(props);

    const newCategories = this.props.app.sharedCache().getNewCategories();
    this.firstCategory = newCategories[0].pk;

    this.state = {
      selectedTab: 1,
      subcategories: this.props.app.sharedCache().getSubCategories(this.firstCategory),
    };
  }
  handleChangeCategory = (event) => {
    this.setState({
      subcategories: this.props.app.sharedCache().getSubCategories(event.target.value),
    });
    this.props.dataSource.handleChange({
      target: {
        id: 'subCategories',
        value: [],
      },
    });
  }
  handleChangeWorksafeAcc = (event) => {
    const value = event.target.value.replace(/\D/g, '');

    this.props.dataSource.handleChange({
      target: {
        id: event.target.id,
        value,
      },
    });
  }
  //UI
  render() {
    var editMode = !this.props.viewOnly;
    return (
      <Tabs activeKey={this.state.selectedTab} className="log-nav-item" onSelect={this.props.dataSource.handleTabChange}>
        {this.returnGeneralView(editMode)}
        {this.returnTimeTrackingView(editMode)}
      </Tabs>
    );
  }
  returnGeneralView(editMode) {
    if (!this.props.dataSource.state.data.category) {
      // Send the default category to the dataSource
      this.props.dataSource.handleChange({ target: { id: 'category', value: this.firstCategory }});
    }

    return (
      <Tab className="childBox child1" eventKey="1" title="General">
        <Row className="justify-content-start align-items-end">
          <Col className="col-2">
            <h6>
              <FormInput id="activitydate" labelText="Activity Date" enabled={editMode} isRequired={true} type={"date"} parent={this.props.dataSource} defaultValue={APIUtils.getCurrentDateOnUIFormat()}/>
            </h6>
          </Col>
          <Col className="col-2">
            <h6>
              <FormInput id="isClientRelated" labelText="Work performed for specific company / member" enabled={editMode} isRequired={false} type="checkbox" parent={this.props.dataSource} defaultValue={true}/>
            </h6>
          </Col>
          <Col className="col-2">
            <h6>
              <FormInput id="isPresentation" labelText="Presentation / Webinar / Toolbox Talk / Conference" enabled={editMode} isRequired={false} type="checkbox" parent={this.props.dataSource} defaultValue={false}/>
            </h6>
          </Col>
          <Col className="col-3">
            <h6>
              <FormInput id="category" labelText="Category" enabled={editMode} isRequired={false} type={"select"} parent={this.props.dataSource} listValues={this.props.app.sharedCache().getNewCategories()} onChange={this.handleChangeCategory}/>
            </h6>
          </Col>
          <Col className="col-3">
            <h6>
            <FormInput id="subCategories" labelText="Sub-categories" enabled={editMode} isRequired={false} type={"multiselect"} parent={this.props.dataSource} listValues={this.state.subcategories} hideIdOnDisplay/>
            </h6>
          </Col>
        </Row>

        <Row className="justify-content-start align-items-end">
          <Col className="col-2 offset-4">
            <h6>
              <FormInput id="numattendees" labelText="Number of Attendees" enabled={editMode && (this.props.dataSource.state.data.isPresentation == true)} isRequired={true} type={"number"} parent={this.props.dataSource} defaultValue={0} customProps={{min: 1}}/>
            </h6>
          </Col>
        </Row>

        <Row className="justify-content-start align-items-start">
          <Col className="col-5">
            <Row className="justify-content-start align-items-end">
              <Col className="col-4">
                <h6>
                  <FormInput id="wcdid" labelText="WorkSafeBC Account #" enabled={editMode} isRequired={this.props.dataSource.state.data.isClientRelated == true} type={"text"} parent={this.props.dataSource} customProps={{maxLength: 12, minLength: 6}} onChange={this.handleChangeWorksafeAcc} preventPropagateToParent/>
                </h6>
              </Col>
              <Col className="col-8">
                <h6>
                  <FormInput id="repname" labelText="Company Representative's name" enabled={editMode} isRequired={this.props.dataSource.state.data.isClientRelated == true} type={"text"} parent={this.props.dataSource}/>
                </h6>
              </Col>
            </Row>

            <Row className="justify-content-start align-items-end">
              <Col className="col-4">
                <h6>
                  <FormInput id="companyname" labelText="Company name" enabled={editMode} isRequired={this.props.dataSource.state.data.isClientRelated == true} type={"text"} parent={this.props.dataSource} placeholderValue={"Enter company name"}/>
                </h6>
              </Col>
              <Col className="col-8">
                <h6>
                  <FormInput id="repphone" labelText="Company Representative's phone number" enabled={editMode} isRequired={this.props.dataSource.state.data.isClientRelated == true} type={"tel"} parent={this.props.dataSource} customProps={{pattern:"([0-9\-\(\) ]*)",step: 0}}/>
                </h6>
              </Col>
            </Row>

            <Row className="justify-content-start align-items-end">
              <Col className="col-4">
                <h6>
                  <FormInput id="companysize" labelText="Company Size" enabled={editMode} isRequired={this.props.dataSource.state.data.isClientRelated == true} type={"select"} parent={this.props.dataSource} emptyOptionValue="Select..." listValues={[
                    { pk: '', description: 'Select...' },
                    ...this.props.app.sharedCache().getCompanySizes(),
                  ]}/>
                </h6>
              </Col>
              <Col className="col-8">
                <h6>
                  <FormInput id="repemail" labelText="Company Representative's email" enabled={editMode} isRequired={this.props.dataSource.state.data.isClientRelated == true} type={"email"} parent={this.props.dataSource}/>
                </h6>
              </Col>
            </Row>

            <Row className="justify-content-start align-items-end">
              <Col className="col-4">
                <h6>
                  <FormInput id="corstatus" labelText="COR Status" enabled={editMode} isRequired={false} type={"select"} parent={this.props.dataSource} emptyOptionValue="Select..." listValues={[
                    { pk: '', description: 'Select...' },
                    { pk: '1', description: 'COR Certified' },
                    { pk: '2', description: 'COR Registered' },
                    { pk: '3', description: 'Not Registered' },
                  ]} info="Select COR Certified if company is already BCCSA COR certified. Select COR Registered if company has registered/applied to BCCSA COR program."/>
                </h6>
              </Col>
              <Col className="col-8">
                <h6>
                  <FormInput id="cu" labelText="Classification Unit" enabled={editMode} isRequired={false} type={"multiselect"} parent={this.props.dataSource} listValues={this.props.app.sharedCache().getCategoryUnits()}/>
                </h6>
              </Col>
            </Row>
          </Col>
          <Col className="col-7" style={{ marginTop: 14 }}>
            <h6>
              <FormInput id="description" labelText="Details / Description of Work (incl any follow-up or next steps)" enabled={editMode} isRequired={true} type={"textarea"} parent={this.props.dataSource} placeholderValue={"Enter description"} customProps={{rows: 4}}/>
            </h6>
          </Col>
        </Row>
      </Tab>
    );
  }

  returnTimeTrackingView(editMode) {
    const displayAutocalculatedCheckbox = (this.props.dataSource && this.props.dataSource.state && this.props.dataSource.state.data && this.props.dataSource.state.data.meal && this.props.dataSource.state.data.meal.mealgst && !this.props.dataSource.state.data.meal.autocalculated);
    return (
      <Tab className="childBox child3" eventKey="3" title="Time Tracking">
        <Row className="justify-content-start align-items-start">
          <div className="childBoxCalculationBox col-8 align-items-end offset-2">
            <Col className="col-3">
              <h5 className="childBoxSubsectionLabel">Consulting:</h5>
              <h6><FormInput className="childBoxSubsectionIndent" id="consulting.consultinghours" labelText="Hours" enabled={editMode} isRequired={true} type={"number"} customProps={{min: 0, step: 'any'}} parent={this.props.dataSource} defaultValue={0}/></h6>
            </Col>
            <Col className="col-3"><h6>
              <FormInput id="consulting.consultingpayment" labelText="Payment ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/>
            </h6></Col>
            <Col className="col-3"><h6>
              <FormInput id="consulting.consultinggst" labelText="GST ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/>
            </h6></Col>
            <Col className="col-3">
              <h6><FormInput id="consulting.consultingaftertax" labelText="Total ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/></h6>
            </Col>
            <h5 className="childBoxPlusSign">+</h5>
          </div>
        </Row>
        <Row className="justify-content-start align-items-start">
          <div className="childBoxCalculationBox col-8 align-items-end offset-2">
            <Col className="col-3">
              <h5 className="childBoxSubsectionLabel">Travel Time:</h5>
              <h6><FormInput className="childBoxSubsectionIndent" id="travel.traveltime" labelText="Time (h)" enabled={editMode} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0} customProps={{min: 0, step: 'any'}}/></h6>
            </Col>
            <Col className="col-3"><h6>
              <FormInput id="travel.travelreimbursement" labelText="Payment ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/>
            </h6></Col>
            <Col className="col-3"><h6>
              <FormInput id="travel.traveltimegst" labelText="GST ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/>
            </h6></Col>
            <Col className="col-3"><h6>
              <FormInput id="travel.traveltimetotal" labelText="Total ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/>
            </h6></Col>
            <h5 className="childBoxPlusSign">+</h5>
          </div>
        </Row>
        <Row className="justify-content-start align-items-start">
          <div className="childBoxCalculationBox col-8 align-items-end offset-2">
            <Col className="col-3">
              <h5 className="childBoxSubsectionLabel">Mileage:</h5>
              <h6><FormInput className="childBoxSubsectionIndent" id="travel.km" labelText="KM Traveled" enabled={editMode} isRequired={true} type={"number"} parent={this.props.dataSource} defaultValue={0} customProps={{min: 0, step: 'any'}}/></h6>
            </Col>
            <Col className="col-3"><h6>
              <FormInput id="travel.kmreimbursement" labelText="Payment ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/>
            </h6></Col>
            <Col className="col-3"><h6>
              <FormInput id="travel.kmgst" labelText="GST ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/>
            </h6></Col>
            <Col className="col-3">
              <h6>
                <FormInput id="travel.kmtotal" labelText="Total ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/>
              </h6>
            </Col>
            <h5 className="childBoxPlusSign">+</h5>
          </div>
        </Row>
        
        <Row className="justify-content-start align-items-start">
          <div className="childBoxCalculationBox col-8 align-items-end offset-2">
            <Col className="col-3" style={{ height: 70, display: 'flex', alignItems: 'center' }}>
              <h5 className="childBoxSubsectionLabel">Meals:</h5>
            </Col>
            <Col className="col-3"><h6>
              <FormInput id="meal.mealamount" labelText="Amount ($)" enabled={editMode} isRequired={false} type={"float"} parent={this.props.dataSource} defaultValue={0} customProps={{ thousandSeparator: '', decimalSeparator: '.', decimalScale: 2 }} />
            </h6></Col>
            <Col className={displayAutocalculatedCheckbox ? "col-2" : "col-3"}><h6>
              <FormInput id="meal.mealgst" labelText="GST ($)" enabled={editMode} isRequired={false} type={"float"} parent={this.props.dataSource} defaultValue={0} customProps={{ thousandSeparator: '', decimalSeparator: '.', decimalScale: 2 }} />
            </h6></Col>
            {displayAutocalculatedCheckbox ?
              <Col className="col-1"><h6>
                <FormInput id="meal.autocalculated" labelText="Auto calculate" enabled={editMode} isRequired={false} type={"checkbox"} parent={this.props.dataSource} defaultValue={false} />
              </h6></Col> : <></>
            }
            <Col className="col-3">
              <h6>
              <FormInput id="meal.mealtotal" labelText="Total ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/>
              </h6>
            </Col>
            <h5 className="childBoxPlusSign">+</h5>
          </div>
        </Row>

        <Row className="justify-content-start align-items-center otherBox">
          <div className="childBoxCalculationBox col-3 offset-4">
            <Col className="col-12"><h6>
                <FormInput className="childBoxSubsectionIndent" id="othercosts" labelText="Other ($)" enabled={editMode} isRequired={true} type={"number.2"} parent={this.props.dataSource} defaultValue={0} customProps={{min: 0, step: 'any'}}/>
            </h6></Col>
            <h5 className="childBoxEqualSign">=</h5>
          </div>
          <Col className="col-1 offset-1 total-col align-items-end">
            <h5 className="childBoxSubsectionLabel">Total:</h5>
          </Col>
        </Row><Row className="justify-content-start align-items-start">
          <Col className="col-3 overlined-col-first offset-2">
            <h6><FormInput id="totalclaim" labelText="Costs ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/></h6>
          </Col><Col className="col-2 overlined-col">
            <h6><FormInput id="totalclaimgst" labelText="GST ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/></h6>
          </Col><Col className="col-3 overlined-col-last">
            <h6><FormInput id="totalclaimaftertax" labelText="Grand Total ($)" enabled={false} isRequired={false} type={"number"} parent={this.props.dataSource} defaultValue={0}/></h6>
          </Col>
        </Row>
      </Tab>
    );
  }
}
