import React, { Component } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Utils from "./Utils";
import autoBind from 'react-autobind';
import MultiSelect, { Creatable, components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import NumberFormat from 'react-number-format';

//FORM
  //id - element ID - also used on data get/set - REQUIRED
  //labelText - Is the label of the input - REQUIRED/can use blank string
  //enabled - is enabled or NOT - REQUIRED
  //isRequired - Valus is required or NOT - REQUIRED
  //type - input type as "text", "number", checkbox, "select" and others - REQUIRED
  //parent - the source of the information. Data should be available at parent.state.data and also will receive handleChange event - REQUIRED
    //OPTIONAL VALUES
  //defaultValue - initial default value if none is set on parent - OPTIONAL
  //placeholderValue - placeholder value - OPTIONAL
  //listValues - all possible values for input type dropdown on format[{pk,description}] - REQUIRED IF 'select' type
  //prefixValue - prefix value to be inserted before the input - OPTIONAL
  //customProps - form.control additional props - OPTIONAL
export default class FormInput extends Component {
		static tabInputCounter = 0;
		constructor(props) {
      super(props);
			autoBind(this);
			this.inputFormatter = null;
			//Optional values
			this.customProps = this.props.customProps;
			this.placeholderValue = this.props.placeholderValue;
			this.defaultValue = this.props.defaultValue;
			this.className = this.props.className;
			this.tabIndex = this.tabInputCounter; this.tabInputCounter++;
			if (this.customProps == undefined || this.customProps == null) this.customProps = {};
			if (this.placeholderValue == undefined || this.placeholderValue == null) this.placeholderValue = "";
			if (this.defaultValue == undefined || this.defaultValue == null) this.defaultValue = "";
			if (this.className == undefined || this.className == null) this.className = "";
			this.validateFormat();
    }
    safeData() { return this.props.parent.state.data === undefined ? {} : this.props.parent.state.data; }
		handleMultiSelectChange(val) { this.props.parent.handleChange({target:{id: this.props.id, value: val}}); }
		handleCreatableDropdownChange(val) { this.props.parent.handleChange({target:{id: this.props.id, value: val.value}}); }
		handleChange(e) {
      if (this.props.type == 'select' && (this.props.emptyOptionValue && e.target.value == this.props.emptyOptionValue)) {
        e.target.value = '';
      }

      if (this.props.onChange) this.props.onChange(e);

      if (!this.props.preventPropagateToParent) {
        this.props.parent.handleChange(e);
      }

			this.validateFormat();
		}
    render() {
      if (this.props.parent == undefined || this.props.parent.state == undefined) return (<>Loading</>);
      //get value
      var value = Utils.getNestedObject(this.safeData(),this.props.id);
      var localType = this.props.type;
      //If not specified value, try to use default value
      if (value === undefined || value === null) { value = this.defaultValue; }

			//check for custom types
			let noStepper = false;
      if (localType == "number.2") {
				localType = "number";
			} else if (localType == "number.nostep") {
				localType = "number";
				noStepper = true;
			}
      //Add into form as default values in case of starting a NEW form
      if (value !== "") { this.props.parent.state.data = Utils.setNestedObject(this.safeData(),this.props.id,value); }

      //Check for types to render
       //DROPDOWN
      if (localType == "select") {return this.renderDropdownInput(value, localType); }
			//CREATABLE DROPBOWN
		 	else if (localType == "creatable-select") {return this.renderCreatableDropdownInput(value, localType); }
			//MULTISELECT
			else if (localType == "multiselect") {return this.renderMultiSelectInput(value, localType); }
      //PREFIXED INPUT
      else if (this.props.prefixValue !== undefined && this.props.prefixValue !== null) { return this.renderPrefixedInput(value, localType); }
      //CHECKBOX
      else if (localType == "checkbox") { return this.renderCheckBoxInput(value, localType); }
      //NUMERIC
      else if (localType == "number") { return this.renderNumericInput(value, localType, noStepper); }
      //FLOAT
      else if (localType == "float") { return this.renderFloatInput(value); }
      //normal input
      else { return this.renderNormalInput(value, localType); }
    }


		//Utils
		validateFormat() {
			//clear previous input formatter timmer
			if (this.inputFormatter != null) clearTimeout(this.inputFormatter);
			//start new formatter timer
			this.inputFormatter = setTimeout(() => {
				if (this.props.type == "number.2") {
					var value = Utils.getNestedObject(this.safeData(),this.props.id);
					if (!isNaN(parseFloat(value))) {
						value = parseFloat(value).toFixed(2);
						this.props.parent.state.data = Utils.setNestedObject(this.safeData(),this.props.id,value);
						this.forceUpdate();
					}
				}
			},1000);
		}
		//Renders
    renderDropdownInput(value, type) {
      return(<Form.Group controlId={this.props.id} className={this.className}>
            {this.renderLabeltext()}
            <Form.Control as={type} onChange={this.handleChange} value={value} name={this.props.id} {... this.customProps}
                          required={this.props.isRequired} disabled={!this.props.enabled} type={type} tabIndex={this.props.disabled ? -1 : this.tabIndex}>
                          {this.props.listValues.map(
                            obj => (<option key={obj["pk"]} id={obj["pk"]}>{obj["description"]}</option>),
                          )}
                        </Form.Control>
      </Form.Group>);
    }
		renderCreatableDropdownInput(value, type) {
			value = {'value': value}; //encapsulate to make it compatible
			return (<Form.Group controlId={this.props.id} className={this.className}>
            		{this.renderLabeltext()}
								<Creatable options={this.props.listValues} isDisabled={!this.props.enabled}
							 				 		 getOptionLabel={(d) => d.value} onChange={this.handleCreatableDropdownChange} value={value}
											 		 getOptionValue={(d) => d.value} {... this.customProps} tabIndex={this.props.disabled ? -1 : this.tabIndex}/>
      			 </Form.Group>);
    }
		renderMultiSelectInput(value, type) {
			const MultiValue = props => (<components.MultiValue key={props.data.id} {...props}>{props.data.id}</components.MultiValue>);
			//required={this.props.isRequired}
			return (<Form.Group controlId={this.props.id} className={this.className}>
            {this.renderLabeltext()}
						<MultiSelect isMulti={true} options={this.props.listValues} components={{MultiValue}} isDisabled={!this.props.enabled}
							 					 getOptionLabel={(d) => ((this.props.hideIdOnDisplay ? '' : `${d.id} - `) + d.desc)} onChange={this.handleMultiSelectChange} value={value}
												 getOptionValue={(d) => d.id} {... this.customProps} tabIndex={this.props.disabled ? -1 : this.tabIndex}/>
      </Form.Group>);
		}
    renderPrefixedInput(value, type) {
      return (<Form.Group controlId={this.props.id} className={this.className}>
                {this.renderLabeltext()}
                <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrepend">{this.props.prefixValue}</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control onChange={this.handleChange} name={this.props.id} aria-describedby="inputGroupPrepend"
                                    required={this.props.isRequired} disabled={!this.props.enabled} {... this.customProps}
                                    placeholder={this.placeholderValue} checked={value} type={type} value={value} tabIndex={this.props.disabled ? -1 : this.tabIndex}>
                                  </Form.Control>
                </InputGroup>
              </Form.Group>
        );
    }
    renderCheckBoxInput(value, type) {
      return ( //we dont use required here cause checkbox are in the class on fields that have the initial value always ON
        <Form.Group controlId={this.props.id} className={this.className}>
              {this.renderLabeltext()}
              <Form.Control onChange={this.handleChange} name={this.props.id}
                            disabled={!this.props.enabled} checked={value} type={type} tabIndex={-1}>
                          </Form.Control>
        </Form.Group>
      );
    }
    renderFloatInput(value) {
      return (
        <Form.Group className={this.className}>
          {this.renderLabeltext()}
          <NumberFormat
            {...this.customProps}
            id={this.props.id}
            className="form-control"
            onChange={this.handleChange}
            name={this.props.id}
            required={this.props.isRequired}
            disabled={!this.props.enabled}
            placeholder={this.placeholderValue}
            value={value}
          />
        </Form.Group>
      );
    }
    renderNumericInput(value, type, noStepper) {
			//
			let otherProps = {};
			if (noStepper) {
				let preventBehavior = (e) => { e.preventDefault() }
				otherProps.onWheel = preventBehavior;
			}
      return (
        <Form.Group controlId={this.props.id} className={noStepper ? "noStepper " + this.className : this.className}>
							{this.renderLabeltext()}
              <Form.Control onChange={this.handleChange} name={this.props.id} {... this.customProps}
                            required={this.props.isRequired} disabled={!this.props.enabled} {... otherProps}
                            placeholder={this.placeholderValue} type={type} value={value} tabIndex={this.props.disabled ? -1 : this.tabIndex}>
                          </Form.Control>
        </Form.Group>
      );
    }
    renderNormalInput(value, type) {
      return (
        <Form.Group controlId={this.props.id} className={this.className}>
              {this.renderLabeltext()}
              <Form.Control onChange={this.handleChange} name={this.props.id} {... this.customProps}
                            required={this.props.isRequired} disabled={!this.props.enabled} placeholder={this.placeholderValue}
                            type={type} as={(type == "textarea" ? type : "input")} value={value}  tabIndex={this.props.disabled ? -1 : this.tabIndex}>
                          </Form.Control>
        </Form.Group>
      );
    }
		renderLabeltext() {
      const { info } = this.props;
      const hasInfo = !!info;

			return (
				this.props.labelText == undefined ?
					<></> :
					<Form.Group>
            {this.props.labelText}
            {hasInfo && (
            <Tooltip placement="right" overlay={info} overlayStyle={{ maxWidth: 300 }}>
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faInfoCircle} size="1x" />
            </Tooltip>)}
          </Form.Group>

			);
		}
}
