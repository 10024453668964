import React, { Component } from "react";
import { Nav, Button, OverlayTrigger, Popover, ListGroup } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import autoBind from 'react-autobind';
//
import CommonChangePassword from "./views/CommonChangePassword";
//
import AppRoutes from "./AppRoutes";
import "./stylesheets/AppViewController.css";
//
class AppViewController extends Component {

  constructor(props) {
    super(props);
    autoBind(this);
    this.props.app.props.history.location.state = undefined; //reset history state if restarting app
    this.handleLogout = this.handleLogout.bind(this);
  }
  //Actions
  handleLogout() {
    this.props.app.setAuthenticatedUser(null);
    this.props.history.push("/");
  }
  handleChangePassword() { this.changePassModal.handleShow(); }
  //UI
  render() {
    return this.renderPage();
  }
  renderPage() {
    return (
      <div className="App">
        <div className="wrapper">
            {this.renderHeader()}
            <div className="main">
              {this.renderUserNavigationBar()}
              <AppRoutes appRef={this}/>
            </div>
          </div>
      </div>
    );
  }
  renderHeader() {
    const popover = (
        <Popover className="userPopover">
          <ListGroup className="userPopoverList">
            <ListGroup.Item as="button" onClick={this.handleChangePassword}>Change password</ListGroup.Item>
            <ListGroup.Item as="button" onClick={this.handleLogout}>Logout</ListGroup.Item>
          </ListGroup>
        </Popover>
      );
    return (<header className="header d-flex align-content-center justify-content-end">
              <h1 className="mr-auto logo"></h1>
              <CommonChangePassword ref={f => (this.changePassModal = f)} app={this.props.app} alertController={this.props.alertController}/>
              {(this.props.app.sharedCache().isLogged() ?
                <OverlayTrigger trigger="click" placement="bottom" rootClose={true} overlay={popover}>
                  <Button className="my-auto buttonUserName" variant="none">{this.props.app.sharedCache().getLoggedUserName()}</Button>
                </OverlayTrigger>
              : <></>)}
            </header>);

  }
  renderUserNavigationBar() {
    if (!this.props.app.sharedCache().isLogged()) {
      return(<></>);
    }else {
      return (
                <Nav defaultActiveKey="/admUsers" className="flex-column nav navbar">
                  <h3>Menu</h3>
                  {(this.props.app.sharedCache().isLoggedUserAdmin() ?
                    <>
                      <IndexLinkContainer to="/"><Nav.Item>Submissions</Nav.Item></IndexLinkContainer>
                      <LinkContainer to="/admReportHistory"><Nav.Item>Advisory History by Month</Nav.Item></LinkContainer>
                      <LinkContainer to="/admReportHistoryByRSA"><Nav.Item>Advisory History by RSA</Nav.Item></LinkContainer>
                      <LinkContainer to="/admYearlyReport"><Nav.Item>Yearly Summary</Nav.Item></LinkContainer>
                      <LinkContainer to="/admUsers"><Nav.Item>User Management</Nav.Item></LinkContainer>
                      <LinkContainer to="/admSettings"><Nav.Item>System Settings</Nav.Item></LinkContainer>
                    </>
                      :
                    <>
                      <IndexLinkContainer to="/"><Nav.Item>Activity Report</Nav.Item></IndexLinkContainer>
                      <LinkContainer to="/advReportHistory"><Nav.Item>Monthly Report</Nav.Item></LinkContainer>
                      <LinkContainer to="/advYearlyReport"><Nav.Item>Yearly Report</Nav.Item></LinkContainer>
                    </>)}
                </Nav>);
    }
  }
}
export default AppViewController;
