import React from "react";
import { Button, Form, Container, Col, Row, Modal } from "react-bootstrap";
import autoBind from 'react-autobind';
//
import CustomComponent from "./../../components/CustomComponent";
import FormInput from "./../../components/FormInput";
import Utils from "./../../components/Utils";
import APIUtils from "./../../components/APIUtils";
//resources
import "./../../stylesheets/AdvisorLogSubmissionModal.css";
//
export default class AdminRejectionModal extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { show: false, data: {rejectionReason: ""} };
  }
  //Actions
  handleChange(event) { Utils.defaultFormChangeHandler(event, this); }
  //Actions
  handleRejection(e) {
    e.preventDefault();
    this.props.handleSubmission(this.state, false);
    this.handleHide();
  }
  //Actions
  handleShow(mrObj) {
    //format some fields to better disyplay
    let additionalObject = {
      actDate: APIUtils.formatStringDateToGSI1SK(mrObj.gsi1sk, true),
      username: this.props.app.sharedCache().findDisplayNameByID(mrObj.pk.replace("MS::",""))
    };
    //
    this.setState({show: true, data: {rejectionReason: "", ...mrObj, ...additionalObject}});
    this.forceUpdate();
  }
  handleHide() { this.setState({show: false, isLoading: false}); }
  //UI
  render() {
    return (
      <Modal size="lg" dialogClassName="submissionModalBox" aria-labelledby="contained-modal-title-vcenter" centered className="submissionModal" show={this.state.show} onHide={this.handleHide}>
        <Form onSubmit={this.handleRejection}>
          <Modal.Header closeButton>
             <Modal.Title id="contained-modal-title-vcenter"> Reject monthly report: </Modal.Title>
           </Modal.Header>
           <Modal.Body>
             <Container>
               <Row className="justify-content-start align-items-end">
                 <h5>Details:</h5>
               </Row>
              <Row className="justify-content-start align-items-end">
                <Col className="col-4">
                  <FormInput id="actDate" labelText="Report date"  enabled={false} isRequired={false} type={"month"} parent={this}/>
                </Col>
                <Col className="col-8">
                  <FormInput id="username" labelText="Username" enabled={false} isRequired={false} type={"text"} parent={this}/>
                </Col>
              </Row>
              <Row className="justify-content-start align-items-end">
                <Col className="col-4">
                  <FormInput id="totalclaimaftertax" labelText="Total claim ($)"  enabled={false} isRequired={false} type={"number.2"} parent={this}/>
                </Col>
              </Row>
              <Row className="justify-content-start align-items-end">
                <h5>Rejection reason:</h5>
              </Row>
              <Row className="justify-content-start align-items-end">
                <Col className="col-10"><h6>
                  <FormInput id="rejectionReason" enabled={true} isRequired={true} type={"textarea"} parent={this} customProps={{maxLength: 300}}/>
                </h6></Col>
              </Row>
             </Container>
           </Modal.Body>
           <Modal.Footer>
             <Button block type="submit" className="loginButton"> Reject </Button>
           </Modal.Footer>
         </Form>
      </Modal>
    );
  }

  _handleReportSubmission() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().submitAdvisorMonthlyReports(this.state.data.submissionPeriod,this.state.selectedIDs, response => {
      if (!this._isMounted) return;
      if (!response['error']) {
        this.props.alertController.showSuccessAlert("Submited!", "Report submitted with success!");
        this.props.submissionDelegate();
        this.handleHide();
      }else {
        this.props.alertController.showErrorAlert("Error", "Error while submitting monthly report.", response);
        this.stopLoading();
      }
    });
  }
}
