import React from "react";
import ReactTable from "react-table";
import autoBind from 'react-autobind';
import { Row, Col, Container } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import XLSX from 'xlsx';
//
import Utils from "./../components/Utils";
import UtilsReport from "./../components/UtilsReport";
import CustomComponent from "./../components/CustomComponent";
import CustomNavigationController from './../components/CustomNavigationController';
import CommonLogFilter from "./subviews/CommonLogFilter";
import CommonLoadingView from "./subviews/CommonLoadingView";
import CommonMultiButton from "./subviews/CommonMultiButton";
import AdminYearlyReportByRSAPDF from "./pdfs/AdminYearlyReportByRSAPDF";
import AdminYearlyReportByQuarterPDF from "./pdfs/AdminYearlyReportByQuarterPDF";
//
export default class AdminYearlyReport extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {data: [], parsedData: [], firstLoad: true, isLoading: false};
  }
  loadResponse(response) {
    //valid data state
    if (response['data'] != null) {
      if (response['data'].length == 0) {
        this.state.data = [];
        this.state.parsedData = [];
      }else {
        this.state.data = response['data'];
        let grouppedByRSA = (this.multiButton ? this.multiButton.getSelectedOptionIndex() != 1 : true);
        //Group data by option
        if (grouppedByRSA) this.state.parsedData = UtilsReport.grouppedQuartersByAdvisor(response['data'], this.props.app.sharedCache());
        else this.state.parsedData = UtilsReport.grouppedQuartersByQuarter(response['data']);
        //push yearly information
        this.state.parsedData.push(UtilsReport.generateYearlyReportRow(this.state.data));
        //Render PDF into hidden div
        Utils.renderPDF((grouppedByRSA ? AdminYearlyReportByRSAPDF : AdminYearlyReportByQuarterPDF), this.props.app, this, ()=>{
          this.pdfRef.loadData(this.state.parsedData);
        });
      }
    } else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
      this.state.parsedData = [];
    }
    this.state.firstLoad =  false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() { if (this.state.firstLoad) this.fetchData(); }

  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().fetchQuarterlyReportsByFilter(this.filterObj.getFilters(), response => {
      if (!this._isMounted) return;
      this.loadResponse(response);
    });
  }
  //Actions
  handleFilterSubmit() { this.fetchData(); }
  handleRowClick(event, row) {
    // if (row.original.pk == "Grand Total") return;
    // //push deatiled QRs view
    // this.props.navigationController.pushView(
    //   <CommonYearlyDetailedReport app={this.props.app} data={APIUtils.yearlyReportByQuarters(row.original, this.state.data)}/>
    // );
  }
  handleMultiChange() { this.loadResponse({data: this.state.data}); }

  handleDownloadXlsx(data) {
    let grouppedByRSA = (this.multiButton ? this.multiButton.getSelectedOptionIndex() != 1 : true);
    const firstColumn = grouppedByRSA ? 'User' : 'Quarter';

    function checkAndGetValue(obj, key) {
      return obj ? Utils.safeNumber(obj[key]) : 0;
    }
    
    const treatedData = data.map((item) => ({
      [firstColumn]: (
        item.isTotal
        ? 'Yearly Total'
        : (
          item.quarter
            ? `Q${parseInt(item.quarter, 10)}`
            : this.props.app.sharedCache().findDisplayNameByID(item.rsaid)
        )
      ),
      'Admin (hs)': checkAndGetValue(item.consulting, 'consultingadminhours'),
      'Visits New': checkAndGetValue(item.companyvisit, 'new'),
      'Visits Follow up': checkAndGetValue(item.companyvisit, 'followup'),
      'SMS New': checkAndGetValue(item.sms, 'new'),
      'SMS Follow up': checkAndGetValue(item.sms, 'followup'),
      'Program New': checkAndGetValue(item.program, 'new'),
      'Program Follow up': checkAndGetValue(item.program, 'followup'),
      'New Applicants Contacted': checkAndGetValue(item.cor, 'numnewcor'),
      'COR Gap Analysis': checkAndGetValue(item.cor, 'numgapanalysisCOR'),
      'COR Gap Analysis Follow up': checkAndGetValue(item.cor, 'numgapanalysisCORFollowup'),
      'Small COR Certified': checkAndGetValue(item.cor, 'numcorcertifiedsmall'),
      'Small COR Registered': checkAndGetValue(item.cor, 'numcorregisteredsmall'),
      'Small COR Unregistered': checkAndGetValue(item.cor, 'numcorunregisteredsmall'),
      'Large COR Certified': checkAndGetValue(item.cor, 'numcorcertifiedlarge'),
      'Large COR Registered': checkAndGetValue(item.cor, 'numcorregisteredlarge'),
      'Large COR Unregistered': checkAndGetValue(item.cor, 'numcorunregisteredlarge'),
      'General Presentations': checkAndGetValue(item.highrisk, 'generalpresentations'),
      'Falls': checkAndGetValue(item.highrisk, 'falls'),
      'Scaffolding': checkAndGetValue(item.highrisk, 'scaffolding'),
      'Work Platforms': checkAndGetValue(item.highrisk, 'workplatforms'),
      'Mobile Equipment': checkAndGetValue(item.highrisk, 'mobileequip'),
      'Social Media Submissions': checkAndGetValue(item.otheractivities, 'socialmedia'),
      'Phone Calls': checkAndGetValue(item.contacttype, 'numphone'),
      'Emails': checkAndGetValue(item.contacttype, 'numemail'),
      'Text Messages': checkAndGetValue(item.contacttype, 'numtext'),
      'IM Consultations': checkAndGetValue(item, 'iminquiry'),
      'Online': checkAndGetValue(item.contacttype, 'numonline'),
      'Safety Climate Tool': checkAndGetValue(item.otheractivities, 'safetyclimatetool'),
      'Overdose Awareness': checkAndGetValue(item.otheractivities, 'overdosePresentation'),
      'WIVA': checkAndGetValue(item.otheractivities, 'wiva'),
    }));

    const spreadsheet = XLSX.utils.json_to_sheet(treatedData);
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, spreadsheet, "Sheet1");

    const { selectedYear } = this.filterObj.getFilters();
    const filename = grouppedByRSA ? `YearlySummarybyRSA-${selectedYear}.xlsx` : `YearlySummarybyQuarter-${selectedYear}.xlsx`;
    XLSX.writeFile(workbook, filename);
  }
  //UI
  render() {
    const data = (this.state.parsedData == null ? [] : this.state.parsedData);
    const props = {
      showPagination: false, minRows: 6, defaultPageSize: 1000,
      noDataText: "No reports!", loadingText: "Loading...", keyField :'pk',
      headerStyle: {}, loading:this.state.isLoading,
      getTrProps: (s, r) => {
        return { onClick: (e) => { this.handleRowClick(e, r) }, };
      }
    };
    let grouppedByRSA = (this.multiButton ? this.multiButton.getSelectedOptionIndex() != 1 : true);
    const columns = [
    {
      width: 150,
      Header: ()=> <div className="alignMidHeader">{(grouppedByRSA ? "User" : "Quarter")}</div>,
      id: 'sk',
      headerStyle: props.headerStyle,
      Cell: props => {
        if (props.original.isTotal) return (<span className='detailedYearlyReportGrandTotal'><b>Yearly Total</b></span>);
        else if (props.original.quarter) return "Q" + parseInt(props.original.quarter);
        else return this.props.app.sharedCache().findDisplayNameByID(props.original.rsaid);
      }
    },
    {
      width: 120,
      Header: ()=> <div className="alignMidHeader"># Admin (hs)</div>,
      headerStyle: props.headerStyle,
      accessor: 'consulting.consultingadminhours',
      Cell: props => Utils.safeNumber(props.value)
    },
    // Visits
    {
      width: 120,
      Header: ()=> <div className="alignMidHeader"># Visits New</div>,
      headerStyle: props.headerStyle,
      accessor: 'companyvisit.new',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 120,
      Header: ()=> <div className="alignMidHeader"># Visits Follow up</div>,
      headerStyle: props.headerStyle,
      accessor: 'companyvisit.followup',
      Cell: props => Utils.safeNumber(props.value)
    },
    // SMS
    {
      width: 120,
      Header: ()=> <div className="alignMidHeader"># SMS New</div>,
      headerStyle: props.headerStyle,
      accessor: 'sms.new',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 120,
      Header: ()=> <div className="alignMidHeader"># SMS <br /> Follow up</div>,
      headerStyle: props.headerStyle,
      accessor: 'sms.followup',
      Cell: props => Utils.safeNumber(props.value)
    },
    // Program
    {
      width: 140,
      Header: ()=> <div className="alignMidHeader"># Program New</div>,
      headerStyle: props.headerStyle,
      accessor: 'program.new',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 140,
      Header: ()=> <div className="alignMidHeader"># Program <br />Follow up</div>,
      headerStyle: props.headerStyle,
      accessor: 'program.followup',
      Cell: props => Utils.safeNumber(props.value)
    },
    // COR
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># New Applicants <br /> Contacted</div>,
      headerStyle: props.headerStyle,
      accessor: 'cor.numnewcor',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 135,
      Header: ()=> <div className="alignMidHeader"># COR Gap <br /> Analysis</div>,
      headerStyle: props.headerStyle,
      accessor: 'cor.numgapanalysisCOR',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># COR Gap Analysis <br /> Follow up</div>,
      headerStyle: props.headerStyle,
      accessor: 'cor.numgapanalysisCORFollowup',
      Cell: props => Utils.safeNumber(props.value)
    },
    // Small business
    {
      width: 130,
      Header: ()=> <div className="alignMidHeader"># Small <br /> COR Certified</div>,
      headerStyle: props.headerStyle,
      accessor: 'cor.numcorcertifiedsmall',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 155,
      Header: ()=> <div className="alignMidHeader"># Small <br /> COR Registered</div>,
      headerStyle: props.headerStyle,
      accessor: 'cor.numcorregisteredsmall',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 130,
      Header: ()=> <div className="alignMidHeader"># Small <br /> Unregistered</div>,
      headerStyle: props.headerStyle,
      accessor: 'cor.numcorunregisteredsmall',
      Cell: props => Utils.safeNumber(props.value)
    },
    // Large business
    {
      width: 130,
      Header: ()=> <div className="alignMidHeader"># Large <br /> COR Certified</div>,
      headerStyle: props.headerStyle,
      accessor: 'cor.numcorcertifiedlarge',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 155,
      Header: ()=> <div className="alignMidHeader"># Large <br /> COR Registered</div>,
      headerStyle: props.headerStyle,
      accessor: 'cor.numcorregisteredlarge',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 130,
      Header: ()=> <div className="alignMidHeader"># Large <br /> Unregistered</div>,
      headerStyle: props.headerStyle,
      accessor: 'cor.numcorunregisteredlarge',
      Cell: props => Utils.safeNumber(props.value)
    },
    // High Risk Presentations
    {
      width: 130,
      Header: ()=> <div className="alignMidHeader"># General <br /> Presentations</div>,
      headerStyle: props.headerStyle,
      accessor: 'highrisk.generalpresentations',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 130,
      Header: ()=> <div className="alignMidHeader"># Falls</div>,
      headerStyle: props.headerStyle,
      accessor: 'highrisk.falls',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 130,
      Header: ()=> <div className="alignMidHeader"># Scaffolding</div>,
      headerStyle: props.headerStyle,
      accessor: 'highrisk.scaffolding',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># Work Platforms</div>,
      headerStyle: props.headerStyle,
      accessor: 'highrisk.workplatforms',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># Mobile Equipment</div>,
      headerStyle: props.headerStyle,
      accessor: 'highrisk.mobileequip',
      Cell: props => Utils.safeNumber(props.value)
    },
    // Other activities
    {
      width: 150,
      Header: ()=> <div className="alignMidHeader"># Social Media <br /> Submissions</div>,
      headerStyle: props.headerStyle,
      accessor: 'otheractivities.socialmedia',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># Phone Calls</div>,
      headerStyle: props.headerStyle,
      accessor: 'contacttype.numphone',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># Emails</div>,
      headerStyle: props.headerStyle,
      accessor: 'contacttype.numemail',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># Text Messages</div>,
      headerStyle: props.headerStyle,
      accessor: 'contacttype.numtext',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># IM Consultations</div>,
      headerStyle: props.headerStyle,
      accessor: 'iminquiry',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># Online</div>,
      headerStyle: props.headerStyle,
      accessor: 'contacttype.numonline',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># Safety <br /> Climate Tool</div>,
      headerStyle: props.headerStyle,
      accessor: 'otheractivities.safetyclimatetool',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># Overdose <br /> Awareness</div>,
      headerStyle: props.headerStyle,
      accessor: 'otheractivities.overdosePresentation',
      Cell: props => Utils.safeNumber(props.value)
    },
    {
      width: 160,
      Header: ()=> <div className="alignMidHeader"># WIVA</div>,
      headerStyle: props.headerStyle,
      accessor: 'otheractivities.wiva',
      Cell: props => Utils.safeNumber(props.value)
    },    
  ];

    //render
    return (
    <div className="AppPageContainer">
     <CommonLoadingView isLoading={this.state.isLoading}/>
     <Container className="filterContainer">
       <Row className="overlayLabelContainer justify-content-between align-items-center">
         <Col className="col-auto">
           <CommonLogFilter app={this.props.app} hideMonth={true} showUsers={false}
                            handleFilterSubmit={this.handleFilterSubmit} isQRStyle={true}
                            {... Utils.propagateRef(this, "filterObj")}/>
         </Col>
         <Col className="col-auto">
           <CommonMultiButton handleMultiChange={this.handleMultiChange} {... Utils.propagateRef(this, "multiButton")}
                              options={["by RSA","by Quarter"]} defaultOption={"by RSA"}/>
         </Col>
         <Col className="col-auto">
          <Button variant="none" className="logBoxPrintButton" onClick={() => this.handleDownloadXlsx(data)}>
            <FontAwesomeIcon icon={faDownload} size="1x"/> XLSX
          </Button>
          { data.length > 0 && Utils.renderPrintPDFButton(this, true /*is landscape*/) }
         </Col>
       </Row>
     </Container>
     <ReactTable data={data} className="v-100 -highlight" columns={columns} {...props} />
    </div>);
  }
}
//Navigation container implementation
export class AdminYearlyReportNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props);
    this.containerClass = AdminYearlyReport;
  }
}
