import { Component } from "react";
import { Auth } from "aws-amplify";
import APIWrapper from "./APIWrapper";
import Cache from "./Cache";
//
class Authenticator extends Component {
		constructor(props) {
			super(props);
			this.state = {isAuthenticating: false};
			this._sharedCache = null;
			this._sharedAPI = null;
			this._configureBrowserStorage();
		}
	  async componentDidMount() {
	  	if (!this.sharedCache().isLogged()) this.checkForAuthetication();
		}

		//STATES
		setAuthenticatedUser(user) {
			if (user == null) { //logout
				Auth.signOut();
				this.sharedCache().setLoggedUser(null);
				this.endLoading();
			}else { //login
				this.checkForAuthetication();
			}
		}
		//Loading
		startLoading() {
			var isAuthenticating = true;
			this.setState({isAuthenticating});
		}
		//end callback
		endLoading(errored) {
			var isAuthenticating = false;
			this.setState({isAuthenticating});
			if (errored) this.sharedCache().setLoggedUser(null);
		}
		//Singleton
		sharedAPIWrapper() {
	      if (this._sharedAPI == null) { this._sharedAPI = new APIWrapper(this); }
	      return this._sharedAPI;
	  }
		//Singleton
		sharedCache() {
	      if (this._sharedCache == null) { this._sharedCache = new Cache(); }
	      return this._sharedCache;
	  }
  	//COGNITO
		renewSessionIfNeeded(callback) {
			let cognitoUser = this.sharedCache().getCognitoUser();
			let currentSession = cognitoUser.signInUserSession;
			if (!currentSession.isValid()) {
				console.log("Current session in expired, renewing it");
				let refreshToken = currentSession.getRefreshToken();
  			cognitoUser.refreshSession(refreshToken, (err, session) => {
    			if(err) {
						alert("Current session is expired and got an error when renewing it! Please, try to log-in again!");
						console.log(err);
						callback(false);
					} else {
						console.log("TOKEN SUCCESSFULLY UPDATED");
						console.log(session);
						callback(true);
					}
    		});
  		} else callback(true);
		}
		checkForAuthetication() {
			this.startLoading();
			Auth.currentSession().then(data => {
			    // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user decipher.setAuthTag(buffer)
					/*When using Authentication with AWS Amplify, you don’t need to refresh Amazon Cognito tokens manually. The tokens are automatically refreshed by the library when necessary.*/
					// This will refresh token every new login user makes it
			    Auth.currentAuthenticatedUser({bypassCache: true}).then(user => {
							this.sharedCache().setLoggedUser(user);
							this.sharedCache().setupUserCache(this, end => {
								this.renewSessionIfNeeded(end => {
									this.endLoading(false);
								});
							});
						})
		        .catch(err => {
							console.log("user not autheticated " + err);
							this.endLoading(true);
						})
			  	})
			  .catch(err => {
					this.props.history.push("/"); //push to login page!
			    console.log("user not autheticated " + err);
					this.endLoading(true);
			  });
		}
		//Browser/Cognito
		_configureBrowserStorage() {
			//This will prevent issue with empty storage on aws-amplify < 1.1.29
			window.localStorage.setItem("key", "value");
			window.localStorage.something = "value"; //This prevent issue with firefox that checks for property differently
			window.sessionStorage.setItem("key", "value");
			window.sessionStorage.something = "value";
		}
		configureAuthStorage(rememberMe) {
			Auth.configure({ storage: rememberMe ? window.localStorage : window.sessionStorage })
		}

};

export default Authenticator;
