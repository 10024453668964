import React from "react";
import ReactTable from "react-table";
import { Row, Col, Button, Container, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import autoBind from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
//
import APIUtils from  "./../components/APIUtils";
import Utils from "./../components/Utils";
import UtilsReport from "./../components/UtilsReport";
import CustomComponent from "./../components/CustomComponent";
import CustomNavigationController from '../components/CustomNavigationController';
import CommonLoadingView from "./subviews/CommonLoadingView";
import CommonLogFilter from "./subviews/CommonLogFilter";
import CommonLogView from "./subviews/CommonLogView";
import CommonMonthlySubmissionPDF from "./pdfs/CommonMonthlySubmissionPDF";
//
import "./../stylesheets/AdvisorReportHistory.css";
//
export default class AdvisorReportHistory extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {data: [], rowsData: [], firstLoad: true, isLoading: false};
  }
  loadResponse(response) {
    //valid data state
    if (response['data'] != null) {
      this.state.data = response["data"];
      this.state.rowsData = UtilsReport.generateMonthlyReportRows(response['data']);
      //Render PDF into hidden div
      Utils.renderPDF(CommonMonthlySubmissionPDF, this.props.app, this, () => {
        this.pdfRef.loadData(this.state.rowsData, this.state.data);
      });
    } else { //non-valid data state
      if (response["errorData"]) this.props.alertController.showErrorAlert("Error!","",response);
      this.state.data = [];
      this.state.rowsData = [];
    }
    this.state.firstLoad = false;
    this.stopLoading(false);
    this.setState(this.state);
  }
  async componentDidMount() {
    if (this.state.firstLoad) this.fetchData();
  }
  //API
  fetchData() {
    this.startLoading();
    this.props.app.sharedAPIWrapper().fetchAdvisorMonthlyReportEntriesByPeriod(this.filterObj.getFilters(), response => {
      if (!this._isMounted) return;
      this.loadResponse(response);
    });
  }
  //Actions
  handleFilterSubmit() { this.fetchData(); }
  handleRowClick(event, row) {
    if (row.original.isNotData) return;
    this.props.navigationController.pushView(
     <CommonLogView reportLGID={row.original} app={this.props.app}
                    alertController={this.props.alertController}/>
    );
  }
  handleDuplicatedEntry(logObj, event) {
    event.stopPropagation();
    this.startLoading();
    //fetch log info
    this.props.app.sharedAPIWrapper().fetchLogEntry(logObj,response => {
      if (!this._isMounted) return;
      if (response['data'] != null && response['data'].length > 0) {
        let dupObject = APIUtils.duplicatedLogEntryObject(response['data'][0]);
        this.props.app.props.history.push("/",dupObject);
      }else this.props.alertController.showErrorAlert("Error!","Could not fetch information to duplicated.",response);
    });
  }
  //UI
  render() {
    const props = {
      showPagination: false, minRows: 6,
      defaultPageSize: 1000, noDataText: "No report available!",
      loadingText: "Loading...", keyField :'pk',
      defaultSorted: [{ id: "activitydate", desc: false}],
      sortable: false, loading:this.state.isLoading,
      getTrProps: (s, r) => {
        return { onClick: (e) => { this.handleRowClick(e, r) } };
      }
    };
    const columns = [
      {
        Header: 'Activity Date',
        id: "activitydate",
        width: 130,
        accessor: d => {
          if (d.isSpace || d.isNotData) return d.activitydate;
          return Utils.toDateFormat(d.activitydate);
        },
        Cell: props => {
          if (props.original.isSpace) return " ";
          else if (props.original.isNotData) return (<div className='text-left detailedLogReportTotal'><b>{props.original.title}</b></div>);
          return props.value;
        }
      }, {
        Header: 'Activity Description',
        accessor: 'description',
        width: 180
      }, {
        Header: 'Company Name',
        accessor: 'companyname',
        width: 150
      }, {
        Header: 'KM',
        width: 60,
        accessor: d => Utils.getSafeLGsRow(d,"travel.km"),
        id:"travel.km"
      }, {
        Header: 'KM($)',
        width: 80,
        accessor: d => Utils.getSafeLGsRow(d,"travel.kmreimbursement", true),
        id:"travel.kmreimbursement"
      }, {
        Header: 'Travel',
        width: 60,
        accessor: d => Utils.getSafeLGsRow(d,"travel.traveltime"),
        id:"travel.traveltime"
      }, {
        Header: 'Travel($)',
        width: 80,
        accessor: d => Utils.getSafeLGsRow(d, "travel.travelreimbursement", true),
        id:"travel.travelreimbursement"
      }, {
        Header: 'Cons.',
        width: 60,
        accessor: d => Utils.getSafeLGsRow(d,"consulting.consultinghours"),
        id:"consulting.consultinghours"
      }, {
        Header: 'Cons.($)',
        width: 80,
        accessor: d => Utils.getSafeLGsRow(d,"consulting.consultingpayment", true),
        id:"consulting.consultingpayment"
      }, {
        Header: 'Other($)',
        width: 80,
        accessor: d => Utils.getSafeLGsRow(d,"othercosts", true),
        id:"othercosts"
      }, {
        Header: 'Total($)',
        width: 80,
        accessor: d => Utils.getSafeLGsRow(d,"totalclaim", true),
        id:"totalclaim"
      }, {
        Header: '',
        accessor: 'pk',
        sortable: false,
        width: 80,
        Cell: props => {
          if (props.original.isNotData) return (<div><br></br></div>);
          return (<span className='tableButtonContainer'>
                    <OverlayTrigger key="edit" placement="left" overlay={ <Tooltip id="tooltip-edit"> Duplicate entry </Tooltip> } container={this}>
                      <Button className="tableEditButton" variant="none" hint="Duplicated"
                              onClick={this.handleDuplicatedEntry.bind(this,props.original)}><FontAwesomeIcon icon={faCopy} size="1x"/></Button>
                    </OverlayTrigger>
                  </span>);
        }
    }];
    //render
    return (
    <div className="AppPageContainer">
      <CommonLoadingView isLoading={this.state.isLoading}/>
      <CommonLogFilter app={this.props.app}
                       handleFilterSubmit={this.handleFilterSubmit}
                       ref={c => (this.filterObj = c)}/>
     <Container className="filterContainer">
       <Row className="overlayLabelContainer justify-content-between">
         <Col className="col-auto"></Col>
         <Badge variant="secondary" className="col-auto">
           <Col className="overlayTotalLabel">Grand Total ($)</Col>
           <Col className="overlayTotalValue">{Utils.toCurrencyFormat(this.state.data.totalclaimaftertax)}</Col>
         </Badge>
         <Col className="col-auto">
           { this.state.rowsData.length > 0 && Utils.renderPrintPDFButton(this) }
         </Col>
       </Row>
      </Container>
      <ReactTable data={this.state.rowsData} className="v-100 -highlight" columns={columns} {...props} />
    </div>);
  }
}
//Navigation container implementation
export class AdvisorReportHistoryNavigationController extends CustomNavigationController {
  constructor(props) {
    super(props);
    this.containerClass = AdvisorReportHistory;
  }
}
