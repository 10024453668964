import Utils from "./Utils";
import APIUtils from "./APIUtils";
//
export default class UtilsReport  {}

UtilsReport.generateIndustryEventsReportFromQRs = function(qrsObj) {
  if (!qrsObj) return [];
  let noEvents = true;
  let counter = 0;
  let IEs = [];
  while (noEvents) {
    noEvents = false;
    let subIEs = [];
    //for each quarter
    for (var i = 0; i < 4; i++) {
      let qrObj = qrsObj[i];
      if (qrObj && qrObj.industryevents && qrObj.industryevents[counter]) {
        noEvents = true;
        let ieObj = qrObj.industryevents[counter];
        subIEs.push({
          industryevent: ieObj.industryevent.replace("IE::",""),
          total: ieObj.total
        });
      }else subIEs.push({industryevent: " ", total: 0});
    }
    //prepare for next
    IEs.push(subIEs);
    counter++
  } return IEs;
};
//get one QR entry equivalent to the yearly report as a QR
UtilsReport.generateYearlyReportRow = function(qrsObj) {
  //Get all QRs from selected group
  let report = null;
  //for each YR
  for (let QRIdx in qrsObj) {
    let advQuarterObj = qrsObj[QRIdx];
    if (advQuarterObj.newEntry == true) {} //do nothing
    else if (report == null) report = JSON.parse(JSON.stringify(advQuarterObj));
    else report = APIUtils.sumQRs(report, advQuarterObj);
  }
  if (report == null) return {sk: "ZZZ"};
  report.isTotal = true;
  report.sk = "ZZZ"; //make sure year summary is at the bottom of tables
  report.pk = report.pk.slice(0,4);
  return report;
}
//get QRs groupped by quarters
UtilsReport.grouppedQuartersByQuarter = function(qrsObj) {
  if (!qrsObj || qrsObj.length == 0) return [];
  let advQuarters = [];
  let year = qrsObj[0].sk.slice(0,4);
  //get index of QR with same advisor
  function allQRsFromQuarter(quarter) {
    let retVal = [];
    let safeQuarter = Utils.toDoubleDigit(quarter);
    for (let qrObj of qrsObj) {
      if (qrObj.sk == (year + "" + safeQuarter)) retVal.push(qrObj);
    } return retVal;
  }
  //for each quarter
  for (let i = 1; i < 5; i++) {
    let selectedQRs = allQRsFromQuarter(i, qrsObj); //get all QRs from this quarter
    let superQR = APIUtils.newQRObject(year); //new QRobj
    for (let qr of selectedQRs) superQR = APIUtils.sumQRs(superQR, qr); //sum QR into every available
    advQuarters.push({quarter: Utils.toDoubleDigit(i), ...superQR });
  } return advQuarters;
}
//get QRs groupped by RSAs
UtilsReport.grouppedQuartersByAdvisor = function(qrsObj, cache) {
  if (!qrsObj|| qrsObj.length == 0) return [];
  let advQuarters = [];
  let year = qrsObj[0].sk.slice(0,4);
  //get index of QR with same advisor
  function allQRsFromRSA(rsaID) {
    let retVal = [];
    for (let qrObj of qrsObj) {
      if (qrObj.pk.replace("QR::","") == rsaID) retVal.push(qrObj);
    } return retVal;
  }
  function allRSAs() {
    let retVal = [];
    for (let qrObj of qrsObj) {
      let rsaID = qrObj.pk.replace("QR::","");
      let rsa = {rsaid: rsaID, rsaname: cache.findDisplayNameByID(rsaID)};
      if (retVal.map((e)=>e.rsaid).indexOf(rsaID) == -1) retVal.push(rsa);
    }
    //order by name
    retVal.sort((a, b) => { return a.rsaname.localeCompare(b.rsaname); });
    return retVal;
  }
  let availableRSAs = allRSAs();
  //for each available rsa
  for (let rsa of availableRSAs) {
    let selectedQRs = allQRsFromRSA(rsa.rsaid); //get all QRs from this RSA
    let superQR = APIUtils.newQRObject(year); //new QRobj
    for (let qr of selectedQRs) superQR = APIUtils.sumQRs(superQR, qr); //sum QR into every available
    advQuarters.push({...rsa, ...superQR});
  } return advQuarters;
}
//
UtilsReport.generateYearlyReportRowsGroupped = function(grouppedQRs, includeRSA) {
  let retValue = [];
  //
  function getYearlyTotalRowFromGrouppedQR(grouppedQR) { return {isTotal: true, ...grouppedQR}; }
  function getRSARowFromGrouppedQR(grouppedQR) {
    return {
        sk: grouppedQR.rsaname,
        isUser: true, year: grouppedQR.sk.slice(0,4)
    };
  }
  function getQuarterRowFromQR(quarter, quarterNumb) {
    let q = {...quarter, sk: "Q" + parseInt(quarterNumb), year: (quarter ? quarter.sk : 0)};
    if (!quarter) q.newEntry = true;
    return q;
  }
  function getQuarterTotalRowFromGrouppedQR(grouppedQR) {
    return {...grouppedQR, sk: "Total " + grouppedQR.rsaname,
                           year: (grouppedQR ? grouppedQR.sk : 0), isQRTotal: true};
  }
  function getSpacedRow() { return {isSpace: true}; }
  //for each report - last one is yeraly total!
  for (let grouppedQR of grouppedQRs) {
    if (grouppedQR.sk == "ZZZ") { //end total row
      if (includeRSA != true) retValue.push(getSpacedRow());
      retValue.push(getYearlyTotalRowFromGrouppedQR(grouppedQR));
    }else {
      if (includeRSA == true) {
        //Get RSA row
        retValue.push(getRSARowFromGrouppedQR(grouppedQR));
        //Row for each quarter
        for (let quarter of [1,2,3,4]) {
          //for each available QR check if it matches
          let foundQR = false;
          for (let qrObj of grouppedQR.qrs) {
            //check if quarter matches with request
            if (qrObj && qrObj.sk && parseInt(qrObj.sk.slice(4,6)) == quarter) {
              retValue.push(getQuarterRowFromQR(qrObj, quarter));
              foundQR = true;
              break;
            }
          }
          if (!foundQR) retValue.push(getQuarterRowFromQR(null, quarter));
        }
      }
      //Total Row
      retValue.push(getQuarterTotalRowFromGrouppedQR(grouppedQR));
      //Space Row
      if (includeRSA == true) retValue.push(getSpacedRow());
    }
  } return retValue;
}
//
UtilsReport.fillMissingQuarters = function(respObj, filter) {
    let missingQuarters = [1,2,3,4];
    //search and remove found quarters from missing []
    for (let QRIdx in respObj) {
      let QRObj = Object.assign({}, respObj[QRIdx]);
      for (let missingIdx in missingQuarters) {
        let missinObj = missingQuarters[missingIdx];
        if (QRObj["sk"].slice(5,6) == missinObj) {
          missingQuarters.splice(missingIdx,1);
          break; //brea current loop, already found for this QR
        }
      }
    }
    //For each missing we add a new one
    for (let missingIdx in missingQuarters) {
      let missinObj = missingQuarters[missingIdx];
      respObj.splice((missinObj-1),0,{"sk":(filter.selectedYear + Utils.toDoubleDigit(missinObj)), "newEntry" : true});
    }
    //
    return respObj;
  }



//MS
UtilsReport.generateMonthlyReportRows = function(msObj) {
  function getSpacedRow() { return { activitydate: "ZZ", isNotData: true, isSpace: true}; }
  function getSubtotalRow() {
    return { activitydate: "ZZZ", isNotData: true, title: "Sub-total:",
             travel: {
               km: Utils.safelyGetNumericNestedObject(msObj, "travel.km"),
               kmreimbursement: Utils.safelyGetNumericNestedObject(msObj, "travel.kmreimbursement"),
               traveltime: Utils.safelyGetNumericNestedObject(msObj, "travel.traveltime"),
               travelreimbursement: Utils.safelyGetNumericNestedObject(msObj, "travel.travelreimbursement")
             },
             meal: {
              mealamount: Utils.safelyGetNumericNestedObject(msObj, "meal.mealamount"),
             },
             consulting: {
               consultinghours: Utils.safelyGetNumericNestedObject(msObj, "consulting.consultinghours"),
               consultingpayment: Utils.safelyGetNumericNestedObject(msObj, "consulting.consultingpayment"),
               consultingadminhours: Utils.safelyGetNumericNestedObject(msObj, "consulting.consultingadminhours")
             },
             othercosts: Utils.safelyGetNumericNestedObject(msObj, "othercosts"),
             totalclaim: Utils.safelyGetNumericNestedObject(msObj, "totalclaim")
    };
  }
  function getGSTRow() {
    let SPACER = "-";
    return {  activitydate: "ZZZZ", isNotData: true, title: "GST:",
              travel: {
                km: SPACER, kmreimbursement: Utils.safelyGetNumericNestedObject(msObj, "travel.kmgst"),
                traveltime: SPACER, travelreimbursement: Utils.safelyGetNumericNestedObject(msObj, "travel.traveltimegst")
              },
              meal: {
                mealamount: Utils.safelyGetNumericNestedObject(msObj, "meal.mealgst"),
              },
              consulting: {
                consultinghours: SPACER, consultingpayment: Utils.safelyGetNumericNestedObject(msObj, "consulting.consultinggst")
              },
              othercosts: SPACER, totalclaim: Utils.safelyGetNumericNestedObject(msObj, "totalclaimgst")
      };
  }
  function getTotalRow() {
    return { activitydate: "ZZZZZ", isNotData: true, title: "Total:",
             travel: {
               km: Utils.safelyGetNumericNestedObject(msObj, "travel.km"),
               kmreimbursement: Utils.safelyGetNumericNestedObject(msObj, "travel.kmtotal"),
               traveltime: Utils.safelyGetNumericNestedObject(msObj, "travel.traveltime"),
               travelreimbursement: Utils.safelyGetNumericNestedObject(msObj, "travel.traveltimetotal")
             },
             meal: {
              mealamount: Utils.safelyGetNumericNestedObject(msObj, "meal.mealtotal"),
            },
             consulting: {
               consultinghours: Utils.safelyGetNumericNestedObject(msObj, "consulting.consultinghours"),
               consultingpayment: Utils.safelyGetNumericNestedObject(msObj, "consulting.consultingaftertax"),
               consultingadminhours: Utils.safelyGetNumericNestedObject(msObj, "consulting.consultingadminhours")
             },
             othercosts: Utils.safelyGetNumericNestedObject(msObj, "othercosts"),
             totalclaim: Utils.safelyGetNumericNestedObject(msObj, "totalclaimaftertax")
    };
  }
  //
  if (!msObj) return [];
  let retVal = [];
  //append LGs into it
  if (msObj.lgs) retVal = retVal.concat(msObj.lgs);
  //Add space
  retVal.push(getSpacedRow());
  //add values
  retVal.push(getSubtotalRow());
  retVal.push(getGSTRow());
  retVal.push(getTotalRow());
  //
  return retVal;
}
